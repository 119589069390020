export const columns: TableColumn[] = [
        {
          Header: "Sl.No",
          accessor: "slno",
        },
        {
          Header: "tableCommon.batch_code",
          accessor: "batch_number",
        },
        {
          Header: "tableCommon.seriel_number",
          accessor: "serial_number",
        },
        {
          Header: 'Autoclave Ends In ',
          accessor: 'autoclaveEndTime',
        },
        {
          Header: "Activity Type",
          accessor: "plate_type",
        },
        {
          Header: "Plate Status",
          accessor: "status",
        },
        {
          Header: 'Autoclaved by',
          accessor: 'autoclave_user',
        },


        // {
        //   Header: "Exposed Time",
        //   accessor: "exposed_time",
        // },
        // {
        //   Header: "Exposed By",
        //   accessor: "exposed_by",
        // },
        // {
        //   Header: "Exposure Area Name",
        //   accessor: "exposure_area_name",
        // }, 
        // {
        //   Header: "Incubated by",
        //   accessor: "incubated_by",
        // },
        // {
        //   Header: "Incubation Area Name",
        //   accessor: "incubation_area_name",
        // },      
       
        // {
        //   Header: 'Colony Count',
        //   accessor: 'colony_count',
        // },
       
  ];


export const HistoryColumns: TableColumn[] = [
  {
    Header: "Sl.No",
    accessor: "slno",
  },
  {
    Header: "tableCommon.batch_code",
    accessor: "batch_number",
  },
  {
    Header: "tableCommon.seriel_number",
    accessor: "serial_number",
  },
  {
    Header: 'Autoclave Duration',
    accessor: 'autoclave_end_time',
  },
  {
    Header: "Activity Type",
    accessor: "plate_type",
  },
  // {
  //   Header: "Plate Status",
  //   accessor: "status",
  // },
  {
    Header: 'Autoclaved by',
    accessor: 'autoclave_user',
  },

]

  
interface TableColumn {
    Header: string;
    accessor: string;
}