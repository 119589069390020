import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TfiClose } from 'react-icons/tfi';
import { authenticationService } from '../../../../service/authentication';
import { userService } from '../../../../service/userService';
import Select from "react-select";
import AlertModal from '../../../../widgets/alertModal';

type FormDataType = {
    id: string;
    popUpNotification: boolean;
    emailNotification: boolean;
    leadAlert: string;
    delayAlert: string;
    leadAlarm: string;
    delayAlarm: string;
    popUpSnooze: string;
    notificationEvents: string;
    notificationType: string;
    userSelection: any;
    reason: string;
};


interface NotificationConfigModalProps {
        showAddModal: boolean;
        userId: string;
        notificationModalName: string;
        setShowAddModal: (value: boolean) => void;
        setuseEffectRequired: (value: boolean) => void;
        setToastMessage: (value: string) => void;
        setToastType: (value: string) => void;
        setShowToast: (value: boolean) => void;
        formData: FormDataType; // Use the same type defined in the parent component
        setFormData: React.Dispatch<React.SetStateAction<FormDataType>>; // Type for the setter function
    }

const NotificationConfigModal: React.FC<NotificationConfigModalProps> = ({
    showAddModal,
    userId,
    setShowAddModal,
    notificationModalName,
    setuseEffectRequired,
    setToastMessage,
    setToastType,
    setShowToast,
    formData,
    setFormData
}) => {

    const { t } = useTranslation();


    const [notificationEvents, setNotificationEvents] = useState([]);
    const [userData, setUserData] = useState([]);
    interface Errors {
        notificationEvents?: string,
        userSelection?: string,
        notificationType? : string,
        snoozeTime?: string,
        delayTime?: string,
        leadTime? : string,
        notificationChannel? : string,
        reason? : string

    }
    const [errors, setErrors] = useState<Errors>({});
    const [addNotifiAlert, setAddNotifiAlert] = useState(false);

    const handleChange = (event: any) => {
        const { type, name, checked, value } = event.target;
        // Check if the input type is 'number'
        if (type === 'number') {
            // Remove all non-numeric characters and prevent negative sign
            const numericValue = value.replace(/[^0-9]/g, ''); 
            // Update state only if the value is not empty
            setFormData((prevData) => ({
            ...prevData,
            [name]: numericValue !== '' ? numericValue : ''
            }));
        }
        // else if (name === "notificationType") {
        //     setFormData((prevState) => ({
        //         ...prevState,
        //         [name]: value,   
        //         delayAlarm: "", 
        //         leadAlarm: ""         
        //     }));
        // } 
        else {
            // Update state for other input types (e.g., checkbox, text)
            setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
            }));
        }
    };

    //   list notification events
    const listNotificationEvents = async () => {
        try {
            const response = await authenticationService.listNotificationEvents();
            if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                    let rowData: any = response.data.map((element: any, index: number) => {

                    return { 
                        slno: index + 1, 
                        id : element.id,
                        eventName: element.event_name,
                        eventId : element.event_id,
                    };
                    });
                    setNotificationEvents(rowData);
                }
                } catch (error) {
                console.log(error);
                }
            }
        } catch (error) {
            console.error('user response', error);
        }
    }

    //   list user
    const getOperatorList = async () => {
        try {
          // const userRole = parseInt(addFilterData.role, 10);  
          const response = await userService.getUserNameList("");
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
      
                    return { 
                        id: element.id,
                        value: `${element.first_name} ${element.last_name}`,
                        label: `${element.first_name} ${element.last_name}`,
                    };
                  });
                  setUserData(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          } else {
            setUserData([]);
          }
        } catch (error) {
          console.error(error);
        }
      };

    //user change handle function
    const handleUserChange = (options:any) => {
        if (options) {
          const formattedOptions = options.map((option: any) => ({
            id: option.id,               // Assuming `option.id` is the unique identifier
            value: option.value, // The value to be stored
            label: option.label  // The label to be displayed
          }));
          setFormData((prevData) => ({
            ...prevData,  
            userSelection: formattedOptions 
          }));
        }
      }
    
    const handleSubmitClick = () => {
        const errors: Errors = {};
        if (!formData.notificationEvents) {
            errors.notificationEvents = t('applicationConfiguration.errorsNotificationEvents');
        }
        if (formData.userSelection.length < 1) {
            errors.userSelection = t('applicationConfiguration.pleaseSelectUsers');
        }
        if (!formData.notificationType) {
            errors.notificationType = t('applicationConfiguration.pleaseSelectNotificationType');
        }
        // if (!formData.popUpSnooze) {
        //     errors.snoozeTime = "Please Enter Snooze Time";
        // }
        // if (formData.notificationType === "alert" && !formData.leadAlarm) {
        //     errors.leadTime = t('applicationConfiguration.pleaseEnterLeadTime');
        // }
        if (formData.notificationType === "alert" && formData.leadAlarm.length < 8) {
            errors.leadTime = "Enter Time in hh:mm:ss format";
        }
        // if (formData.notificationType === "alarm" && !formData.delayAlarm) {
        //     errors.delayTime = t('applicationConfiguration.pleaseEnterDelayTime');
        // }
        if (formData.notificationType === "alarm" && formData.delayAlarm.length < 8) {
            errors.delayTime = "Enter Time in hh:mm:ss format";
        }
        if (!formData.popUpNotification && !formData.emailNotification) {
            errors.notificationChannel = t('applicationConfiguration.pleaseSelectNotificationChannel');
        }
        if (formData.id && !formData.reason) {
            errors.reason = "Please Enter Reason";
          }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        setAddNotifiAlert(true)
        setErrors({});
    }

    //api call to make user active or inactive 
    const handleNoticationSubmit = async () => {
        setAddNotifiAlert(false)
        const valuesString: any = formData?.userSelection?.map((option:any) => option.id).join(',');

         // Helper function to convert hh:mm:ss to seconds
        const convertToSeconds = (timeValue : any) => {
            const timeParts = timeValue.split(':');
            const hours = parseInt(timeParts[0], 10) || 0;
            const minutes = parseInt(timeParts[1], 10) || 0;
            const seconds = parseInt(timeParts[2], 10) || 0;
            return (hours * 3600) + (minutes * 60) + seconds;
        };



        try {
            const dataToSend = {
                "id":formData.id,
                "event_id": formData.notificationEvents,
                "selected_user_ids": valuesString,
                "pop_up_notification": formData.popUpNotification? "on" : "off",
                "email_notification": formData.emailNotification? "on" : "off",
                "notification_type": formData.notificationType,
                // "snooze_time": formData.popUpSnooze,
                "lead_time": convertToSeconds(formData.leadAlarm),
                "delay_time": convertToSeconds(formData.delayAlarm),
                "interface_name": "Notification Settings",
                "reason" : formData.reason
            }
            const response = await authenticationService.saveNotificationSettings(dataToSend);
            
            if (response.status && response.statusCode === 200) {
                setuseEffectRequired(true)
                setToastMessage(response.message)
                setToastType('success')
                handleClose()
            }else{
                setToastType('error')
                setToastMessage(response.message)      
            }
            setShowToast(true)
        } catch (error) {
            console.error(error);
        }
    }

    const handleChangeWithValidation = (e : any) => {
        let inputValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
        if (inputValue.length > 6) {
        inputValue = inputValue.slice(0, 6); // Limit to 6 characters (hhmmss)
        }
        let name = e.target.name;

        let formattedTime = inputValue;

        // Insert colons to format as hh:mm:ss
        if (inputValue.length > 2) {
        formattedTime = inputValue.slice(0, 2) + ":" + inputValue.slice(2);
        }
        if (inputValue.length > 4) {
        formattedTime = formattedTime.slice(0, 5) + ":" + formattedTime.slice(5);
        }

        setFormData({
            ...formData,
            [name]: formattedTime,
          });
      
      };

    const handleClose = () => {
        setShowAddModal(false);
        setFormData({
            id:"",
            popUpNotification: false,
            emailNotification: false,
            leadAlert: "",
            leadAlarm: "",
            delayAlert: "",
            delayAlarm: "",
            popUpSnooze: '',
            notificationEvents: '',
            notificationType: '',
            userSelection: '',
            reason: '',
        });
        setErrors({});
    }
        

  useEffect(() => {
    listNotificationEvents();
    getOperatorList();
  }, [])
  

  return (
    <>
    <Modal 
              className="top-right-modal-md"
              centered={false}
              show={showAddModal}
          >
        <div>
            <div className="modalHead">
                  <p className="modalHeadText ms-3 pt-3 p-2">{notificationModalName}</p>
                  <div className="p-2 mx-3 my-2 " onClick={() => handleClose()}>
                    <TfiClose fill="#FFFFFF" />
                  </div>
            </div>

                  <div className="selectWrap-new">
                    <div className="form-group my-1 mx-3 px-2">
                      <label htmlFor="" >{t('applicationConfiguration.notificationEvents')}<span style={{ color: 'red' }}> *</span></label>
                      <br />
                        <select
                        id="notificationEvents"
                        value={formData.notificationEvents}
                        name="notificationEvents"
                        onChange={handleChange} 
                        className='placeholder-style'
                        >
                        <option value="">Select</option>
                        {notificationEvents.map((event: any) => (
                            <option key={event.id} value={event.id}>
                            {event.eventName}
                            </option>
                        ))}
                        </select>
                      {errors.notificationEvents && <p className="errorText">{errors.notificationEvents}</p>}
                    </div>

                    <div className="form-group my-1 mx-3 px-2">
                      <label htmlFor="" >{t('applicationConfiguration.notificationType')}<span style={{ color: 'red' }}> *</span></label>
                      <br />
                        <select
                        id="notificationType"
                        value={formData.notificationType}
                        name="notificationType"
                        onChange={handleChange} 
                        className='placeholder-style'
                        >
                        <option value="">Select</option>
                        <option value="alert">{t('applicationConfiguration.alert')}</option>
                        <option value="alarm">{t('applicationConfiguration.alarm')}</option>
                        <option value="info">{t('applicationConfiguration.info')}</option>
                        
                        </select>
                      {errors.notificationType && <p className="errorText">{errors.notificationType}</p>}
                    </div>

                    <div className="form-group my-1 mx-5" style={{"minWidth":"50px"}}>
                      <label htmlFor="" >{t('applicationConfiguration.notificationChannels')}<span style={{ color: 'red' }}> *</span></label>
                      <div className="form-check form-switch">
                            <input className="form-check-input" 
                                type="checkbox" 
                                role="switch" 
                                id="switch1" 
                                name='popUpNotification' 
                                checked={formData.popUpNotification}
                                onChange={(e) => handleChange(e)}/>
                            <label className="form-check-label" htmlFor="switch1">{t('applicationConfiguration.popUpNotification')}</label>
                        </div>

                        <div className="form-check form-switch">
                            <input className="form-check-input" 
                                type="checkbox" 
                                role="switch" 
                                id="switch4"
                                name='emailNotification' 
                                checked={formData.emailNotification}
                                onChange={(e) => handleChange(e)} />
                            <label className="form-check-label" htmlFor="switch4">{t('applicationConfiguration.emailNotification')}</label>
                        </div>
                      {errors.notificationChannel && <p className="errorText">{errors.notificationChannel}</p>}
                    </div>
                  </div>

                  {/* first section */}
                  <div className='py-3'>
                    {/* <div className="d-flex justify-content-around align-items-center gap-3 selectWrap-new">
                        <div className="form-check form-switch">
                            <input className="form-check-input" 
                                type="checkbox" 
                                role="switch" 
                                id="switch1" 
                                name='popUpNotification' 
                                checked={formData.popUpNotification}
                                onChange={(e) => handleChange(e)}/>
                            <label className="form-check-label" htmlFor="switch1">Pop-Up Notification</label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" 
                                type="checkbox" 
                                role="switch" 
                                id="switch2" 
                                name='popUpAlert'
                                checked={formData.popUpAlert}
                                onChange={(e) => handleChange(e)}/>
                            <label className="form-check-label" htmlFor="switch2">Alert</label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" 
                                type="checkbox" 
                                role="switch" 
                                id="switch3" 
                                name='popUpAlarm'
                                checked={formData.popUpAlarm}
                                onChange={(e) => handleChange(e)}/>
                            <label className="form-check-label" htmlFor="switch3">Alarm</label>
                        </div>
                    </div> */}

                    <div className="selectWrap-new">
                        {/* <div className="form-group my-1 mx-3 px-2">
                                <label htmlFor="" >{t('applicationConfiguration.snoozeTimeForAlert/Alarm')}<span style={{ color: 'red' }}> *</span></label>
                                <br />
                                <input 
                                    id="popUpSnooze"
                                    value={formData.popUpSnooze}
                                    name="popUpSnooze"
                                    type='text'
                                    placeholder='hh:mm:ss'
                                    className="placeholder-style"
                                    onChange={handleChangeWithValidation}
                                    maxLength={8}
                                />
                                {errors.snoozeTime && <p className="errorText">{errors.snoozeTime}</p>}
                        </div> */}
                        
                        {formData.notificationType === "alert" && (
                        <>
                        <div className="form-group my-1 mx-3 px-2">
                            <label htmlFor="" >{t('applicationConfiguration.leadTimeForTriggeringAlert')}<span style={{ color: 'red' }}> *</span></label>
                            <br />
                            <input 
                                id="leadAlarm"
                                value={formData.leadAlarm}
                                name="leadAlarm"
                                type='text'
                                placeholder='hh:mm:ss'
                                className="placeholder-style"
                                onChange={handleChangeWithValidation}
                                maxLength={8}
                            />
                        {errors.leadTime && <p className="errorText">{errors.leadTime}</p>}
                        </div>

                        {/* <div className="form-group my-1 mx-3 px-2">
                            <label htmlFor="" >Delay Time for Triggering Alert<span style={{ color: 'red' }}> *</span></label>
                            <br />
                            <input 
                                id="delayAlarm"
                                value={formData.delayAlarm}
                                name="delayAlarm"
                                type='text'
                                placeholder='hh:mm:ss'
                                className="placeholder-style"
                                onChange={handleChangeWithValidation}
                                maxLength={8}
                            />
                            {errors.delayTime && <p className="errorText">{errors.delayTime}</p>}
                        </div> */}
                        </>
                        )}

                        {formData.notificationType === "alarm" && (
                        <>
                         {/* <div className="form-group my-1 mx-3 px-2">
                            <label htmlFor="" >Lead Time for Triggering Alarm<span style={{ color: 'red' }}> *</span></label>
                            <br />
                            <input 
                                id="leadAlarm"
                                value={formData.leadAlarm}
                                name="leadAlarm"
                                type='text'
                                placeholder='hh:mm:ss'
                                className="placeholder-style"
                                onChange={handleChangeWithValidation}
                                maxLength={8}
                            />
                            {errors.leadTime && <p className="errorText">{errors.leadTime}</p>}
                        </div> */}

                        <div className="form-group my-1 mx-3 px-2">
                            <label htmlFor="" >{t('applicationConfiguration.delayTimeforTriggeringAlarm')}<span style={{ color: 'red' }}> *</span></label>
                            <br />
                            <input 
                                id="delayAlarm"
                                value={formData.delayAlarm}
                                name="delayAlarm"
                                type='text'
                                placeholder='hh:mm:ss'
                                className="placeholder-style"
                                onChange={handleChangeWithValidation}
                                maxLength={8}
                            />
                            {errors.delayTime && <p className="errorText">{errors.delayTime}</p>}
                        </div>
                        </>
                        )}
                    </div>

                  </div>

                  {/* second section */}
                  {/* <div className='border m-3 py-3'>
                    <div className="d-flex justify-content-around align-items-center gap-3 selectWrap-new">
                        <div className="form-check form-switch">
                            <input className="form-check-input" 
                                type="checkbox" 
                                role="switch" 
                                id="switch4"
                                name='emailNotification' 
                                checked={formData.emailNotification}
                                onChange={(e) => handleChange(e)} />
                            <label className="form-check-label" htmlFor="switch4">Email Notification</label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" 
                                type="checkbox" 
                                role="switch" 
                                id="switch5" 
                                name='emailAlert' 
                                checked={formData.emailAlert}
                                onChange={(e) => handleChange(e)} />
                            <label className="form-check-label" htmlFor="switch5">Alert</label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" 
                                type="checkbox" 
                                role="switch" 
                                id="switch6"
                                name='emailAlarm' 
                                checked={formData.emailAlarm}
                                onChange={(e) => handleChange(e)} />
                            <label className="form-check-label" htmlFor="switch6">Alarm</label>
                        </div>
                    </div>

                    <div className="selectWrap-new">
                        <div className="form-group my-1 mx-3 px-2">
                            <label htmlFor="" >Lead Time for Triggering Alert</label>
                            <br />
                            <input 
                                id="emailLeadTrigAlert"
                                value={formData.emailLeadTrigAlert}
                                name="emailLeadTrigAlert"
                                type='number'
                                placeholder=''
                                className="placeholder-style"
                                onChange={handleChange}
                                maxLength={100}
                                min={1}
                            />
                            {errors.calibrationData && <p className="errorText">{errors.calibrationData}</p>}
                        </div>

                        <div className="form-group my-1 mx-3 px-2">
                            <label htmlFor="" >Delay Time for Triggering Alarm</label>
                            <br />
                            <input 
                                id="emailDelayTrigAlarm"
                                value={formData.emailDelayTrigAlarm}
                                name="emailDelayTrigAlarm"
                                type='number'
                                placeholder=''
                                className="placeholder-style"
                                onChange={handleChange}
                                maxLength={100}
                                min={1}
                            />
                            {errors.calibrationData && <p className="errorText">{errors.calibrationData}</p>}
                        </div>
                    </div>

                  </div> */}

                  <div className="selectWrap-new">
                    <div className="form-group my-1 mx-3 ps-2" style={{"minWidth":"100px"}}>
                      <label htmlFor="" >{t('applicationConfiguration.userSelection')}<span style={{ color: 'red' }}> *</span></label>
                      <br />
                        <Select 
                            options={userData} 
                            isMulti={true} 
                            name="userSelection" 
                            onChange={handleUserChange}
                            value={formData.userSelection}
                        />
                      {errors.userSelection && <p className="errorText">{errors.userSelection}</p>}
                    </div>

                    {formData.id && (
                        <div className="form-group my-1 ms-3 me-1 ps-2 pe-5">
                            <label htmlFor="" className='ReasonLabel'>{t('configuration.reason')} <span style={{ color: 'red' }}>*</span></label>
                            <br />
                            <input 
                                id="reason"
                                value={formData.reason}
                                name="reason"
                                type='text'
                                placeholder={t('configuration.reason')}
                                className="reasonClass"
                                onChange={handleChange} 
                            />
                            {errors.reason && <p className="errorText">{errors.reason}</p>}
                        </div>
                    )}

                  </div>

                  <div className="bottomArea">
                    <button  className="modalCancelBtn" onClick={() => handleClose()} >
                    {t('buttons.cancel')}
                    </button>
                    <button className="squareOrangeBtn3" onClick={() => handleSubmitClick()} >{t('buttons.submit')}</button>
                    {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
                  </div>
        </div>
    </Modal>

    {/* Alert modal for add/edit notification */}
    <AlertModal 
          show={addNotifiAlert}
          onHide={() => setAddNotifiAlert(false)}
          title='Alert'
          message={`${notificationModalName}`}
          onConfirm={handleNoticationSubmit}
    />
    </>
  )
}

export default NotificationConfigModal;