export const columns: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    {
        Header: "applicationConfiguration.eventName",
        accessor: "event_name",
    },
    // {
    //     Header: "plateInventory.tableColumns.status",
    //     accessor: "status",
    // },
  ];


    
  interface TableColumn {
    Header: string;
    accessor: string;
  }