import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { dashboardServices } from "../../../../service/dashboard";
import translationData from '../../../../locales/dashboardTranslation.json'

interface LabtestDetailsLayoutProps {
  heading: string;
  selectedDate: any;
}

const columnStyle = {
  margin: "0 0 6px 0",
};

const lastColumnStyle = {
  margin: "0",
};
const LabtestDetailsLayout: React.FC<LabtestDetailsLayoutProps> = ({
  heading,
  selectedDate,
}) => {

  interface ColumnData {
    id: number;
    apiName: string;
    displayName: string;
    count: number;
  }
  const [columnsData, setColumnsData] = useState<ColumnData[]>([]);
  const navigate = useNavigate();

  const navigateToOtherPage = () => {
    navigate('/labtest-table'); 
  };

  const switchToPage = (id: number) => {
    if (id >= 1 && id <= 7) {
      navigate(`/inner-view-labtest/${id}`);
    }
  }

  const getLabtestStatus = async () => {
    try {
      const response = await dashboardServices.getLabtestStatus(selectedDate);
      if (response.status && response.statusCode === 200) {
        try {
          const responseData = response.data;
          if (responseData) {
            setColumnsData(prevColumnsData => {
              return prevColumnsData.map(column => {
                const { apiName, displayName } = column;

                const apiCount = responseData[apiName];

                if (apiCount !== undefined) {
                  return { ...column, count: apiCount };
                } else {
                  console.warn(`API response for ${displayName} is undefined or missing.`);
                  return column;
                }
              });
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error('user response', error);
    }
  };

  useEffect(() => {
    const LabtestDataHead = translationData.LabtestDataHead;
    setColumnsData(LabtestDataHead.map((item => ({ ...item, count: 0 }))));
    }, []); 

  useEffect(() => {
    getLabtestStatus();
  }, [])

  const getColumnNameStyle = (column: any) => {
    if (column.displayName.includes('outside the range') || column.displayName.includes('Environmental Flora') ) {
      return 'column-style yellowText';
    }else {
      return 'column-style';
    }
  };

  return (
    <div className="container">
      <h2 className="head-style" title="Click here to View Lab Test Details" onClick={navigateToOtherPage}>
        {heading}
      </h2>
      <div className="columns" style={{ padding: "10px" }}>
          {columnsData.map((column) => (
          <div key={column.id} className="column" style={column.id === columnsData.length ? lastColumnStyle : columnStyle}>
          <div className="linkStyle" onClick={() => switchToPage(column.id)}>
          <span className={getColumnNameStyle(column)}>
            {column.displayName}
          </span>
          <span className="countStyle">{column.count}</span>
          </div>
          </div>
      ))}
      </div>
    </div>
  );
};

export default LabtestDetailsLayout;