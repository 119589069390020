import React, {useState, useEffect} from 'react'
import HeaderSection from '../../common/headerSection';
import PageHeader from '../../common/pageHeader';
import CustomToast from '../../../widgets/toaster';
import { userService } from '../../../service/userService';
import CustomTable from '../../../widgets/table';
import { auditTrialReport } from '../../../service/auditTrialReport';
import Pagination from '../../../widgets/pagination';
import { useTranslation } from 'react-i18next';
import baseUrl from '../../../service';
import { deviceRegistration } from '../../../service/deviceRegistration';

const LogReport = () => {

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [logDetails, setLogDetails] = useState([])
  const [totalElements, setTotalElements] = useState(0)
  const [userData , setUserData] = useState<Array<any>>([]); 
  const [addFilterData, setAddFilterData] = useState({
    role : "",
    employee:"",
    fromDate: "",
    toDate: "",
  });
  const [showDownloadCSV, setShowDownloadCSV] = useState(true);
  const [userId , setUserId] = useState("");
  const [authToken , setAuthToken] = useState("");
  const [authUserID , setAuthUserID] = useState("");
  const { t } = useTranslation()


  useEffect(() => {
    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
      setAuthToken(storedObject[0].x_auth_token)
      setAuthUserID(storedObject[0].x_userid)
    }
    listLogDetails();
  }, [])

 
  const listLogDetails = async () => {
    try {
      const response = await deviceRegistration.listLogDetails();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    logFileName: element.logFileName,
                    downloadPath: element.downloadPath,
                };
              });
              setLogDetails(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error('user response', error);
    }
  }


  const handleDownload = (downloadPath: string) => {
    const link = baseUrl + downloadPath
    window.open(link, '_blank');
   };


  return (
    <div>
        <HeaderSection />
        <PageHeader pageHeader={t('auditTrialReport.logReport')} />
        <CustomToast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={5000}
                message={toastMessage}
                toastType={toastType}
            />

       
        <div className='me-3 mt-4'>
            <div className=' mx-3 pe-2' style={{"height":"63vh"}}>
            <table className="log-list">
                <thead>
                    <tr>
                        <th>{t('auditTrialReport.fileName')}</th>
                        <th>{t('auditTrialReport.action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {logDetails.map((log : any, index) => (
                        <tr key={index}>
                            <td>{log.logFileName}</td>
                            <td>
                                <button
                                    className="download-button"
                                    onClick={() => handleDownload(log.downloadPath)}
                                >
                                    {t('buttons.downloadReport')}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </div>
    </div>
  )
}

export default LogReport;