
//column heads for Exception handling Request 
export const columns: TableColumn[] = [
  {
      Header: "Deviation Id",
      accessor: "slno",
    },
    {
      Header: "Area Code",
      accessor: "area_code",
    },
    {
      Header: "Area / Equipment Name",
      accessor: "equipment_name",
    },
    {
      Header: "Activity / Process",
      accessor: "process",
    },
    // {
    //   Header: "plateInventory.tableColumns.quantity",
    //   accessor: "quantity",
    // },
    {
      Header: "Operator Name",
      accessor: "operator_name",
    },
    {
      Header: "Operator ID",
      accessor: "operator_id",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Reason",
      accessor: "reason",
    },
    {
      Header: "exceptionHandling.tableColumns.status",
      accessor: "status",
    },
   
]


//column heads for Exception handling History  
export const columnsHistory: TableColumn[] = [
  {
    Header: "Deviation Id",
    accessor: "slno",
  },
  {
    Header: "Area Code",
    accessor: "area_code",
  },
  {
    Header: "Area / Equipment Name",
    accessor: "equipment_name",
  },
  {
    Header: "Activity / Process",
    accessor: "process",
  },
  // {
  //   Header: "plateInventory.tableColumns.quantity",
  //   accessor: "quantity",
  // },
  {
    Header: "Operator Name",
    accessor: "operator_name",
  },
  {
    Header: "Operator ID",
    accessor: "operator_id",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Reason",
    accessor: "reason",
  },
  {
    Header: "exceptionHandling.tableColumns.status",
    accessor: "status",
  },
]


interface TableColumn {
  Header: string;
  accessor: string;
}
