import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import { userService } from "../../../service/plateServices";
import moment from "moment";
import CustomToast from "../../../widgets/toaster";
import { columns, columnsHistory } from './partials/columns';
import Requests from "./partials/request";
import AddException from "../../common/addException";
import History from "./partials/history";
import AddDeviationModel from "../../common/addDeviation";
import { IncidentOrDeviationApi } from "../../../service/deviation";
import { areaService } from "../../../service/areaServices";


const DeviationPage = () => {
  const [tab, setTab] = useState("1");
  const [show, setShow] = useState(false);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [userId, setUserId] = useState<number>(0);
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState("success")
  const [toastType, setToastType] = useState("success")
  const [authToken , setAuthToken] = useState("")
  const [authUserID , setAuthUserID] = useState("")

  //pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [totalPages, setTotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  //Exception listing
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [plateStatus, setPlateStatus] = useState("16")
  const [plateSerielNum, setPlateSerielNums] = useState([])
  const [keyWord, setkeyWord] = useState("")

  //pagination in Exception History Area
  const [pageHistory, setPageHistory] = useState(1)
  const [pageSizeHistory, setPageSizeHistory] = useState(5)
  const [totalPagesHistory, setTotalPagesHistory] = useState(0)
  const [totalElementsHistory, setTotalElementsHistory] = useState(0)
  //Exception History
  const [plateDetailsHistory, setPlateDetailsHistory] = useState<Array<any>>([]);
  const [plateStatusHistory, setPlateStatusHistory] = useState("")
  const [keyWordHistory, setkeyWordHistory] = useState("")
  const [reasonId, setReasonId] = useState("");
  const [locationType, setLocationType] = useState("");
  const [historyReasonId, setHistoryReasonId] = useState("");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");

  const { t } = useTranslation();
  const [reason, setReason] = useState<Array<any>>([]);
  const [areas, setAreas] = useState<Array<any>>([]);

  //get user details
  useEffect(() => {
    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
      setAuthToken(storedObject[0].x_auth_token)
      setAuthUserID(storedObject[0].x_userid)
    }

    getExceptionPlates();
    getExceptionPlateHistory();
    listSerialNumber();
    getReasonList();
    geAreaTypeList();
    setUseEffectRequired(false)
  }, [useEffectRequired, tab])

  
//api call to list exception plate detail in request tab 
  const getExceptionPlates = async () => {
      try {
        const response = await IncidentOrDeviationApi.getIncidentList(page, pageSize,sort, order, locationType, reasonId, keyWord, "requested");
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
  
                  const statusNumber = parseInt(element.location_type, 10); 
                  const area_type = areaService.getLocationStatus(statusNumber);
                  return { 
                      slno: "V" + element.id, 
                      area_code: element.area_code,
                      equipment_name: element.area_name,
                      process: area_type,
                     
                      operator_name : element.operator_name,
                      operator_id : element.operator_id,
                      date : element.created_at,
                      reason: element.reason,
                      status: element.deviation_status,
                      id : element.id
                  };
                });
                setPlateDetails(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setPlateDetails([])
          setTotalPages(0)
          setTotalElements(0)
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    };

    //api call to list exception plate detail in history tab 
  const getExceptionPlateHistory = async () => {
        try {
          const response = await IncidentOrDeviationApi.getIncidentList(pageHistory, pageSizeHistory,sort, order, plateStatusHistory, historyReasonId, keyWordHistory, "proceeded,stopped");
          // const response = await userService.getExceptionPlateHistory(pageHistory, pageSizeHistory, sort, order, plateStatusHistory, keyWordHistory, historyReasonId, authToken ,authUserID);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  setTotalPagesHistory(response?.totalPages)
                  setTotalElementsHistory(response?.totalElements)
                  let rowData: any = response.data.map((element: any, index: number) => {
  
                    const statusNumber = parseInt(element.location_type, 10); 
                    const area_type = areaService.getLocationStatus(statusNumber);
                    return { 
                        slno: "V" + element.id, 
                        area_code: element.area_code,
                        equipment_name: element.area_name,
                        process: area_type,
                       
                        operator_name : element.operator_name,
                        operator_id : element.operator_id,
                        date : element.created_at,
                        reason: element.reason,
                        status: element.deviation_status,
                        id : element.id
                    };
                  });
                  setPlateDetailsHistory(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            setPlateDetailsHistory([])
          }
        } catch (error) {
          console.error('Plate response', error);
        }
    };

//api call to list the serial number for filter dropdown 
  const listSerialNumber = async () => {
        try {
          const response = await userService.listSerialNumber();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
      
                    return { 
                        value: element.serial_number,
                        label: element.serial_number ,
                    };
                  });
                  setPlateSerielNums(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          }
        } catch (error) {
          console.error('Plate response', error);
        }
    }

// handle on approve button click 
  const handleApproveClick = async (selectedRows: string[]) => {
    const filteredPlateDetails = plateDetails.filter((item) => selectedRows.includes(item.slno));
    const selectedIds = filteredPlateDetails.map((item) => item.id).join(',');
    
    const dataToSend = {
      "deviation_ids" : selectedIds,
      "status" : "proceeded",
      "interface_name" : "proceeded Deviation"
    }

    try {
      const response = await IncidentOrDeviationApi.changeDeviationStatus(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setUseEffectRequired(true)
      getExceptionPlateHistory();
    } catch (error) {
      console.error(error);
    }
  }

  // handle on reject button click in request tab 
  const handleRejectClick = async (selectedRows: string[]) => {
    const filteredPlateDetails = plateDetails.filter((item) => selectedRows.includes(item.slno));
    const selectedIds = filteredPlateDetails.map((item) => item.id).join(',');
    
    const dataToSend = {
      "deviation_ids" : selectedIds,
      "status" : "stopped",
      "interface_name" : "Stopped Deviation"
    }

    try {
      const response = await IncidentOrDeviationApi.changeDeviationStatus(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setUseEffectRequired(true)
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //handle table pagination in request view table 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  //handle table pagination in history view table 
  const handleHistoryPageChange = (newPage : any, pageSize?: number) => {
    setPageHistory(newPage);
    if(pageSize) {
      setPageSizeHistory(pageSize)
    }
    setUseEffectRequired(true)
  };

  //handle filter in request table section 
  const handleFilter = (key: string, value: any) => {
    if (key === "AreaType"){
      setLocationType(value)
    }
    if (key === "search") {
      setkeyWord(value)
    }
    if (key === "Reason") {
      setReasonId(value)
    }
    setUseEffectRequired(true)
  }

  // handle filter in history view table 
  const handleHistoryFilter = (key: string, value: any) => {
    if (key === "AreaType"){
      setPlateStatusHistory(value)
    }
    if (key === "Reason"){
      setHistoryReasonId(value)
    }
    if (key === "search") {
      setkeyWordHistory(value)
    }
    setUseEffectRequired(true)
  }

  // function to sort table
  const handleSortHistory = (key: string, value: boolean) => {
    setSort(key)
    if (value) {
      setOrder("asc")
    } else {
      setOrder("desc")
    }
    setUseEffectRequired(true)
  }

  // function to sort table
  const handleSort = (key: string, value: boolean) => {
  setSort(key)
  if (value) {
    setOrder("asc")
  } else {
    setOrder("desc")
  }
  setUseEffectRequired(true)
}

const getReasonList = async () => {
  try {
    const response = await IncidentOrDeviationApi.getReasonList();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                slno: index + 1, 
                id : element.id,
                reason : element.reason,
              };
            });
            setReason(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    }
  } catch (error) {
    console.error('area response', error);
  }
}

const geAreaTypeList = async () => {
  try {
    const response = await areaService.getIncidentFilter();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            console.log(response.data, "respose--->geAreaTypeList===122")
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                slno: index + 1, 
                title: element.type_name,
                id : element.type_code,
                status : element.status,
              };
            });
            setAreas(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    }
  } catch (error) {
    console.error('area response', error);
  }
}

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Deviation / Incident Reporting" />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        />  
      <div className="exceptionHandlingTab">
        <button
          onClick={() => setTab("1")}
          className={tab === "1" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t('exceptionHandling.request')}
        </button>
        <button
          onClick={() => setTab("2")}
          className={tab === "2" ? "colTabBtnSel" : "colTabBtn"}
        >
              {t('exceptionHandling.history')}
        </button>
      </div>

      {tab === "1" && (
        <Requests
          plateDetails={plateDetails}
          columns={columns}
          plateSerielNum={plateSerielNum}
          totalElements={totalElements}
          page={page}
          totalPages={totalPages}
          reason={reason}
          areas={areas}
          handleApproveClick={handleApproveClick}
          handleRejectClick={handleRejectClick}
          handleAddClick={() => setShow(true)}
          handlePageChange={handlePageChange}
          handleFilter={handleFilter}
          handleSort={handleSort}
        />
      )}

      {tab === "2" && (
        <History 
          plateDetailsHistory={plateDetailsHistory}
          columnsHistory={columnsHistory}
          totalElementsHistory={totalElementsHistory}
          pageHistory={pageHistory}
          totalPagesHistory={totalPagesHistory}
          handleHistoryPageChange={handleHistoryPageChange}
          handleFilter={handleHistoryFilter}
          handleSort={handleSortHistory}
          reason={reason}
          areas={areas}
        />
      )}

      {/* add deviation modal */}
      <AddDeviationModel show={show} setShow={() => setShow(false)} reason={reason} setUseEffectRequired={setUseEffectRequired}/>


    </div>
  );
};

export default DeviationPage;
