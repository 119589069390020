import React, { useState, useEffect } from 'react'
import PageHeader from '../../../common/pageHeader'
import HeaderSection from '../../../common/headerSection'
import { Button, Modal } from 'react-bootstrap'
import CustomTable from '../../../../widgets/table'
import { columns } from './partials/columns'
import { TfiClose } from 'react-icons/tfi'
import { useTranslation } from 'react-i18next';
import AlertModal from '../../../../widgets/alertModal'
import { equipments } from '../../../../service/equipments'
import CustomToast from '../../../../widgets/toaster'
import { areaService } from '../../../../service/areaServices'
import Pagination from '../../../../widgets/pagination'
import { userService } from '../../../../service/userService'
import moment from 'moment'





const EquipmentCalibration = () => {

  const { t } = useTranslation();


  const [showAddModal, setShowAddModal] = useState(false)
  const [modalName, setModalName] = useState(t('configuration.addEquipment'))
  const [addEquipmentData, setAddEquipmentData] = useState({
    id: "",
    equipmentName: "",
    equipmentId: "",
    equipmentType: "",
    equipmentCalReq: "0",
    prevCalDate: "",
    calDueInDays: 0,
    calibrationData: "",
    capacity: 0,
    selectedArea: "",
    reason: "",
    isColCountManual : "",
    areaType: "",
    policyName: ""
  });
  const [addEquipmentAlert, setEquipmentAlert] = useState(false);
  const [useEffectRequired, setuseEffectRequired] = useState(false);
  const [userId, setUserId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  // table pagination for sample type
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [keyWord, setkeyWord] = useState("");
  const [eqipmentType , setEquipmentType] = useState("")
  const [equipmentList, setEquipmentList] = useState([]);
  const [areaList, setAreaList] = useState<any>([]);
  const [selectedArea, setSelectedArea] = useState<any>([]);
  interface Errors {
    equipmentName?: string,
    equipmentId?: string,
    equipmentType?: string,
    equipmentCalReq?: string,
    prevCalDate?: string,
    calDueInDays?: string,
    calibrationData?: string,
    capacity?: string,
    selectedArea?: string,
    reason?: string,
    policyName?:string
  }
  const [errors, setErrors] = useState<Errors>({});
  const [modalShow, setModalShow] = useState(false);
  const [selectedSlnoLen, setSelectedSlnoLen] = useState(0);
  const [cellWidth, setCellWidth] = useState(1);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [equipmetTypesList, setEquipmentTypesList] = useState([]);
  const [permission, setPermission] = useState<any>([]);
  const [selectedPolicyDuration, setSelectedPolicyDuration] = useState('');
  const[policyName , setPolicyName] = useState<Array<any>>([]);
  const [nextCalDate, setNextCalDate] = useState("");

  useEffect(() => {
    var storedData = localStorage.getItem('userDetails');
  
    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
      getPermissionByRole(storedObject[0].role_master_id);
    }
    listEquipments();
    listEquipmentTypes();
    getAreaList();
    setuseEffectRequired(false)
  }, [useEffectRequired])
  

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
        // try {
        //     if ( Array.isArray(response.data) && response.data?.length > 0) {
        //       let rowData: any = response.data.map((element: any, index: number) => {

        //         return { 
        //           // slno: index + 1, 
        //           label: element.title,
        //           value : element.id,
        //           // status : element.status,
        //         };
        //       });
        //       // setUserRoleList(rowData);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddEquipmentChange = (e: any, ) => {
    const { name, value } = e.target;
    const [id, areaType] = value.split("|");

    let regex;
    if (name === "capacity" || name === "calDueInDays") {
      regex = /^[0-9]{0,50}$/;
    } else if (name === "equipmentName") {
      regex = /^[a-zA-Z ]*$/;
    } else if (name === "equipmentId" || name === "calibrationData") {
      regex = /^[a-zA-Z][a-zA-Z0-9]*$/;
    }

    if (value === "") {
      // console.log("haa122")
    } else if (regex && !regex.test(value)) {
      return
    }

    if(name === "policyName") {
      const selectedPolicy = policyName.find((policy) => policy.id === value);
      if (selectedPolicy) {
        setSelectedPolicyDuration(selectedPolicy.duration);
      } else {
        setSelectedPolicyDuration('');
      }
    }   

    if(name === 'equipmentType'){
      setAddEquipmentData((prevFormData : any) => ({
      ...prevFormData,
      equipmentType: id,
      areaType: areaType,
    }));
    }else{
      setAddEquipmentData((prevFormData : any) => ({
      ...prevFormData,
      [name]: value,
      // equipmentType: id,
      // areaType: areaType,
    }));
    }
  }

   //create requipments
   const createEquipment = async () => {
    setEquipmentAlert(false)

    const selectedAreaIds = selectedArea.map((area: any) => area.id);
    const locationIdsString = selectedAreaIds.join(',');

    const dataToSend = {
        "id": addEquipmentData.id,
        "user_id" : userId,
        "name" : addEquipmentData.equipmentName,
        "type_id" : addEquipmentData.equipmentType,
        "calibration_required_flg" : addEquipmentData.equipmentCalReq,
        "last_calibration_date" : addEquipmentData.prevCalDate,
        "calibration_active_days" : addEquipmentData.calDueInDays,
        "calibration_details" : addEquipmentData.calibrationData,
        "equipment_code" : addEquipmentData.equipmentId,
        "capacity" : addEquipmentData.capacity,
        "location_ids" : locationIdsString,
        "interface_name" : "Equipment Configuration",
        "reason" : addEquipmentData.reason,
        "colony_count_flag" : addEquipmentData.isColCountManual,
        "policy_id" : addEquipmentData.policyName
    } 
    try {
      const response = await equipments.createEquipments(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
        addEquipmentModalClose()
        setuseEffectRequired(true)
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
    } catch (error) {
      console.error('user response', error);
    }
  }

  //get Equipments list from api  
  const listEquipments = async () => {
    try {
      const response = await equipments.listEquipments(page, pageSize, keyWord , eqipmentType);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    id : element.id,
                    name: element.name,
                    type_id: element.type_id,
                    type_name: element.equipment_type_name,
                    calibration_details: element.calibration_details,
                    capacity: element.capacity,
                    equipment_code: element.equipment_code,
                    calibration_required_flg: element.calibration_required_flg,
                    calibration_active_days: element.calibration_active_days,
                    last_calibration_date: element.last_calibration_date,
                    qr_image_path: element.qr_image_path,
                    location_ids: element.location_ids,
                    colony_count_flag : element.colony_count_flag,
                    area_type : element.area_type,
                    policy_id : element.policy_id,
                    duration: element.policy_duration

                };
              });
              setEquipmentList(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }else{
        setEquipmentList([]);
        setTotalPages(0)
        setTotalElements(0)
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  //get EquipmentTypes list from api  
  const listEquipmentTypes = async () => {
    setEquipmentTypesList([])
    try {
      const response = await equipments.listEquipmentTypes();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    id : element.id,
                    type_name: element.type_name,
                    area_type : element.area_type 
                };
              });
              setEquipmentTypesList(rowData)
            }else{
              setEquipmentTypesList([])
            }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error('user response', error);
    }
  }


  // get all area list
  const getAreaList = async () => {
    try {
      const response = await areaService.getParentAreaList([11]);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    id : element.id,
                    name: element.name,
                };
              });
              setAreaList(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error('user response', error);
    }
  }


  const addEquipmentBtnClick = () => {
    setModalName(t('configuration.addEquipment'))
    setShowAddModal(true)
  }

  const addEquipmentModalClose = () => {
    setShowAddModal(false)
    setAddEquipmentData({
      id: "",
      equipmentName: "",
      equipmentId: "",
      equipmentType: "",
      equipmentCalReq: "0",
      prevCalDate: "",
      calDueInDays: 0,
      calibrationData: "",
      capacity: 0,
      selectedArea: "",
      reason: "",
      isColCountManual: "",
      areaType: "",
      policyName: "",
    });
    setEquipmentTypesList([]);
    setSelectedPolicyDuration("");
    setSelectedArea([]);
    setErrors({});
    setuseEffectRequired(true);
    setNextCalDate("")
  }

  interface IncubatorListProps {
    incubators: Incubator[];
    onIncubatorClick: (incubator: Incubator) => void;
  }

  const IncubatorList: React.FC<IncubatorListProps> = ({ incubators, onIncubatorClick }) => {
    return (
      <div className='mx-3 p-2' style={{border:"1px solid grey", height: "25vh", width:"150px", overflow:"scroll"}}>
        {incubators.slice().reverse().map((incubator) => (
          <p className='my-1' style={{background: "grey", color: "white", fontSize:"small"}} key={incubator.id} onClick={() => onIncubatorClick(incubator)}>
            {incubator.name}
          </p>
        ))}
      </div>
    );
  };

  interface Incubator {
    id: string;
    name: string;
  }

  const handleIncubatorClick = (clickedIncubator: Incubator) => {
    const updatedDefaultIncubators = areaList.filter(
      (incubator:any) => incubator.id !== clickedIncubator.id
    );

    const updatedSelectedIncubators = selectedArea.filter(
      (incubator:any) => incubator.id !== clickedIncubator.id
    );

    setAreaList(updatedDefaultIncubators);
    setSelectedArea([clickedIncubator, ...selectedArea]);

  };

  const handleSelectedIncubatorClick = (clickedIncubator: Incubator) => {
    const updatedSelectedIncubators = selectedArea.filter(
      (incubator:any) => incubator.id !== clickedIncubator.id
    );

    setSelectedArea(updatedSelectedIncubators);
    setAreaList([clickedIncubator, ...areaList]);

  };

  // Get the current date in the format YYYY-MM-DD
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  //handle policy management alert
  const handlePolicyAddAlert = () => {
    const errors: Errors = {};
      // if (!addPolicyData.policyDuration) {
      //   errors.policyDuration = "Please enter Policy Duration";
      // }
      if (!addEquipmentData.equipmentName) {
        errors.equipmentName = "Please Enter Equipment Name";
      }
      if (!addEquipmentData.equipmentType) {
        errors.equipmentType = "Please select Equipment Type";
      }
      if (!addEquipmentData.equipmentId) {
        errors.equipmentId = "Please Enter Equipment Id";
      }
      if (addEquipmentData.id && !addEquipmentData.reason) {
        errors.reason = "Please enter Reason";
      }
      if(!addEquipmentData.policyName){
        errors.policyName = "Please select Policy Name";
      }
      // if (!addEquipmentData.calibrationData) {
      //     errors.calibrationData = "Please Enter Area Order";
      // }
      // if (!addEquipmentData.capacity) {
      //   errors.capacity = "Please Enter Capacity";
      // }
      if ( addEquipmentData.equipmentType === "1" && selectedArea.length < 1) {
        errors.selectedArea = "Please Select Area";
      }
      //   if(!addPolicyData.incubationCount){
      //     errors.incubationCount = "Please enter Incubation Count";
      //   }
      // }
      // if (addPolicyData.areaType === "8") {
      //   if(!addPolicyData.colCountMinRange){
      //     errors.colCountMinRange = "Please enter Min Colony Count";
      //   }
      //   if(!addPolicyData.colCountMaxRange){
      //     errors.colCountMaxRange = "Please enter Max Colony Count";
      //   }
      // }
      // if (addPolicyData.areaType === "10") {
      //   if(!addPolicyData.fingerDapType){
      //     errors.fingerDapType = "Please select finger dab type";
      //   }
      //   if(!addPolicyData.fingerDabDepartment){
      //     errors.fingerDabDepartment = "Please select finger dab department";
      //   }
      // }
      if (addEquipmentData.equipmentCalReq == "1") {
        if(!addEquipmentData.prevCalDate){
          errors.prevCalDate = "Enter Last Calibrated Date";
        }
        if(!addEquipmentData.calDueInDays){
          errors.calDueInDays = "Enter Duration Day Count";
        }
      }
      
      
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        console.log(errors)
        return;
      }
      setEquipmentAlert(true)
      setErrors({});
  }

  //handle edit click 
  const handleEditClick = (id: string) => {
    // setScheduleModalName("Edit Schedule")
    setModalName(t('configuration.editEquipment'))
    const filteredEquipmentDetails: any = equipmentList.filter((item: any) => item.slno === id);
    setAddEquipmentData({
      equipmentName: filteredEquipmentDetails[0]?.name,
      equipmentId: filteredEquipmentDetails[0]?.equipment_code,
      equipmentType: filteredEquipmentDetails[0]?.type_id,
      equipmentCalReq: filteredEquipmentDetails[0]?.calibration_required_flg,
      prevCalDate: filteredEquipmentDetails[0]?.last_calibration_date,
      calDueInDays: filteredEquipmentDetails[0]?.calibration_active_days,
      calibrationData: filteredEquipmentDetails[0]?.calibration_details,
      capacity: filteredEquipmentDetails[0]?.capacity,
      selectedArea: "",
      id: filteredEquipmentDetails[0]?.id,
      reason: filteredEquipmentDetails[0]?.reason,
      isColCountManual: filteredEquipmentDetails[0]?.colony_count_flag,
      areaType : filteredEquipmentDetails[0]?.area_type,
      policyName: filteredEquipmentDetails[0]?.policy_id
    })
    setSelectedPolicyDuration(filteredEquipmentDetails[0]?.duration)

    const filteredIncubators = areaList?.filter((incubator : any) => {
      const orderIds = filteredEquipmentDetails[0]?.location_ids?.split(',').map((id: any ) => id.trim());
      return orderIds?.includes(incubator.id);
    });

    const updatedIncubatorList = areaList?.filter((incubator: any) => !filteredIncubators?.includes(incubator));

    setSelectedArea(filteredIncubators)
    setAreaList(updatedIncubatorList)
    setShowAddModal(true)
  }

  //handle single and multi QR download button click
  const handleQrDownload = async (slno: string[], type: string) => {
    const filteredEquipmentDetails = equipmentList.filter((item: any) => slno.includes(item.slno));
    const selectedIds = filteredEquipmentDetails.map((item: any) => item.id).join(',');
    // const selectedSerielNo = filteredEquipmentDetails.map((item: any) => item.serial_number);
    setSelectedSlnoLen(slno.length)
    if (type === "single") {
      // setShowGenerateQrAlert(true)
      //  setImageId(selectedIds)
    } else if (type === "multi") {
        setModalShow(true)
        setSelectedEquipments(filteredEquipmentDetails)
    }
  }

  //create grid for qr section 
  const createGrid = () => {
    return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {selectedEquipments?.map((cellIndex :any, index :number) => (
                <div key={index} style={{border: '0.5px solid grey', margin: "15px 15px", "alignItems" : "center"}}>
                  <img src={cellIndex?.qr_image_path} alt={`Image ${cellIndex?.equipment_code}`} style={{ width: `${cellWidth}cm`, height: `${cellWidth}cm`, pageBreakInside: 'avoid' }} />
                  <p style={{"fontSize": `${cellWidth + 10}px`, "fontWeight":"800", "margin": "2px", "textAlign" : "center"}} >{cellIndex?.name}</p>
                </div>
              ))}
            </div>
    )
  }

  //handle download qr button 
  const handleDownloadBtn = () => {
    const content : any = document.getElementById('innerDiv');
    const pri: any = window.open('', '', 'height=600,width=800');
    pri.document.write(content.innerHTML);
    pri.onafterprint = () => {
      pri.close();
      setModalShow(false);
      setCellWidth(1);
    };
    pri.print();

  }

  //handle table pagination 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage)
    if(pageSize) {
      setPageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  //handle filter section in user management table 
  const handleFilter = (key: string, value: any) => {
        if(key === "EquipmentType") {
          setEquipmentType(value)
        } else if (key === "search") {
          setkeyWord(value)
        }
        setuseEffectRequired(true)
  }

  const getPolicyNameList = async () => {
    try {    
      const locationTypeNumber = parseInt(addEquipmentData.areaType, 10); 
      const response = await areaService.getPolicyNameList(locationTypeNumber);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    activity_name: element.activity_name,
                    duration: element.duration,
                    id : element.id,
                };
              });
              setPolicyName(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  const checkSpecialChar =(e : any)=>{
    if(!/[0-9a-zA-Z]/.test(e.key)){
     e.preventDefault();
    }
   };

  useEffect(() =>{
    getPolicyNameList();
  },[addEquipmentData.areaType])

  useEffect(() => {
    const { prevCalDate, calDueInDays } = addEquipmentData;

    if(prevCalDate) {
      const startDateObj = new Date(prevCalDate);
      var newDate = new Date(startDateObj.setTime( startDateObj.getTime() + calDueInDays * 86400000 ));
      const formattedDate = moment(newDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('DD-MM-YYYY');
      setNextCalDate(formattedDate)
    } else {
      setNextCalDate("")
    }

  }, [addEquipmentData.prevCalDate, addEquipmentData.calDueInDays]);

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('configuration.equipmentConfiguration')} />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        />

      <div className='me-3' style={{marginTop : "20px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    tableHead={t('configuration.equipments')}
                    data={equipmentList} 
                    columns={columns} 
                    isEditable={permission?.includes("edit_equipment")} 
                    isTempDeletable={false}
                    totalElements={totalElements} 
                    isActionVisible={true} 
                    isViewVisible={false} 
                    isSeachable={true}
                    isToggleStatus={false}
                    isResetPassword={false}
                    addButton={permission?.includes("add_equipment")}
                    addButtonText={t('configuration.addEquipment')}
                    onQrDownloadClick={handleQrDownload}
                    isQrDownloadable={true}
                    onUseEffectRequired={() => setuseEffectRequired(true)}
                    onAddClick={addEquipmentBtnClick}
                    onFilterClick={handleFilter}
                    onEditClick={handleEditClick}
                    changeRoleButton={false}
                />
            </div>
            <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </div>

        <Modal 
              className="top-right-modal-md"
              centered={false}
              show={showAddModal}
          >
            <div>
              <div className="modalHead">
                  <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                  <div onClick={() => addEquipmentModalClose()} className="p-2 mx-3 my-2 ">
                    <TfiClose fill="#FFFFFF" />
                  </div>
              </div>

              <div>
              <div className="selectWrap">
                    <div className="form-group my-1">
                      <label htmlFor="" >{t('configuration.equipmentName')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                        id="equipmentName"
                        value={addEquipmentData.equipmentName}
                        name="equipmentName"
                        type='text'
                        placeholder={t('configuration.equipmentName')}
                        className="placeholder-style"
                        onChange={handleAddEquipmentChange}
                        maxLength={100}
                      />
                      {errors.equipmentName && <p className="errorText">{errors.equipmentName}</p>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="">{t('configuration.equipmentID')}<span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input
                        id="equipmentId"
                        value={addEquipmentData.equipmentId}
                        name="equipmentId"
                        placeholder={t('configuration.equipmentID')}
                        className="placeholder-style"
                        onChange={handleAddEquipmentChange} 
                        maxLength={100}
                      /> 
                      {errors.equipmentId && <p className="errorText">{errors.equipmentId}</p>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="">{t('configuration.equipmentType')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                       <select
                        id="equipmentType"
                        value={`${addEquipmentData.equipmentType}|${addEquipmentData.areaType}`}
                        name="equipmentType"
                        className="placeholder-style"
                        onChange={handleAddEquipmentChange} 
                      >
                        <option value="">Select</option>
                        {equipmetTypesList.map((equipmentType: any) => (
                          <option key={equipmentType.id} value={`${equipmentType.id}|${equipmentType.area_type}`}>
                            {equipmentType.type_name}
                          </option>
                        ))}
                      </select> 
                      {errors.equipmentType && <p className="errorText">{errors.equipmentType}</p>}
                    </div>
              </div>

              <div className="selectWrap-new">                
                    <div className="form-group my-1 mx-4 px-3">
                      <label className=''>{t('configuration.equipmentCalibrationRequired')}</label>
                      <div className="radio-container">
                      <input
                        type="radio"
                        id="equipmentCalReq" // Unique id for the "Yes" option
                        name="equipmentCalReq"
                        value="1" // Assign value 1 for "Yes"
                        checked={addEquipmentData.equipmentCalReq === "1"}
                        onChange={handleAddEquipmentChange}
                        />
                      <label htmlFor="equipmentCalReq">Yes</label>
                      <input
                        type="radio"
                        id="equipmentCalReq" // Unique id for the "No" option
                        name="equipmentCalReq"
                        value="0" // Assign value 0 for "No"
                        checked={addEquipmentData.equipmentCalReq === "0"}
                        onChange={handleAddEquipmentChange}
                        />
                      <label htmlFor="equipmentCalReq">No</label>
                      </div>
                    </div>

                    {addEquipmentData?.equipmentCalReq === '1' && (
                          <div className="form-group my-1 mx-3 px-3">
                            <label>{t('configuration.previousCalibratedDate')} <span style={{ color: 'red' }}>*</span></label>
                            <br/>
                            <input
                              id="prevCalDate"
                              value={addEquipmentData.prevCalDate}
                              name="prevCalDate"
                              type="date"
                              className="placeholder-style"
                              onChange={handleAddEquipmentChange}
                              max={getCurrentDate()}
                            />
                            {errors.prevCalDate && <p className="errorText">{errors.prevCalDate}</p>}
                          </div>
                      )}
                    {addEquipmentData?.equipmentCalReq === '1' && ( 
                        <div className="form-group my-1 mx-4 px-3">
                          <label>Calibration Due <span style={{ color: 'red' }}>*</span></label>
                          <br />
                          <input
                            id="calDueInDays"
                            value={addEquipmentData.calDueInDays}
                            name="calDueInDays"
                            type="text"
                            className="placeholder-style"
                            placeholder="Calibration Due in (Days)"
                            onChange={handleAddEquipmentChange}
                            maxLength={5}
                          />
                          {errors.calDueInDays && <p className="errorText">{errors.calDueInDays}</p>}
                        </div>
                    )}
                  </div>

                <div className="selectWrap-new">
                  {/* <div className="form-group my-1 mx-3 px-2"> */}
                  <div className="form-group my-1 mx-3 px-2">
                      <label htmlFor="" >Remarks</label>
                      <br />
                      <input 
                        id="calibrationData"
                        value={addEquipmentData.calibrationData}
                        name="calibrationData"
                        type='text'
                        placeholder='Remarks'
                        className="placeholder-style"
                        onChange={handleAddEquipmentChange}
                        maxLength={100}
                      />
                      {errors.calibrationData && <p className="errorText">{errors.calibrationData}</p>}
                    </div>

                  {(addEquipmentData.equipmentType === "3" || addEquipmentData.equipmentType === "4") && (
                  <div className="form-group my-1 mx-5 px-2">
                    <label htmlFor="" >Capacity </label>
                    <br />
                    <input 
                      id="capacity"
                      value={addEquipmentData.capacity}
                      name="capacity"
                      type='text'
                      placeholder='Capacity'
                      className="placeholder-style"
                      onChange={handleAddEquipmentChange}
                      maxLength={5}
                    />
                    {/* {errors.capacity && <p className="errorText">{errors.capacity}</p>} */}
                  </div>
                  )}

                    {addEquipmentData.equipmentType === "2" && (
                      <div className="form-group my-1 mx-2 px-3">
                        <label >{t('policyManagement.count_entry')}</label>
                        <div className="radio-container">
                          <input
                            type="radio"
                            id="isColCountManual"
                            name="isColCountManual"
                            value="1"
                            checked={addEquipmentData.isColCountManual === "1"}
                            onChange={handleAddEquipmentChange}
                          />
                          <label htmlFor="isColCountManual">Yes</label>
                          <input
                            type="radio"
                            id="isColCountManual"
                            name="isColCountManual"
                            value="0"
                            checked={addEquipmentData.isColCountManual === "0"}
                            onChange={handleAddEquipmentChange}
                          />
                          <label htmlFor="isColCountManual">No</label>
                        </div>
                     </div>
                    )}
                </div>

                <div className="d-flex my-2">
                  {addEquipmentData.equipmentType === "1" && (
                  <div className='form-group my-3 mx-3 px-2' style={{marginLeft:"5%"}}>
                    <p> Location List</p>
                    <IncubatorList incubators={areaList} onIncubatorClick={handleIncubatorClick} />                
                  </div>
                  )}

                  {addEquipmentData.equipmentType === "1" && (
                  <div className='form-group my-3 mx-5 px-2' style={{marginLeft:"10%"}}>
                    <p>Selected Location <span style={{ color: 'red' }}>*</span></p>
                    <IncubatorList incubators={selectedArea} onIncubatorClick={handleSelectedIncubatorClick} />
                    {errors.selectedArea && <p className="errorText ms-3">{errors.selectedArea}</p>}
                  </div>
                  )}

                  <div className="selectWrap-new mt-2">
                    {addEquipmentData.id && (
                    <div className="form-group my-1 mx-2 px-3">
                        <label htmlFor="" className='ReasonLabel'>{t('equipmentCalibration.reason')} <span style={{ color: 'red' }}>*</span></label>
                        <br />
                        <input 
                            id="reason"
                            value={addEquipmentData.reason}
                            name="reason"
                            type='text'
                            placeholder={t('equipmentCalibration.reason')}
                            className="reasonClass"
                            onChange={handleAddEquipmentChange} 
                            onKeyDown={(e)=>checkSpecialChar(e)}
                        />
                        {errors.reason && <p className="errorText">{errors.reason}</p>}
                    </div>
                    )}
                  </div>

                </div>

                <div className="selectWrap">             
                  <div className="form-group my-1">
                    <label>{t('areaManagement.policy_name')}<span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <select
                      id="policyName"
                      value={addEquipmentData.policyName}
                      name="policyName"
                      onChange={handleAddEquipmentChange} 
                    >
                      <option value="">Select</option>
                      {policyName.map((policy) => (
                        <option key={policy.id} value={policy.id}>
                          {policy.activity_name}
                        </option>
                      ))}
                    </select>
                    {errors.policyName && <p className="errorText">{errors.policyName}</p>}
      
                  </div>

                  <div className="form-group my-1">
                    <label>{t('areaManagement.duration')}</label>
                    <br />
                    <input 
                       id="duration"
                       value={selectedPolicyDuration}
                       name="duration"
                       type='text'
                       readOnly
                       placeholder='Duration'
                       className="placeholder-style"
                       onChange={handleAddEquipmentChange} 
                    />
                  </div>
                  
                  {addEquipmentData?.equipmentCalReq === '1' ? (
                  <div className="form-group my-1">
                    <label>Next Calibration Date</label>
                    <br />
                    <input 
                       id="nextCalibratingDate"
                       value={nextCalDate}
                       name="nextCalibratingDate"
                       type='text'
                       readOnly
                       placeholder='Next Calibration Date'
                       className="placeholder-style"
                    />
                  </div>
                  ) : (
                    <div className="form-group my-1" style={{"visibility":"hidden"}} >
                      <label>Next Calibrating Date</label>
                      <br />
                      <input 
                        id="nextCalibratingDate"
                        value={nextCalDate}
                        name="nextCalibratingDate"
                        type='text'
                        readOnly
                        placeholder='Next Calibrating Date'
                        className="placeholder-style"
                      />
                    </div>
                  )}

                  
              </div>
      
              </div>

              <div className="bottomArea">
                  <button onClick={() => addEquipmentModalClose()} className="modalCancelBtn">
                  {t('buttons.cancel')}
                  </button>
                  <button onClick={() => handlePolicyAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
                  {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
                </div>
            </div>
          
        </Modal>


        { /*modal for Generate Area QR*/}
        <Modal
              // size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalShow}
            >
             <Modal.Header closeButton onClick={() => { setModalShow(false); setCellWidth(1); }}>
                <Modal.Title id="contained-modal-title-vcenter">
                {t('buttons.download_qr')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Selected {selectedSlnoLen} Equipment(s) for download QR</p>
                <div className="selectWrap">
                  <div className="form-group my-1">
                    <label>{t('areaManagement.cell_width')}</label>
                    <br />
                    <input 
                       type="number"
                       id="cellWidth"
                       value={cellWidth}
                       min="1"
                       onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (value > 0) {
                          setCellWidth(value); // Update state only if value is greater than zero
                        }
                      }}
                    />
                  </div>
                </div>
                <div id="innerDiv" hidden style={{ border: '1px solid black', padding: '5px', margin:"5px", overflow: "scroll",height: "80vh"}}>
                  {createGrid()}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => handleDownloadBtn()}>{t('buttons.submit')}</Button>
              </Modal.Footer>
        </Modal>


        {/* Alert modal for Add  policy */}
        <AlertModal 
          show={addEquipmentAlert}
          onHide={() => setEquipmentAlert(false)}
          title='Alert'
          message={`${modalName}`}
          onConfirm={createEquipment}
        />

    </div>
  )
}

export default EquipmentCalibration