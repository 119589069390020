export const columns: TableColumn[] = [
  {
    Header: "tableCommon.sl_no",
    accessor: "slno",
  },
  {
    Header: "scheduleOperator.tableColumns.production_schedule_name",
    accessor: "production_schedule_name",
  },
  {
    Header: "scheduleOperator.tableColumns.operator_name",
    accessor: "operator_name",
  },
  {
    Header: "scheduleOperator.tableColumns.start_date_time",
    accessor: "start_date_time",
  },
  {
    Header: "scheduleOperator.tableColumns.end_date_time",
    accessor: "end_date_time",
  },
  {
    Header: "scheduleOperator.tableColumns.activity_type_name",
    accessor: "activity_type_name",
  },
  {
    Header: "scheduleOperator.tableColumns.process_name",
    accessor: "process_name",
  },
  {
    Header: "scheduleOperator.tableColumns.area_name",
    accessor: "area_name",
  },
  {
    Header: "scheduleOperator.tableColumns.location_name",
    accessor: "location_name",
  },
  {
    Header: "scheduleOperator.tableColumns.work_shift_name",
    accessor: "work_shift_name",
  },
  {
    Header: "scheduleOperator.tableColumns.created_at",
    accessor: "created_at",
  },
];
export const addedOperatorsColumns: TableColumn[] = [
  {
    Header: "tableCommon.sl_no",
    accessor: "slno",
  },
  {
    Header: "scheduleOperator.activityType",
    accessor: "activity_type_name",
  },
  {
    Header: "scheduleOperator.process",
    accessor: "process_name",
  },
  {
    Header: "scheduleOperator.location",
    accessor: "location_name",
  },
  {
    Header: "scheduleOperator.work_shift",
    accessor: "work_shift_name",
  },
  {
    Header: "scheduleOperator.users",
    accessor: "user_name",
  },
];
export const reassignOperatorsColumns: TableColumn[] = [
  {
    Header: "tableCommon.sl_no",
    accessor: "slno",
  },
  {
    Header: "scheduleOperator.production_schedule_name",
    accessor: "production_schedule_name",
  },
  {
    Header: "scheduleOperator.activityType",
    accessor: "activity_type_name",
  },
  {
    Header: "scheduleOperator.process",
    accessor: "process_name",
  },
  {
    Header: "scheduleOperator.tableColumns.start_date_time",
    accessor: "start_date_time",
  },
  {
    Header: "scheduleOperator.tableColumns.end_date_time",
    accessor: "end_date_time",
  },
  {
    Header: "scheduleOperator.work_shift",
    accessor: "work_shift_name",
  },
];

interface TableColumn {
  Header: string;
  accessor: string;
}
