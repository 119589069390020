import React, { useEffect, useState } from 'react'
import PageHeader from '../../../common/pageHeader'
import HeaderSection from '../../../common/headerSection'
import { Modal} from 'react-bootstrap'
import CustomTable from '../../../../widgets/table'
import { columns, columnsSubType } from './partials/columns'
import { TfiClose } from 'react-icons/tfi'
import { useTranslation } from 'react-i18next';
import { rawMaterials } from '../../../../service/rawMaterials'
import AlertModal from '../../../../widgets/alertModal'
import CustomToast from '../../../../widgets/toaster'
import Pagination from '../../../../widgets/pagination';
import { userService } from '../../../../service/userService'



const AddRawMaterials = () => {

  const { t } = useTranslation();


  const [showAddModal, setShowAddModal] = useState(false);
  const [modalName, setModalName] = useState(t('configuration.addSampleType'));
  const [modalEdit, setModalEdit] = useState(false);
  const [addSampleData, setAddSampleData] = useState({
    materialName: "",
    materialId: "",
    type: "",
    subType: "",
    id: "",
    reason: ""
  });
  const [rawMaterialsType, setRawMaterialsType] = useState([]);
  const [rawMaterialsDropDown, setRawMaterialsDropDown] = useState([]);
  const [rawMaterialsSubType, setRawMaterialsSubType] = useState([]);
  const [addRawMaterialAlert, setAddRawMaterialAlert] = useState(false);
  const [idAndStatus, setIdAndStatus] = useState({ 
    id: "",
    status: "" 
  });
  const [showUserStatusChangeAlert, setShowUserStatusChangeAlert] = useState(false);
  const [showSubTypeChangeAlert, setShowSubTypeChangeAlert] = useState(false);
  const [tab, setTab] = useState("1")
  const [useEffectRequired, setuseEffectRequired] = useState(false)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [SelectedRawMaterialId, setSelectedRawMaterialId] = useState("");
   // table pagination for sample type
   const [sampleTypePage, setSampleTypePage] = useState(1);
   const [sampleTypepageSize, setSampleTypePageSize] = useState(5);
   const [sampleTypetotalPages, setSampleTypeTotalPages] = useState(0);
   const [sampleTypetotalElements, setSampleTypeTotalElements] = useState(0);
   // table pagination for sample Sub type
   const [sampleSubTypePage, setSampleSubTypePage] = useState(1);
   const [sampleSubTypepageSize, setSampleSubTypePageSize] = useState(5);
   const [sampleSubTypetotalPages, setSampleSubTypeTotalPages] = useState(0);
   const [sampleSubTypetotalElements, setSampleSubTypeTotalElements] = useState(0);
   const [keyWord, setkeyWord] = useState("")
   const [keyWordSubType, setKeyWordSubType] = useState("");
   const [materialId, setMaterialId] = useState("");

   interface Errors {
    materialName?: string,
    materialId?: string,
    type?: string,
    subType?: string,
    reason?: string
  }
  
  const [errors, setErrors] = useState<Errors>({});
  const [userId, setUserId] = useState("");
  const [authToken , setAuthToken] = useState("");
  const [authUserID , setAuthUserID] = useState("");
  const [permission, setPermission] = useState<any>([]);





  useEffect(() => {

    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      setAuthToken(storedObject[0].x_auth_token);
      setAuthUserID(storedObject[0].x_userid);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listRawMaterialType();
    listDropDownType();
    listRawMaterialSubType();
    setuseEffectRequired(false)
  }, [useEffectRequired])
  

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
        // try {
        //     if ( Array.isArray(response.data) && response.data?.length > 0) {
        //       let rowData: any = response.data.map((element: any, index: number) => {

        //         return { 
        //           // slno: index + 1, 
        //           label: element.title,
        //           value : element.id,
        //           // status : element.status,
        //         };
        //       });
        //       // setUserRoleList(rowData);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  }

  //get all_raw_material_type list from api  
  const listRawMaterialType = async () => {
    try {
      const response = await rawMaterials.listRawMaterialType(sampleTypePage, sampleTypepageSize, authToken ,authUserID , keyWord);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setSampleTypeTotalPages(response?.totalPages)
              setSampleTypeTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    material_type: element.material_type,
                    material_type_code: element.material_type_code,
                    id : element.id,
                    status : element.status,
                };
              });
              setRawMaterialsType(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }else {
        setRawMaterialsType([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  const listDropDownType = async () => {
    try {
      const response = await rawMaterials.listDropDownType(authToken ,authUserID);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              // setSampleTypeTotalPages(response?.totalPages)
              // setSampleTypeTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    material_type: element.material_type,
                    material_type_code: element.material_type_code,
                    id : element.id,
                    status : element.status,
                };
              });
              setRawMaterialsDropDown(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  //get all_raw_material_type list from api 
  const listRawMaterialSubType = async () => {
    try {
      const response = await rawMaterials.listRawMaterialSubType(sampleSubTypePage, sampleSubTypepageSize, authToken ,authUserID, keyWordSubType, materialId);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setSampleSubTypeTotalPages(response?.totalPages)
              setSampleSubTypeTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    material_type: element.material_type_name,
                    name: element.name,
                    material_type_code: element.material_sub_type_code,
                    id : element.id,
                    material_type_id: element.material_type_id,
                    status : element.status,
                };
              });
              setRawMaterialsSubType(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      } else {
        setRawMaterialsSubType([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  const handleAddRawMaterialChange = (e : any) => {
    const { name, value } = e.target;

    // const { name, value } = e.target;
    let regex;
    if (name === "materialName") {
      regex = /^[a-zA-Z ]*$/;
    } else if (name === "materialId") {
      regex = /^[a-zA-Z][a-zA-Z0-9]*$/;
    }

    if (value === "") {
      // console.log("haa122")
    } else if (regex && !regex.test(value)) {
      return
    }

    setAddSampleData((prevFormData : any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const checkSpecialChar =(e : any)=>{
    if(!/[0-9a-zA-Z]/.test(e.key)){
     e.preventDefault();
    }
   };


  //create raw material type 
  const createRawMaterialType = async () => {
    setAddRawMaterialAlert(false)

    const dataToSend = {
        id: addSampleData.id,
        material_type : addSampleData.materialName,
        material_type_id : addSampleData.materialId,
        "interface_name" : "Add Sample Type",
        reason : addSampleData.reason
    }

    try {
      const response = await rawMaterials.createRawMaterialType(dataToSend, authToken ,authUserID);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
        addEquipmentModalClose();
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      // addEquipmentModalClose()
      setuseEffectRequired(true)
    } catch (error) {
      console.error('user response', error);
    }
  }

  //create raw material sub type  
  const createRawMaterialSubType = async () => {
    setAddRawMaterialAlert(false)
    const dataToSend = {
        id: addSampleData.id,
        material_type_id : addSampleData.subType,
        material_sub_type_name : addSampleData.materialName,
        material_sub_type_code : addSampleData.materialId,
        "interface_name" : "Add Sample SubType",
        reason : addSampleData.reason
    }

    try {
      const response = await rawMaterials.createRawMaterialSubType(dataToSend, authToken ,authUserID);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      addEquipmentModalClose()
      setuseEffectRequired(true)
    } catch (error) {
      console.error('user response', error);
    }
  }

  //handle edit click 
  const handleEditClick = (id: string) => {
    setModalName(t('configuration.editSampleType'))
    setModalEdit(true)
    const filteredUserDetails: any = rawMaterialsType.filter((item: any) => item.slno === id);
    setAddSampleData({
      materialName: filteredUserDetails[0]?.material_type,
      materialId: filteredUserDetails[0]?.material_type_code,
      type: tab,
      subType: "",
      id: filteredUserDetails[0]?.id,
      reason: filteredUserDetails[0]?.reason
    })
    const selectedIds = filteredUserDetails.map((item: any) => item.id).join(',');
    setSelectedRawMaterialId(selectedIds)
    setShowAddModal(true)
  }

   //handle sub type edit click 
   const handleSubTypeEditClick = (id: string) => {
    // setScheduleModalName("Edit Schedule")
    setModalName(t('configuration.editSubType'))
    setModalEdit(true)
    const filteredUserDetails: any = rawMaterialsSubType.filter((item: any) => item.slno === id);
    setAddSampleData({
      materialName: filteredUserDetails[0]?.name,
      materialId: filteredUserDetails[0]?.material_type_code,
      type: tab,
      subType: filteredUserDetails[0]?.material_type_id,
      id: filteredUserDetails[0]?.id,
      reason: filteredUserDetails[0]?.reason
    })
    const selectedIds = filteredUserDetails.map((item: any) => item.id).join(',');
    setSelectedRawMaterialId(selectedIds)
    setShowAddModal(true)
  }

  //handle policy management alert
  const handleSampleAddAlert = () => {
    const errors: Errors = {};
      if (!addSampleData.materialId) {
        errors.materialId = t('configuration.errors.materialId');
      }
      if (!addSampleData.materialName) {
        errors.materialName = t('configuration.errors.materialName');
      }
      if (!addSampleData.type) {
        errors.type = t('configuration.errors.type');
      }
      if (addSampleData.id && !addSampleData.reason) {
        errors.reason = t('configuration.errors.reason');
      }
      if (addSampleData.type == "2") {
        if(!addSampleData.subType){
          errors.subType = t('configuration.errors.subType');
        }
      }
      
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        console.log(errors)
        return;
      }
      setAddRawMaterialAlert(true)
      setErrors({});
  }


  const handleSubmit = () => {
    setAddRawMaterialAlert(false)
    if (addSampleData.type == "1") {
      createRawMaterialType()
    } else if (addSampleData.type == "2") {
      createRawMaterialSubType()
    }
  }

  //show status alert modal for status change 
  const handleStatusAlert = async (id:any , status: string) => {
    setIdAndStatus({ id, status });
    setShowUserStatusChangeAlert(true)
  }

   //show status alert modal for sub type status change 
   const handleSubTypeStatusAlert = async (id:any , status: string) => {
    setIdAndStatus({ id, status });
    setShowSubTypeChangeAlert(true)
  }

  //api call to make user active or inactive 
  const handleStatusChange = async () => {
    let updatedStatus = "";
      if (idAndStatus?.status === "active") {
        updatedStatus = "inactive";
      } else {
        updatedStatus = "active";
      }
      
      try {
        const response = await rawMaterials.changeTypeStatus(idAndStatus.id, updatedStatus, authToken ,authUserID ,"Sample Type Status");
        
        if (response.status && response.statusCode === 200) {
          setuseEffectRequired(true)
          setShowUserStatusChangeAlert(false)
          updatedStatus = ""
          setToastMessage(response.message)
          setToastType('success')
        } else {
          setShowUserStatusChangeAlert(false)
          updatedStatus = ""
          setToastMessage(response.message)
          setToastType('error')
        }
        setShowToast(true)
      } catch (error) {
        console.error(error);
      }
  }

  //api call to make user active or inactive 
  const handleSubTypeStatusChange = async () => {
    let updatedStatus = "";
      if (idAndStatus?.status === "active") {
        updatedStatus = "inactive";
      } else {
        updatedStatus = "active";
      }
      
      try {
        const response = await rawMaterials.changeSubTypeStatus(idAndStatus.id, updatedStatus, authToken ,authUserID, "Sample SubType Status");
        
        if (response.status && response.statusCode === 200) {
          setuseEffectRequired(true)
          setShowSubTypeChangeAlert(false)
          updatedStatus = ""
          setToastMessage(response.message)
          setToastType('success')
        } else {
          setShowSubTypeChangeAlert(false)
          updatedStatus = ""
          setToastMessage(response.message)
          setToastType('error')
        }
        setShowToast(true)
      } catch (error) {
        console.error(error);
      }
  }

  const addEquipmentBtnClick = () => {
    setModalName(t('configuration.addSampleType'))
    setModalEdit(false)
    setShowAddModal(true)
  }

  const addSubEquipmentBtnClick = () => {
    setModalName(t('configuration.addSubType'))
    setModalEdit(false)
    setShowAddModal(true)
  }

  const addEquipmentModalClose = () => {
    setShowAddModal(false)
    setAddSampleData({
      materialName: "",
      materialId: "",
      type: "",
      subType: "",
      id: "",
      reason: ""
    })
    setErrors({})
  }

  //handle table pagination 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setSampleTypePage(newPage)
    if(pageSize) {
      setSampleTypePageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  //handle table pagination 
  const handleSubTypePageChange = (newPage : any, pageSize?: number) => {
    setSampleSubTypePage(newPage)
    if(pageSize) {
      setSampleSubTypePageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  //handle filter section in sample type
  const handleFilter = (key: string, value: any) => {
    if(key === "Role") {
      // setRole(value)
    } else if (key === "search") {
      setkeyWord(value)
    }
    setuseEffectRequired(true)
  }

  const handleSubTypeFilter = (key: string, value: any) => {
    if(key === "MaterialId") {
      setMaterialId(value)
    } else if (key === "search") {
      setKeyWordSubType(value)
    }
    setuseEffectRequired(true)
  }


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('configuration.addSampleType')} />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 

      <div className='exceptionHandlingTab'>
              <button onClick={() => setTab("1")} className={tab === "1" ? 'colTabBtnSel': 'colTabBtn'}>{t('configuration.sampleType')}</button>
              <button onClick={() => setTab("2")} className={tab === "2" ? 'colTabBtnSel': 'colTabBtn'}>{t('configuration.subType')}</button>
        </div>

        {tab === "1" && (
        <div className='me-3' style={{marginTop : "20px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    tableHead={t('configuration.sampleType')}
                    data={rawMaterialsType} 
                    columns={columns} 
                    isEditable={permission?.includes("edit_rawmaterial_type")} 
                    isTempDeletable={false}
                    totalElements={sampleTypetotalElements} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    isSeachable={true}
                    isToggleStatus={permission?.includes("edit_rawmaterial_type")}
                    isResetPassword={false}
                    addButton={permission?.includes("add_rawmaterial_type")}
                    addButtonText={t('configuration.addSampleType')}
                    onUseEffectRequired={() => setuseEffectRequired(true)}
                    onFilterClick={handleFilter}
                    onStatusChange={handleStatusAlert}
                    onAddClick={addEquipmentBtnClick}
                    // onSortClick={handleSort}
                    onEditClick={handleEditClick}
                    // onResetPassClick={handleResetPassClick}
                    changeRoleButton={false}
                />
            </div>
            <div>
              <Pagination
                page={sampleTypePage}
                totalPages={sampleTypetotalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </div>
        )}

        {tab === "2" && (
          <div className='me-3' style={{marginTop : "20px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    tableHead={t('configuration.subType')}
                    data={rawMaterialsSubType} 
                    columns={columnsSubType} 
                    isEditable={permission?.includes("edit_rawmaterial_type")} 
                    isTempDeletable={false}
                    totalElements={sampleSubTypetotalElements} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    isSeachable={true}
                    isToggleStatus={permission?.includes("edit_rawmaterial_type")}
                    isResetPassword={false}
                    addButton={permission?.includes("add_rawmaterial_type")}
                    addButtonText={t('configuration.addSubType')}
                    onUseEffectRequired={() => setuseEffectRequired(true)}
                    onFilterClick={handleSubTypeFilter}
                    onStatusChange={handleSubTypeStatusAlert}
                    onAddClick={addSubEquipmentBtnClick}
                    // onSortClick={handleSort}
                    onEditClick={handleSubTypeEditClick}
                    // onResetPassClick={handleResetPassClick}
                    changeRoleButton={false}
                    // changeRoleButtonText={t('userManagement.change_role')}
                    // onRoleDEpartmentClick={onRoleDEpartmentClick}
                    // onTempDeleteClick={handleTempDelete}
                />
            </div>
            <div>
              <Pagination
                page={sampleSubTypePage}
                totalPages={sampleSubTypetotalPages}
                handlePageChange={handleSubTypePageChange}
              />
            </div>
        </div>
        )}

        <Modal 
              className="top-right-modal-md"
              centered={false}
              show={showAddModal}
          >
            <div>
              <div className="modalHead">
                  <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                  <div onClick={() => addEquipmentModalClose()} className="p-2 mx-3 my-2 ">
                    <TfiClose fill="#FFFFFF" />
                  </div>
              </div>

              <div>
              <div className="selectWrap">
                    <div className="form-group my-1">
                      <label htmlFor="">{t('configuration.type')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                       <select
                        id="type"
                        value={addSampleData.type}
                        name="type"
                        className={`placeholder-style ${modalEdit ? 'disabled-css' : ''}`}
                        style={{opacity: modalEdit ? '0.3' : '1'}}
                        onChange={handleAddRawMaterialChange} 
                        disabled={modalEdit}
                      >
                        <option value="">Select</option>
                        <option value="1">{t('configuration.sampleType')}</option>
                        <option value="2">{t('configuration.subType')}</option>
                      </select> 
                      {errors.type && <p className="errorText">{errors.type}</p>}
                    </div>

                    <div className="form-group my-1">
                      <label htmlFor="" >{t('configuration.materialName')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                        id="materialName"
                        value={addSampleData.materialName}
                        name="materialName"
                        type='text'
                        placeholder={t('configuration.materialName')}
                        className="placeholder-style"
                        onChange={handleAddRawMaterialChange}
                        maxLength={100}
                      />
                      {errors.materialName && <p className="errorText">{errors.materialName}</p>}
                    </div>

                    <div className="form-group my-1">
                      <label htmlFor="">{t('configuration.materialCode')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input
                        id="materialId"
                        value={addSampleData.materialId}
                        name="materialId"
                        placeholder={t('configuration.materialCode')}
                        className="placeholder-style"
                        onChange={handleAddRawMaterialChange} 
                        maxLength={100}
                      /> 
                      {errors.materialId && <p className="errorText">{errors.materialId}</p>}
                    </div>
              </div>

                <div className="selectWrap-new">
                  {/* {addSampleData.type == "1" && (
                    <div className="form-group my-1 mx-3 px-2">
                      <label htmlFor="" >Sample Type</label>
                      <br />
                      <select
                        id="sampleType"
                        value={addSampleData.sampleType}
                        name="sampleType"
                        className="placeholder-style"
                        // onChange={handleAddPolicyChange} 
                      >
                        <option value="">--select--</option>
                        <option value="1">Sample Type 1</option>
                        <option value="2">Sample Type 2</option>
                      </select> 
                      {/* {errors.policyName && <p className="errorText">{errors.policyName}</p>} */}
                    {/* </div>
                  )} */} 

                  {addSampleData.id && (
                  <div className="form-group my-1 ms-3 me-1 ps-2 pe-5">
                      <label htmlFor="" className='ReasonLabel'>{t('configuration.reason')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                          id="reason"
                          value={addSampleData.reason}
                          name="reason"
                          type='text'
                          placeholder={t('configuration.reason')}
                          className="reasonClass"
                          onChange={handleAddRawMaterialChange} 
                          onKeyDown={(e)=>checkSpecialChar(e)}
                      />
                      {errors.reason && <p className="errorText">{errors.reason}</p>}
                  </div>
                  )}

                  {addSampleData.type == "2" && (
                    <div className="form-group my-1 mx-3 px-2">
                      <label htmlFor="" >{t('configuration.sampleType')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <select
                        id="subType"
                        value={addSampleData.subType}
                        name="subType"
                        className="placeholder-style"
                        onChange={handleAddRawMaterialChange} 
                      >
                        <option value="">Select</option>
                        {rawMaterialsDropDown.map((element: any) => (
                          <option value={element.id}>{element.material_type}</option>
                        ))}
                      </select> 
                      {errors.subType && <p className="errorText">{errors.subType}</p>}
                    </div>
                  )}
                </div>

              </div>
                <div className="bottomArea">
                  <button onClick={() => addEquipmentModalClose()} className="modalCancelBtn">{t('buttons.cancel')}</button>
                  <button onClick={() => handleSampleAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
                  {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
                </div>
            </div>
          
        </Modal>

        {/* Alert modal for Add policy */}
        <AlertModal 
          show={addRawMaterialAlert}
          onHide={() => setAddRawMaterialAlert(false)}
          title='Alert'
          message={`${modalName}`}
          onConfirm={handleSubmit}
        />

        {/* Alert modal for sample type status change */}
        <AlertModal
          show={showUserStatusChangeAlert}
          onHide={() => setShowUserStatusChangeAlert(false)}
          title='Alert'
          message={t('configuration.rawMaterialStatusChangeAlert')}
          onConfirm={handleStatusChange}
        /> 

        {/* Alert modal for sub type status change */}
        <AlertModal
          show={showSubTypeChangeAlert}
          onHide={() => setShowSubTypeChangeAlert(false)}
          title='Alert'
          message={t('configuration.rawMaterialStatusChangeAlert')}
          onConfirm={handleSubTypeStatusChange}
        /> 



    </div>
  )
}

export default AddRawMaterials