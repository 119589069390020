import axios from "axios";
import baseUrl from "../../index";
import { handleLogOut } from "../../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );


const getBatchList =  async (page :number, size :number, keyword:any, plateStatus:any, batchStatus?:any, sort?:any , order?:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/batches/list?page=${page}&size=${size}&sort=${sort}&order=${order}&plate_status=${plateStatus}&batch_status=${batchStatus}&batch_number=&keyword=${keyword}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getBatchDetails =  async (page :number, size :number, sort:any , order:any , keyword:any, plateStatus:any, fromdate?:string , todate?:string , batchNumber?:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/batches/list?page=${page}&size=${size}&sort=${sort}&order=${order}&plate_status=&batch_status=${plateStatus}&batch_number=${batchNumber}&keyword=${keyword}&from_date=${fromdate}&to_date=${todate}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

const listPlateDetails =async (page :number, size :number, sort:any , order:any , plateStatus:any, batchId: any, keyword: any) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=${sort}&order=${order}&status=active&plate_status=${plateStatus}&keyword=${keyword}&batch_number=${batchId}&serial_number=&location=`,
        {
          headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
          }
        });
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return error.response?.data
        }
      }
}

const listLotPlateDetails = async (page :number, size :number, sort:any , order:any , plateStatus:any, batchId: any, keyword: any, lot_id :any) => {
  try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=id&order=asc&status=active&keyword=${keyword}&batch_number=&serial_number=&current_location_id=&location=&incubator_id&lot_id=${lot_id}&lot_code`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}

const getTestTubeBatchDetails =  async (page :number, size :number, sort:any , order:any, keyword :any) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/test_tubes/list_test_tube_batch?page=${page}&size=${size}&order=${order}&sort=${sort}&batch_id=${keyword}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getInnerTestTubeDetails =  async (page :number, size :number, sort:any , order:any, batch_no:any, keyword:any) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/test_tubes/list_test_tube_details?page=${page}&size=${size}&order=${order}&sort=${sort}&batch_id=${batch_no}&serial_number=${keyword}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const discardPlates = async (dataToSend: any) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/plates/discard_plate`, dataToSend,
        {
          headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
          }
        });
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return error.response?.data
        }
      }
}

const startAutoClave = async (dataToSend: any) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/plates/start_plate_auto_clave`, dataToSend,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const stopAutoClave = async (dataToSend: any) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/plates/stop_plate_auto_clave`, dataToSend,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getTestTubeBatchFilter =  async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/test_tubes/list_test_tube_batch?&order=desc&sort=id`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const TestTubeDropDown =  async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/test_tubes/list_test_tube_details?order=asc&sort=desc`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const discardBatchView =async (sort:any , order:any , plateStatus:any, batchId: any, keyword: any) => {
  try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?sort=${sort}&order=${order}&status=active&plate_status=${plateStatus}&keyword=${keyword}&batch_number=${batchId}&serial_number=&location=`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}

  const BatchService = {
    getBatchList,
    listPlateDetails,
    discardPlates,
    startAutoClave,
    stopAutoClave,
    getBatchDetails,
    getTestTubeBatchDetails,
    getInnerTestTubeDetails,
    listLotPlateDetails,
    getTestTubeBatchFilter,
    TestTubeDropDown,
    discardBatchView
  };

  export { BatchService };