import React, { useEffect, useState } from 'react'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import { areaService } from '../../../../service/areaServices';
import { userService } from '../../../../service/plateServices';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { visualInspection } from '../../../../service/visualinspection';
import baseUrl from '../../../../service';
import {userService as userRoleServive} from '../../../../service/userService';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../../widgets/table';
import Pagination from '../../../../widgets/pagination';
import { plateActivityColumns } from './partials/columns';
import { reportServices } from '../../../../service/reportServices';


interface ActiveAirSamplingReportProps {
  ishide?: boolean;
}


const PlateActivityreport: React.FC<ActiveAirSamplingReportProps> = ({ ishide }) => {   

  
  type Filters = {
    location: string;
    action: string;
    user: string;
    fromDate: string;
    toDate: string;
  };
  type OptionType = {
    value: string;
    label: string;
  };

 const { t } = useTranslation()

 const [filters, setFilters] = useState({
    location: '',
    action: '',
    user: '',
    fromDate: '',
    toDate: '',
  });
  const [areas, setAreas] = useState<Array<any>>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [plateBatches, setPlateBatches] = useState([]);
  const [lotcode, setLotCode] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [userData , setUserData] = useState<Array<any>>([]); 
  const [actions , setActions] = useState<Array<any>>([]);
  const [showDownloadCSV, setShowDownloadCSV] = useState(true);
  const [isApplyClicked, setIsApplyClicked] = useState(true);
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [authUserID, setAuthUserID] = useState("");

  const handleChange = (
    eventOrValue: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | SingleValue<OptionType>,
    actionOrUndefined?: ActionMeta<OptionType>
  ) => {
    let newFilters: Filters;
  
    if (actionOrUndefined) {
      // For react-select
      const { name } = actionOrUndefined as { name: string };
      newFilters = { ...filters, [name]: (eventOrValue as SingleValue<OptionType>)?.value || '' };
    } else {
      // For native select and input elements
      const { name, value } = (eventOrValue as React.ChangeEvent<HTMLSelectElement | HTMLInputElement>).target;
      newFilters = { ...filters, [name]: value };
    }
  
    setFilters(newFilters);
  
    // Enable button if any filter is changed
    const anyFilterChanged = Object.values(newFilters).some(val => val !== '');
    setIsButtonDisabled(!anyFilterChanged);
    setIsApplyClicked(false);
    setShowDownloadCSV(true)
  };
  
  //batch list for filter dropdown 
  const listPlateBatch = async () => {
      try {
        const response = await userService.listPlateBatch();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.batch_number,
                      label: element.batch_number ,
                  };
                });
                setPlateBatches(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
  }

  const lotCode = async () => {
    try {
      const response = await visualInspection.listFilterLots();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.lot_code,
                    label: element.lot_code ,
                };
              });
              setLotCode(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  const listSerialNumber = async () => {
    try {
      const response = await userService.listSerialNumber();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.serial_number,
                    label: element.serial_number ,
                };
              });
              setPlateSerielNums(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //api call to list plate activity detail in request tab 
  const getPlateActivityReport = async () => {
  try {
    const response = await reportServices.plateActivityReport(page, pageSize, filters.fromDate, filters.toDate, filters.location, filters.user, filters.action);

    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

              const statusNumber = parseInt(element.location_type, 10); 
              return { 
                id : element.id,
                slno: index + 1,
                name : element.user,
                action : element.process_name,
                logDate: element.log_date,
                location : element.location,
                description : element.description,
                reason : element.reason,
              };
              });
              setPlateDetails(rowData);
          }
          } catch (error) {
          console.log(error); 
          }
      } else {
      setPlateDetails([])
      setTotalPages(0)
      setTotalElements(0)
      }
  } catch (error) {
      console.error('Plate response', error);
  }
  };

  useEffect (() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setAuthUserID(storedObject[0].x_userid);
    }

    getPlateActivityReport();
    geAreaTypeList();
    listPlateBatch();
    listSerialNumber();
    lotCode();
    getOperatorList();
    geAreaTypeList();
    ListActions();

    setUseEffectRequired(false)
  },[useEffectRequired])
  
  const getOperatorList = async () => {
    try {
      // const userRole = parseInt(addFilterData.role, 10);  
      const response = await userRoleServive.getUserNameList("");
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              // setTotalPages(response?.totalPages)
              // setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    slno: index + 1, 
                    id: element.id,
                    name: `${element.first_name} ${element.last_name}`,
                };
              });
              setUserData(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

const downloadAuditCSV = async () => {
    const link = `${baseUrl}/summary/plate_activity?sort=id&order=desc&location_id=${filters.location}&user_id=${filters.user}&action=${filters.action}&from_date=${filters.fromDate}&to_date=${filters.toDate}&auth_id=${authUserID}`
    window.location.href = link;
    setShowDownloadCSV(true)
    setIsApplyClicked(true)
};


const geAreaTypeList = async () => {
  try {  
    const response = await areaService.getParentAreaList([11,10,13,15,8,9,12]);
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  slno: index + 1, 
                  policy_id : element.policy_id,
                  id : element.id,
                  title: element.name,
              };
            });
            setAreas(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    }
  } catch (error) {
    console.error('user response', error);
  }
}

const handlePageChange = (newPage : any, pageSize?: number) => {
  setPage(newPage);
  if(pageSize) {
    setPageSize(pageSize)
  }
  setUseEffectRequired(true)
};

const handleApplyClick =() =>{
  setShowDownloadCSV(false);
  setIsApplyClicked(true);
  getPlateActivityReport();
  // listAuditTrialReport();
}

const handleCancel = () => {
  const initialFormData = {
    location: '',
    action: '',
    user: '',
    fromDate: '',
    toDate: '',
  };
  setFilters(initialFormData);
  setUseEffectRequired(true)
  // listAuditTrialReport();
}


const ListActions = async () => {
  try {  
    const response = await visualInspection.ListActions();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  slno: index + 1, 
                  id : element.keyword,
                  title: element.name,
              };
            });
            setActions(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    }
  } catch (error) {
    console.error('user response', error);
  }
}

  return (
    <div>
    {!ishide && (
        <>
      <HeaderSection />
      <PageHeader pageHeader="Activity Log Report" />
      </>
      )}
      <div className="filter-component">
        <div className="filter-row">
          <label className="filter-label">
            User
            <select name="user" value={filters.user} onChange={handleChange} className="filter-select">
              <option value="">Select</option>
              {userData.map((type, index) => (
                        <option key={index} value={type?.id}>
                          {type?.name}
                        </option>
               ))}
            </select>
          </label>
        </div>

        <div className="filter-row">
        <label className="filter-label">
            Location
            <select name="location" value={filters.location} onChange={handleChange} className="filter-select">
              <option value="">Select</option>
              {areas?.map((obj, index) => (
                <option key={index} value={obj?.id}>
                  {obj?.title}
                </option>
              ))}
            </select>
          </label>
      </div>

      <div className="filter-row">
      <label className="filter-label">
            Action
            <select name="action" value={filters.action} onChange={handleChange} className="filter-select">
              <option value="">Select</option>
              {actions?.map((obj, index) => (
                <option key={index} value={obj?.id}>
                  {obj?.title}
                </option>
              ))}
            </select>
          </label>
      </div>

      <div className="filter-row">
        <label className="filter-label">
          From Date
          <input 
            type="date" 
            name="fromDate" 
            value={filters.fromDate} 
            onChange={handleChange} 
            className="filter-input" 
            onKeyDown={(e) => {
              const allowedKeys = [
                "Tab",
                "Enter",
                "Escape",
              ];
              if (!allowedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => e.preventDefault()}
          />
        </label>
      </div>

      <div className="filter-row">
        <label className="filter-label">
          To Date
          <input type="date" name="toDate" value={filters.toDate} onChange={handleChange} className="filter-input" />
        </label>
      </div>

      {/* <div className="filter-row">
        <button className="filter-button" disabled={isButtonDisabled} onClick={downloadAuditCSV}>Download Plate Activity Log Report</button>
      </div> */}
      </div>

      <div className="mx-2 d-flex justify-content-end" >
            <button className="analyticalFilterClose" title="Click here to Reset Filter" onClick={handleCancel}>{t("buttons.cancel")}</button>
            <button 
            className={isApplyClicked ? "analyticalFilterApplyDisabledDownload" : "analyticalFilterApply"}
            disabled={isApplyClicked} 
            onClick={handleApplyClick} 
            title="Click here to Apply Filter" >
                {t("buttons.apply")}
            </button>
            <button 
            className={showDownloadCSV ? "analyticalFilterDisabledDownload" : "analyticalFilterDownload"}
            disabled={showDownloadCSV} 
            onClick={downloadAuditCSV}
            title="Click here to Download CSV" >
            {t("buttons.download_pdf")}
            </button>

        </div>

        <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
          <CustomTable
          data={plateDetails}
          columns={plateActivityColumns}
          isEditable={false}
          totalElements={totalElements}
          isActionVisible={false}
          isViewVisible={false}
          isSeachable={false}
          tableHead={'Activity Log Report'}
          showBatchFilter={false}
          showLotCodeFilter={false}
        />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>

    </div>
  )
}

export default PlateActivityreport
