import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import ParetoChart from "../partials/paretoChart";
import { useEffect, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import DownloadPdf from "../../../../widgets/downloadPdf";
import { useTranslation } from "react-i18next";
import { trendAnalytics } from "../../../../service/trendAnalytics";
import { gradeTypes } from "../../../../service/gradeType";
import { userService } from "../../../../service/userService";


const ExcursionReport = () => {
  const navigate = useNavigate();
  const { bulletId } = useParams<{ bulletId: string }>();
  const [selectedArea, setSelectedArea] = useState<string>("");
  const { t } = useTranslation();
  const [areaDropDown, setareaDropDown] = useState<Array<any>>([])
  const [locationDropDown, setlocationDropDown] = useState<Array<any>>([])
  const [activityList, setActivityList] = useState<Array<any>>([]); 
  const [userData , setUserData] = useState<Array<any>>([]); 

  const [labels , setLabels]  = useState<Array<any>>([]); 
  const [barlabels , setBarLabels]  = useState<Array<any>>([]);
  const [excursion , setExcursion]  = useState<Array<any>>([]);
  const [percentExcr , setPercentExcr]  = useState<Array<any>>([]);
  const [alertLimit , setAlertLimit] = useState<string>("");
  const [actionLimit , setActionLimit] = useState<string>("");
  const [SpecificationLimit  , setSpecificationLimit] = useState<string>("");
  const [isRefreshed, setIsRefreshed] = useState(false);

  const [filterData, setFilterData] = useState({
    area: "",
    location: "",
    activity: "",
    operator: "",
    from_date: "",
    to_date: "",
    graph_type : "",
  })

  useEffect(() => {
    if (bulletId === "day") {
      setSelectedArea("Daily");
    } else if (bulletId === "week") {
      setSelectedArea("Weekly");
    } else if (bulletId === "month") {
      setSelectedArea("Monthly");
    } else if (bulletId === "year") {
      setSelectedArea("Yearly");
    } else if (bulletId === "quarter") {
        setSelectedArea("Quarterly");
    } else {
      setSelectedArea("");
    }

    listAreaType();
    getActivityList();
    getOperatorList();
    setFilterData((prevState: any) => ({
      ...prevState,
      graph_type: bulletId,
    }));
  }, [bulletId]);

  const handleInputChange = (event : any) => {
    const { name, value } = event.target;

    if(name === "from_date" && filterData.to_date < value) {
      setFilterData(prevState => ({
        ...prevState,
        ["to_date"]: ""
      }));
    }

    setFilterData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if(name == 'area'){
      if (value !== '0') {
        listLocationType(value);
      }else {
        setlocationDropDown([]);
      }
    }
  };

const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate =
        `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-` +
        `${date.getDate().toString().padStart(2, '0')} ` +
        `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

    return formattedDate;
};

const listAreaType = async () => {
  try {
    const response = await trendAnalytics.listAreaType();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  value: element.id,
                  label: element.name,
                  type: element.type,
              };
            });
            setareaDropDown(rowData);

          }
        } catch (error) {
          console.log(error); 
        }
    }
  } catch (error) {
    console.error('Plate response', error);
  }
}

const listLocationType = async (parent_area: string) => {
  try {
    const response = await trendAnalytics.listLocationType(parent_area);
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  value: element.id,
                  label: element.name,
                  type: element.type,
              };
            });
            setlocationDropDown(rowData);

          }
        } catch (error) {
          console.log(error); 
        }
    }
  } catch (error) {
    console.error('Plate response', error);
  }
}

const getActivityList = async () => {
  try {
    const response = await gradeTypes.getActivityList();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                label: element.activity_name,
                value : element.id,
              };
            });
            setActivityList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    }
  } catch (error) {
    console.error(error);
  }
}


const getOperatorList = async () => {
  try {
    const response = await userService.getUserNameList("");
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  slno: index + 1, 
                  id: element.id,
                  name: `${element.first_name} ${element.last_name}`,
              };
            });
            setUserData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    } else {
      setUserData([]);
    }
  } catch (error) {
    console.error(error);
  }
};

const handleSubmit = async ()=> {
  getExcursionAnalyticsData();
}

const onCancelClick = async () => {
  setFilterData({
    area: "",
    location: "",
    activity: "",
    operator: "",
    from_date: "",
    to_date: "",
    graph_type: ""  
  })
  setFilterData((prevState: any) => ({
    ...prevState,
    graph_type: bulletId,
  }));
  // setareaDropDown([])
  setlocationDropDown([])
  setLabels([]);
  setBarLabels([]);
  setExcursion([]);
  setPercentExcr([]);
  setAlertLimit("")
  setActionLimit("")
  setSpecificationLimit("")
}


const getExcursionAnalyticsData = async () => {
  try {
    const response = await trendAnalytics.getExcursionAnalyticsData(filterData);
    if (response.status && response.statusCode === 200) {
       setAlertLimit(response.data?.alert_limit)
       setActionLimit(response.data?.action_limit)
       setSpecificationLimit(response.data?.specification_limit)
      const plateDetails = response.data?.plate_details;    
      if (Array.isArray(plateDetails) && plateDetails.length > 0) {
        const dates = plateDetails.map((detail: any) => detail.date);
        const plateCount = plateDetails.map((detail: any) => detail.plate_count);
        const excursions = plateDetails.map((detail: any) => detail.excursions);
        const excursionPercentage = plateDetails.map((detail: any) => detail.excursion_percentage);

        setLabels(dates);
        setBarLabels(plateCount);
        setExcursion(excursions);
        setPercentExcr(excursionPercentage);


      } else {
        console.log("No plate details found.");
        setLabels([]);
        setBarLabels([]);
        setExcursion([]);
        setPercentExcr([]);
      }
    } else {
      console.log(`Unexpected response: ${response.statusCode}`);
      setLabels([]);
      setBarLabels([]);
      setExcursion([]);
      setPercentExcr([]);
    }
  } catch (error) {
    console.error("Failed to fetch excursion analytics data:", error);
  }
};




  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('dashboard.excursionsReport')} />
      <nav aria-label="breadcrumb" className="breadcrumb-container row">
        <div className="col-10 d-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item dashboard-hover">
              <Link to="/analytics-dashboard">{t('dashboard.analyticalDashboard')}</Link>
            </li>
            <li className="breadcrumb-item dashboard-hover" aria-current="page">
              <Link to="/inner-landing/Excursion">{t('dashboard.excursionsReport')}</Link>
            </li>
            <li className="breadcrumb-item active selectedArea" aria-current="page">
              {selectedArea}
            </li>
          </ol>
        </div>
        <div className="col-2 d-flex">
          <DownloadPdf divId="dashWrap" reportHead={t('dashboard.excursionsReport')} />
        </div>
      </nav>

      <div >

      <div className="d-flex justify-content-around mt-3">

        {/* Area filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_area')}<span style={{ color: 'red' }}>*</span>:</label>
          <select className='areaTypeSelect' name="area" value={filterData.area} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            {areaDropDown?.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </select>
        </div>

        {/* location filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_location')}<span style={{ color: 'red' }}>*</span>:</label>
          <select className='areaTypeSelect' name="location" value={filterData.location} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            {locationDropDown?.map((type) => (
              <option key={type.value} value={type.value}>
              {type.label}
            </option>
            ))}
          </select>
        </div>

        {/* Activity filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_activity')}<span style={{ color: 'red' }}>*</span>:</label>
          <select className='areaTypeSelect' name="activity" value={filterData.activity} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            {activityList?.map((type) => (
              <option key={type.value} value={type.value}>
              {type.label}
            </option>
            ))}
          </select>
        </div>

        {/* Operator filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_operator')}:</label>
          <select className='areaTypeSelect' name="operator" value={filterData.operator} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            {userData?.map((type) => (
              <option key={type.id} value={type.id}>
              {type.name}
            </option>
            ))}
          </select>
        </div>

        {/* Microbiologist filter */}
        {/* <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_microbiologist')}:</label>
          <select className='areaTypeSelect' name="microbiologist" value={filterData.microbiologist} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Microbiologist 1" >Microbiologist 1</option>
            <option value="Microbiologist 2" >Microbiologist 2</option>
            <option value="Microbiologist 3" >Microbiologist 3</option>
            <option value="Microbiologist 4" >Microbiologist 4</option> */}
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          {/* </select>
        </div> */}



        {/* Shift filter */}
        {/* <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_shift')}:</label>
          <select className='areaTypeSelect' name="shift" value={filterData.shift} onChange={(e) =>  handleInputChange(e)} >
            {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
          ))} 
          </select>
        </div> */}

        {/* Organism filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.from_date')}<span style={{ color: 'red' }}>*</span>:</label>
          <input
              id="selectedDate"
              className="form-control"
              value={formatDateTime(filterData.from_date)}
              name="from_date"
              type="datetime-local"
              onChange={(e) =>  handleInputChange(e)}
              onKeyDown={(e) => {
                const allowedKeys = [
                  "Tab",
                  "Enter",
                  "Escape",
                ];
                if (!allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => e.preventDefault()}
            />
        </div>
      </div>

      <div className="d-flex justify-content-start mx-5 mt-3">
        {/* Sub filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column", "marginRight":"60%"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.to_date')}<span style={{ color: 'red' }}>*</span>:</label>
          <input
              id="selectedDate"
              className="form-control"
              value={formatDateTime(filterData.to_date)}
              name="to_date"
              type="datetime-local"
              min={formatDateTime(filterData.from_date)}
              onChange={(e) =>  handleInputChange(e)}
              onKeyDown={(e) => {
                const allowedKeys = [
                  "Tab",
                  "Enter",
                  "Escape",
                ];
                if (!allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => e.preventDefault()}
            />
        </div>

        <div className="my-3">
            <button className="analyticalFilterClose" title="Click here to Reset Filter" onClick={onCancelClick}>{t('buttons.cancel')}</button>
            <button className="analyticalFilterApply" title="Click here to Apply Filter" onClick={handleSubmit}>{t('buttons.apply')}</button>
        </div>

      </div>

      <div className="pareto-head-chart" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <p className="pareto-head" style={{ marginRight: "200px" }}>
      {t('dashboard.excursionsReport')} | By {selectedArea}
      {filterData.activity && (
      <span>
        | By {
          activityList.find(type => type.value === filterData.activity)?.label || filterData.activity
        }
      </span>
    )}
      </p>
       </div>

       {/* Chart Section */}
       <div id="dashWrap" style={{ width: "900px", height: "400px", marginLeft: "80px", marginBottom:"25px" }}>
        {/* Pareto Chart Component */}
        <ParetoChart
          barChartData={barlabels}
          barChartLabels={labels}
          lineChartData1={excursion}
          lineChartData2={percentExcr}
          alertLimit={alertLimit}
          actionLimit={actionLimit}
          SpecificationLimit={SpecificationLimit}
        />
      </div>

      </div>
    </div>
  );
};

export default ExcursionReport;
