import React, {useState, useEffect} from 'react'
import { FaFilePdf } from "react-icons/fa6";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logoImg from "../../assets/images/empats_logo.jpeg";


interface DownloadPdfProps {
    divId: string;
    reportHead: string;
}

const DownloadPdf: React.FC<DownloadPdfProps> = ({divId, reportHead}) => {


  const [userName, setUserName] = useState<number>(0);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());



    //handle user details 
    useEffect(() => {
      var storedData = localStorage.getItem('userDetails');

      if(storedData) {
        var storedObject = JSON.parse(storedData);
        setUserName(storedObject[0].first_name + storedObject[0].last_name)
      }
    }, [])

    //handle time details 
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 1000); // Update every second

      return () => clearInterval(intervalId); // Cleanup on unmount
    }, []); // Run once on component mount

    const printDocument = () => {
        const input: any = document.getElementById(divId);
    
        if (input) {
          html2canvas(input).then((canvas: HTMLCanvasElement) => {
            const imgData: string = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imgData, "JPEG", 0, 20, imgWidth, imgHeight);

            // const textTopContent = "Pharma Co.";
            // const textPdfHead = reportHead;
            const textBottomContent = `Report Generated By: ${userName}`;
            const textBottomContent2 = `Report Generated At: ${currentDateTime}`;

            // const logoImg = "https://t3.ftcdn.net/jpg/01/06/33/74/360_F_106337464_PqrDoCy2HhtDr6ezOGq13hvpVFcMGpQV.jpg"

            
            pdf.setFontSize(12); // Set font size to 12
            pdf.setTextColor(67, 67, 69); // Set text color to red

            // pdf.text(textTopContent, 25, 12);
            pdf.text(reportHead, 102, 12);
            pdf.addImage(logoImg, "JPEG", 5, 2, 40, 12);
            pdf.setFontSize(8);
            pdf.text(textBottomContent2, 5, imgHeight + 23)
            pdf.text(textBottomContent, 5, imgHeight + 18)


            // Add vertical line
            const lineX = 100; // Adjust X position as needed
            const lineYStart = 1; // Adjust start Y position as needed
            const lineYEnd = 14; // Adjust end Y position as needed
            pdf.setLineWidth(0.1); // Set line width
            pdf.setDrawColor(153, 145, 144); // Set line color to black
            const dashLength = 0.5; // Set length of each dash
            const gapLength = 0.2; // Set length of each gap
            pdf.setLineDashPattern([dashLength, gapLength], 1)
            pdf.line(lineX, lineYStart, lineX, lineYEnd); // Draw line


            const lineY = 15; // Adjust Y position as needed
            pdf.setLineWidth(0.1); // Set line width
            pdf.setDrawColor(153, 145, 144); // Set line color to black
            pdf.line(5, lineY, imgWidth - 5, lineY); // Draw line
            pdf.save("download.pdf");
          });
        }
      };


  return (
    <div style={{"visibility" : "hidden"}}>
        <FaFilePdf
              onClick={printDocument}
              fontSize={30}
              fill="#780000"
              title="Click here to download pdf"
              className='mt-2'
          />
    </div>
  )
}

export default DownloadPdf