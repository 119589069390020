import React, {useState, useEffect} from 'react'
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import { Column } from 'react-table';
import { Modal } from 'react-bootstrap';
import { TfiClose } from 'react-icons/tfi';
import { BatchService } from '../../../../service/plateServices/discard';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { userService } from '../../../../service/userService';


interface DiscardsProps {
    columns: Column[];
    plateBatches: string[];

}

const Discards: React.FC<DiscardsProps> = ({
    columns,
    plateBatches
}) => {

  const { t } = useTranslation();
  const [batchList, setBatchList] = useState([])
  const [useEffectRequired, setUseEffectRequired] = useState(false)
  const [keyword, setKeyword] = useState("")
  const [userId, setUserId] = useState<number>(0);

  // pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [totalPages, setTotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)

  // pagination
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [permission, setPermission] = useState<any>([]);




//get user details
  useEffect(() => {

    var storedData = localStorage.getItem('userDetails');
  
    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
      getPermissionByRole(storedObject[0].role_master_id);
    }

    getBatchList(18, keyword)
    setUseEffectRequired(false)
  }, [useEffectRequired])


  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }
  
  //api call to get history discard list 
  const getBatchList = async (plateStatus: any, keyword:string) => {
    try {
      const response = await BatchService.getBatchList(page, pageSize,keyword, "", "6",sort,order);
      if(response.status && response.statusCode === 200){
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
         
             return { 
                 slno: index + 1, 
                 batch_no: element.batch_no,
                 batchGrn: element.batch_grn,
                 quantity: element.plate_count,
                 plate_type: element.plate_size_type_name,
                 id: element.id
             };
           });

           setBatchList(rowData);
          }
      } else {
        setBatchList([])
      }
    } catch (error) {
      return error;
    }
  }

  //handle filter 
  const handleFilter = (key: string, value: any) => {
    if (key === "search") {
      setKeyword(value)
    }
    setUseEffectRequired(true)
  }

  //on main table filter change 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  //handle discard table sort 
  const handleSort = (key: string, value: boolean) => {
    setSort(key)
    if (value) {
      setOrder("asc")  
      getBatchList(key,"asc")    
    } else {
      setOrder("desc")
      getBatchList(key,"desc")
    }
    setUseEffectRequired(true)
   
  }

  return (
    <div className="me-3" style={{ marginTop: "10px" }}>
          <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
            <CustomTable
              tableHead={t('plateInventory.discard_history')}
              data={batchList}
              columns={columns}
              isEditable={false}
              totalElements={totalElements}
              isActionVisible={false}
              isViewVisible={false}
              discardButton={false}
              discardButtonText={t('plateInventory.discard')}
              plateBatch={plateBatches}
              isSeachable={true}
              onFilterClick={handleFilter}
              onSortClick={handleSort}
            />
          </div>
          
          <div>
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
  )
}

export default Discards;