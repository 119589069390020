export const columns: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'slno',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_number',
      },
      {
        Header: 'Lot Code',
        accessor: 'lot_code',
      },
      {
        Header: 'Total Plates',
        accessor: 'total_plates',
      },
      {
        Header: 'Passed Plates',
        accessor: 'passed_plates',
      },
      {
        Header: 'Failed Plates',
        accessor: 'failed_plates',
      },
      {
        Header: 'Operator Name',
        accessor: 'operator_name',
      },
      // {
      //   Header: 'Operator ID',
      //   accessor: 'operator_id',
      // },
      {
        Header: 'Operator Code',
        accessor: 'operator_code',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Inspection Reason',
        accessor: 'reason',
      },
    
  ];


export const columnsModalTable: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'slno',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_number',
      },
      {
        Header: 'Lot Code',
        accessor: 'lot_code',
      },
      {
        Header: 'Plate Count',
        accessor: 'plate_count',
      },
      {
        Header: 'Pass Count',
        accessor: 'lot_pass_count',
      }
]

  
interface TableColumn {
    Header: string;
    accessor: string;
}