import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

interface ParetoChartProps {
  barChartData: string[];
  barChartLabels: string[];
  lineChartData1: string[];
  lineChartData2: string[];
  SpecificationLimit: string | number;
  alertLimit: string | number;
  actionLimit: string | number;
}

interface ChartInstance {
  destroy: () => void;
}

Chart.registry.add(...registerables); // Register any necessary plugins
Chart.register(annotationPlugin);

const ParetoChart: React.FC<ParetoChartProps> = ({
  barChartData,
  barChartLabels,
  lineChartData1,
  lineChartData2,
  SpecificationLimit,
  alertLimit,
  actionLimit
}) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<ChartInstance | null>(null);

  // useEffect(() => {
    
  //   if (chartRef.current) {
  //     const ctx = chartRef.current.getContext('2d');
  //     if (ctx) {
  //       // Destroy any existing Chart instance on the canvas
  //       if (chartInstanceRef.current) {
  //         chartInstanceRef.current.destroy();
  //       }

  //       // Create the new chart with bar and two line datasets
  //       const chartConfig: ChartConfiguration<'bar' | 'line', string[], string> = {
  //         type: 'bar',
  //         data: {
  //           labels: barChartLabels,
  //           datasets: [
  //             {
  //               label: 'Samples',
  //               data: barChartData,
  //               backgroundColor: '#6c757d',
  //               borderColor: '#6c757d',
  //               borderWidth: 1,
  //               type: 'bar',
  //               barPercentage: 0.8, 
  //               categoryPercentage: 0.7,
  //               order: 2,
  //             },
  //             {
  //               label: 'Excursions',
  //               data: lineChartData1,
  //               fill: false,
  //               borderColor: '#52b788',
  //               backgroundColor: '#52b788',
  //               borderWidth: 5,
  //               yAxisID: 'line-y-axis',
  //               type: 'line',
  //               order: 1,
  //             },
  //             {
  //               label: '% of Excursions',
  //               data: lineChartData2,
  //               fill: false,
  //               borderColor: '#ff6b6b',
  //               backgroundColor: '#ff6b6b',
  //               borderWidth: 5,
  //               yAxisID: 'line-y-axis2',
  //               type: 'line', 
  //               order: 1,
  //             },
  //           ],
  //         },
  //         options: {
  //           plugins: {
  //             // title: {
  //             //   display: true,
  //             //   text: 'Chart Title',
  //             // },
  //             legend: {
  //               position: 'bottom'
  //             },
  //             annotation: {
  //               annotations: {
  //                 line1: {
  //                   type: 'line',
  //                   borderColor: 'blue',
  //                   borderWidth: 2,
  //                   borderDash: [6, 6],
  //                   scaleID: 'line-y-axis',
  //                   value: alertLimit, 
  //                   label: {
  //                     display: true,
  //                     content: `Alert Limit: ${alertLimit}`,
  //                     position: 'end',
  //                     backgroundColor: 'black',
  //                     color: 'white',
  //                     yAdjust: 20,
  //                     xAdjust: -20
  //                   },
  //                 },
  //                 line2: {
  //                   type: 'line',
  //                   borderColor: 'yellow',
  //                   borderWidth: 2,
  //                   borderDash: [6, 6],
  //                   scaleID: 'line-y-axis',
  //                   value: actionLimit, 
  //                   label: {
  //                     display: true,
  //                     content: `Action Limit: ${actionLimit}`,
  //                     position: 'end',
  //                     backgroundColor: 'black',
  //                     color: 'white',
  //                     yAdjust: -20,
  //                     xAdjust: -15
  //                   },
  //                 },
  //                 line3: {
  //                   type: 'line',
  //                   borderColor: 'red',
  //                   borderWidth: 2,
  //                   borderDash: [6, 6],
  //                   scaleID: 'line-y-axis',
  //                   value: SpecificationLimit, 
  //                   label: {
  //                     display: true,
  //                     content: `Specification Limit: ${SpecificationLimit}`,
  //                     position: 'end',
  //                     backgroundColor: 'black',
  //                     color: 'white',
  //                     yAdjust: 20,
  //                     xAdjust: -10,
  //                   },
  //                 },
  //               }
  //             }
  //           },
  //           scales: {
  //             x: {
  //               grid: {
  //                 display: false, 
  //               },
  //             },
  //             y: {
  //               beginAtZero: true,
  //               display: false,
  //               grid: {
  //                 display: false, 
  //               },
  //               ticks: {
  //                 stepSize: 30, // You can also try adjusting this value
  //                 autoSkip: true,
  //                 maxTicksLimit: 10, // Adjust this value based on your preference
  //               },
  //             },
  //             'line-y-axis': {
  //               type: 'linear',
  //               position: 'left',
  //               beginAtZero: true,
  //               title: {
  //                 display: true,
  //                 text: 'Excursions'
  //               },
  //             },
  //             'line-y-axis2': {
  //               type: 'linear',
  //               position: 'right',
  //               beginAtZero: true,
  //               title: {
  //                 display: true,
  //                 text: '% of Excursions'
  //               },
  //             },
  //           },
  //         },
  //       };

  //       // Create the chart instance
  //       chartInstanceRef.current = new Chart(ctx, chartConfig) as ChartInstance;
  //     }
  //   }
  // }, [barChartData, barChartLabels, lineChartData1, lineChartData2]);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        // Destroy any existing Chart instance on the canvas
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }
  
        // Define annotations with proper typing
        const annotations: Record<string, any> = {};
        
        if (alertLimit) {
          annotations.line1 = {
            type: 'line',
            borderColor: 'blue',
            borderWidth: 2,
            borderDash: [6, 6],
            scaleID: 'line-y-axis',
            value: alertLimit,
            label: {
              display: true,
              content: `Alert Limit: ${alertLimit}`,
              position: 'end',
              backgroundColor: 'black',
              color: 'white',
              yAdjust: 20,
              xAdjust: -20,
            },
          };
        }
  
        if (actionLimit) {
          annotations.line2 = {
            type: 'line',
            borderColor: 'yellow',
            borderWidth: 2,
            borderDash: [6, 6],
            scaleID: 'line-y-axis',
            value: actionLimit,
            label: {
              display: true,
              content: `Action Limit: ${actionLimit}`,
              position: 'end',
              backgroundColor: 'black',
              color: 'white',
              yAdjust: -20,
              xAdjust: -15,
            },
          };
        }
  
        if (SpecificationLimit) {
          annotations.line3 = {
            type: 'line',
            borderColor: 'red',
            borderWidth: 2,
            borderDash: [6, 6],
            scaleID: 'line-y-axis',
            value: SpecificationLimit,
            label: {
              display: true,
              content: `Specification Limit: ${SpecificationLimit}`,
              position: 'end',
              backgroundColor: 'black',
              color: 'white',
              yAdjust: 20,
              xAdjust: -10,
            },
          };
        }
  
        // Create the new chart with bar and two line datasets
        const chartConfig: ChartConfiguration<'bar' | 'line', string[], string> = {
          type: 'bar',
          data: {
            labels: barChartLabels,
            datasets: [
              {
                label: 'Samples',
                data: barChartData,
                backgroundColor: '#6c757d',
                borderColor: '#6c757d',
                borderWidth: 1,
                type: 'bar',
                barPercentage: 0.8,
                categoryPercentage: 0.7,
                order: 2,
              },
              {
                label: 'Excursions',
                data: lineChartData1,
                fill: false,
                borderColor: '#52b788',
                backgroundColor: '#52b788',
                borderWidth: 5,
                yAxisID: 'line-y-axis',
                type: 'line',
                order: 1,
              },
              {
                label: '% of Excursions',
                data: lineChartData2,
                fill: false,
                borderColor: '#ff6b6b',
                backgroundColor: '#ff6b6b',
                borderWidth: 5,
                yAxisID: 'line-y-axis2',
                type: 'line',
                order: 1,
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                position: 'bottom',
              },
              annotation: {
                annotations,
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                beginAtZero: true,
                display: false,
                grid: {
                  display: false,
                },
                ticks: {
                  stepSize: 30,
                  autoSkip: true,
                  maxTicksLimit: 10,
                },
              },
              'line-y-axis': {
                type: 'linear',
                position: 'left',
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Excursions',
                },
              },
              'line-y-axis2': {
                type: 'linear',
                position: 'right',
                beginAtZero: true,
                title: {
                  display: true,
                  text: '% of Excursions',
                },
              },
            },
          },
        };
  
        chartInstanceRef.current = new Chart(ctx, chartConfig);
      }
    }
  }, [barChartLabels, barChartData, lineChartData1, lineChartData2, alertLimit, actionLimit, SpecificationLimit]);
  
  
  return <canvas ref={chartRef} width="900" height="400" />;
};

export default ParetoChart;