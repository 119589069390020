import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { authenticationService } from "../service/authentication";
import CustomToast from "../widgets/toaster";
import { MdErrorOutline } from "react-icons/md";

// import './signin.css'

const UpdatePassword = (userDetails: any) => {
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [authUserID, setAuthUserID] = useState("");
  const [userId, setUserId] = useState("");
  const [resetFlg, setResetFlg] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const username: any = queryParams.get("username");
  // const password: any = queryParams.get("password");

  const passwordsMatch = newPassword === confirmPassword;

  useEffect(() => {
    var storedData = localStorage.getItem("loginDetails");
    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setAuthToken(storedObject[0].x_auth_token);
      setAuthUserID(storedObject[0].x_userid);
      setUserId(storedObject[0].id);
      setResetFlg(storedObject[0].reset_flg);
    }
  }, []);

  const handleNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setnewPassword(event.target.value);
  };

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleconfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setconfirmPassword(event.target.value);
  };

  const canLogin =
    password !== "" || (confirmPassword !== "" && password == confirmPassword);

  // Validate password
  const validatePassword = (password: any) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%^&*])(?=.{8,})/;
    return regex.test(password);
  };

  const handleLogOut = async (userId: any, authToken: any, authUserID: any) => {
    try {
      const response = await authenticationService.LogoutApi(
        userId,
        authToken,
        authUserID
      );
      if (response.status && response.statusCode === 200) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("loginDetails");
      }
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const handleUpdateBtn = async () => {
    if (
      passwordsMatch &&
      validatePassword(newPassword) &&
      validatePassword(confirmPassword)
    ) {
      try {
        const response = await authenticationService.changePassword(
          username,
          password,
          newPassword,
          confirmPassword
        );
        if (response.status && response.statusCode === 200) {
          setToastType("success");
          setToastMessage(response.message);
          if (resetFlg === 1) {
            handleLogOut(authUserID, authToken, authUserID);
          } else {
            handleLogOut(userId, authToken, authUserID);
          }
          navigate("/");
        } else {
          setToastType("error");
          setToastMessage(response.message);
        }
        setShowToast(true);
      } catch (error) {
        console.error("Error signing in:", error);
      }
    } else {
      console.log("invalid pass");
    }
  };

  return (
    <div className="signinBg">
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <div className="loginhexagon" style={{ float: "left" }}>
        <>
          <div className="main-wrapper2">
            <div className="badge environmental">
              <div className="ribbon">Environment</div>
            </div>

            <div className="badge components">
              <div className="ribbon">components</div>
            </div>
          </div>

          <div className="main-wrapper">
            <div className="badge clean_utilities">
              <div className="ribbon">Clean Utilities</div>
            </div>

            <div className="badge heading">
              <div className="login-text">
                Monitoring & <br /> Management
                <br />
                through
                <br /> MPATS&reg;
                <br />
              </div>
            </div>

            <div className="badge containerClosure">
              <div className="ribbon">container / Closure</div>
            </div>
          </div>

          <div className="main-wrapper2">
            <div className="badge personnel">
              <div className="ribbon">Personnel</div>
            </div>

            <div className="badge surfaces">
              <div className="ribbon">Surfaces</div>
            </div>
          </div>
        </>
      </div>
      <div className="UpdatePasswordInputArea" style={{ float: "right" }}>
        <div className="SignupText">
          <p>Login to</p>
        </div>
        <div className="signinBrandArea">
          <p className="brandHead">MPATS</p>
          <p className="brandDisc">
            Microbial Media Plates Tracking & Management System
          </p>
        </div>
        <div className="formGroup" style={{ marginLeft: "30px" }}>
          <label htmlFor="password" className="login-label">
            Current Password
          </label>
          <input
            type="password"
            placeholder="Current Password"
            className="form-control custom-input"
            id="password"
            value={password}
            onChange={handlePassword}
            style={{ width: "20rem", height: "3rem" }}
          />
          {/* <div style={{ color: 'white', fontSize: '12px' }}>
            {password && !validatePassword(password) && (
              'Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, and a symbol (!,@,#,%,^,&,*).'
            )}
          </div> */}
        </div>
        <div className="formGroup" style={{ marginLeft: "30px" }}>
          <label htmlFor="new-password" className="login-label">
            New Password
            {/* {newPassword && !validatePassword(newPassword) && (
            <MdErrorOutline className="mx-2" title="Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, and a symbol (!,@,#,%,^,&,*)." fontSize={18} fill="red"/>
          )} */}
          </label>
          <input
            type="password"
            placeholder="New Password"
            className="form-control custom-input"
            id="new-password"
            value={newPassword}
            onChange={handleNewPassword}
            style={{ width: "20rem", height: "3rem" }}
          />
          {/* <div style={{ color: 'yellow', fontSize: '12px', fontWeight:"600"}}>
            {newPassword && !validatePassword(newPassword) && (
              '* Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, and a symbol (!,@,#,%,^,&,*).'
            )}
          </div> */}
          <div
            style={{ color: "yellow", fontSize: "12px", fontWeight: "600" }}
            title=" Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, and a symbol (!,@,#,%,^,&,*)."
          >
            {newPassword &&
              !validatePassword(newPassword) &&
              "*Password criteria doesn`t meet"}
          </div>
        </div>
        <div className="formGroup" style={{ marginLeft: "30px" }}>
          <label htmlFor="password" className="login-label">
            Confirm Password
          </label>
          <input
            type="password"
            placeholder="Confirm Password"
            className="form-control custom-input"
            id="password"
            value={confirmPassword}
            onChange={handleconfirmPassword}
            style={{ width: "20rem", height: "3rem" }}
          />
          {!passwordsMatch && (
            <p
              style={{ color: "yellow", fontSize: "12px", fontWeight: "600" }}
              className="my-2"
            >
              * Password does not match
            </p>
          )}
          {/* <div style={{ color: 'yellow', fontSize: '12px', fontWeight:"600" }}>
            {confirmPassword && !validatePassword(confirmPassword) && (
              '* Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, and a symbol (!,@,#,%,^,&,*).'
            )}
          </div> */}
          <div
            style={{ color: "yellow", fontSize: "12px", fontWeight: "600" }}
            title=" Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, and a symbol (!,@,#,%,^,&,*)."
          >
            {confirmPassword &&
              !validatePassword(confirmPassword) &&
              "*Password criteria doesn`t meet"}
          </div>
        </div>
        {canLogin ? (
          <button
            onClick={() => handleUpdateBtn()}
            type="button"
            className="signinBtn2"
          >
            Update
          </button>
        ) : (
          <button type="button" className="signinBtn1">
            Update
          </button>
        )}
        <p className="text-center mt-2" style={{ color: "white" }}>
          click here to{" "}
          <Link to="/" style={{ color: "black" }}>
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default UpdatePassword;
