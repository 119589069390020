
export const ScheduleSummary: TableColumn[] = [
    {
        Header: "Production Schedule Name",
        accessor: "schedule_name",
      },
      {
        Header: "Area Name",
        accessor: "area_name",
      },
      {
        Header: "Location Name",
        accessor: "location_name",
      },
      {
        Header: "Process Name",
        accessor: "activity_type",
      },
      // {
      //   Header: "Duration (Mins)",
      //   accessor: "duration",
      // },
      {
        Header: "Scheduled start Date and Time",
        accessor: "start_date_time",
      },
      {
        Header: "Scheduled end Date and Time",
        accessor: "end_date_time",
      },
      {
        Header: "Actual start Date and Time",
        accessor: "actual_start_time",
      },
      {
        Header: "Expected end Date and Time",
        accessor: "actual_end_time",
      }
]



export const DiscrepancySummary: TableColumn[] = [
      {
        Header: "Area",
        accessor: "Area",
      },
      {
        Header: "Location",
        accessor: "Location",
      },
      {
        Header: "Activity Type",
        accessor: "Activity Type",
      },
      {
        Header: "Operator",
        accessor: "Operator",
      },
      {
        Header: "Microbiologist",
        accessor: "Microbiologist",
      },
      {
        Header: "Date & Time",
        accessor: "Date & Time",
      },
      {
        Header: "Discrepancy Type",
        accessor: "Discrepancy Type",
      },
      {
        Header: "Discrepancy Reason",
        accessor: "Discrepancy Reason",
      },
      {
        Header: "Corrective Action",
        accessor: "Corrective Action",
      }
]


export const ExceptionSummary: TableColumn[] = [
      {
        Header: "Batch Code",
        accessor: "batch_code",
      },
      {
        Header: "Serial Number",
        accessor: "serial_number",
      },
      {
        Header: "Exception Reason",
        accessor: "reason",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Area Name",
        accessor: "area_name",
      },
      {
        Header: "Location Name",
        accessor: "location_name",
      },
      {
        Header: "Operator Name",
        accessor: "operator_name",
      },
      {
        Header: "Operator ID",
        accessor: "operator_id",
      },
      {
        Header: "Status",
        accessor: "status",
      },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }
  