export const columns: TableColumn[] = [
    {
        Header: "Sl no",
        accessor: "slno",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Date",
        accessor: "date",
      },
]

export const HolidayNamecolumns: TableColumn[] = [
  {
      Header: "Sl no",
      accessor: "slno",
    },
    {
      Header: "Name",
      accessor: "name",
    },
]

export const weakDayColumns: TableColumn[] = [
  {
      Header: "Sl no",
      accessor: "slno",
    },
    {
      Header: "WeekDays Name",
      accessor: "weekDaysName",
    },
    {
      Header: "From Date",
      accessor: "fromDate",
    },
    {
      Header: "To Date",
      accessor: "toDate",
    },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }