import React, { useState ,useEffect } from "react";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import {userService as userRoleServive} from '../../../service/userService';
import { userService as plateRelatedService } from '../../../service/plateServices';
import Plates from "./partials/plates";
import { columns, discardColumns, discardHistory } from './partials/columns';
import Discards from "./partials/pendingDiscard";
import { Modal } from 'react-bootstrap';
import { TfiClose } from "react-icons/tfi";
import AlertModal from "../../../widgets/alertModal";
import CustomToast from "../../../widgets/toaster";
import { useTranslation } from 'react-i18next';
import { BatchService } from "../../../service/plateServices/discard";


const Discard = () => {

  const { t } = useTranslation();

  const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
  const [tab, setTab] = useState("1");
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false)
  
  // pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [totalPages, setTotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)

  const [plateStatus, setPlateStatus] = useState<any | null>("");
  const [plateBatches, setPlateBatches] = useState([])
  const [keyWord, setkeyWord] = useState("")
  const [fromdate, setFromDate] = useState(currentDate)
  const [toDate, setToDate] = useState(currentDate)
  const [userId, setUserId] = useState<number>(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [permission, setPermission] = useState<any>([]);



  useEffect(() => {
    // getPlateList();
    getBatchList(sort , order);
    setUseEffectRequired(false)
    listPlateBatch()
}, [useEffectRequired])


//api call for table plate listing 
const getBatchList = async (sort:any , order:any) => {
  try {
    // const response = await userService.getPlateList(page, pageSize, sort, order, plateStatus, keyWord);
    const response = await BatchService.getBatchDetails(page, pageSize, sort,order , keyWord, plateStatus, fromdate, toDate)
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {

              const StatusConstants = {
                QUARANTINE: "0",
                QA_APPROVED: "1",
                READY_TO_USE: "2",
                ACTIVITY_INPROGRESS: "3",
                QA_REJECTED : "4",
                UNDER_TEST:"5",
                BATCH_DISCARD:"6"
              };

              let status;

              // Mapping numeric status to string representation
              switch (element.batch_status) {
                  case StatusConstants.UNDER_TEST:
                      status = "Waiting for QA Approval";
                      break;
                  case StatusConstants.QA_APPROVED:
                      status = "QA APPROVED";
                      break;
                  case StatusConstants.READY_TO_USE:
                      status = "READY TO USE";
                      break;
                  case StatusConstants.ACTIVITY_INPROGRESS:
                      status = "ACTIVITY INPROGRESS";
                      break;
                  case StatusConstants.QA_REJECTED:
                      status = "QA REJECTED";
                      break;
                  case StatusConstants.QUARANTINE:
                      status = "QUARANTINE";
                      break;
                  case StatusConstants.BATCH_DISCARD:
                      status = "DISCARD";
                      break;
                  default:
                      status = "UNKNOWN STATUS";
              }

              return { 
                  id : element.id,
                  slno: index + 1, 
                  batch_number: element.batch_no,
                  manufacturer_details: element.manufacturer_details,
                  manufacturing_date: element.created_at,
                  plate_type: element.plate_size_type_name,
                  quantity: element.plate_count,
                  lot_id: element.lot_id,
                  status: status,
                  batch_grn:element.batch_grn,
                  remaining_for_lot_assign:element.remaining_for_lot_assign
              };
            });
            setPlateDetails(rowData);
          }
        } catch (error) {
          console.log(error); 
        }
    } else {
      setPlateDetails([])
    }
  } catch (error) {
    console.error('Plate response', error);
  }

}


//batch list for filter dropdown 
const listPlateBatch = async () => {
  try {
    const response = await plateRelatedService.listPlateBatch();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  value: element.batch_number,
                  label: element.batch_number ,
              };
            });
            setPlateBatches(rowData);
          }
        } catch (error) {
          console.log(error); 
        }
    }
  } catch (error) {
    console.error('Plate response', error);
  }
}


const handleSort = (key: string, value: boolean) => {
  setSort(key)
  if (value) {
    setOrder("asc")  
    getBatchList(key,"asc")    
  } else {
    setOrder("desc")
    getBatchList(key,"desc")
  }
 
}

//get permission by role 
const getPermissionByRole = async (role : number) => {
  try {
    const response = await userRoleServive.getPermissionByRole(role);
    if(response.status && response.statusCode === 200){
      setPermission(response.data.permissions)
    }
  } catch (error) {
    console.error(error);
  }
}

useEffect (() => {
  var storedData = localStorage.getItem('userDetails');

  if(storedData) {
    var storedObject = JSON.parse(storedData);
    setUserId(storedObject[0].id);
    getPermissionByRole(storedObject[0].role_master_id);
  }
  }, [])


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Discard" />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 
      <div className="exceptionHandlingTab">
        <button
          onClick={() => setTab("1")}
          className={tab === "1" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t('plateInventory.discard')}
        </button>
        <button
          onClick={() => setTab("2")}
          className={tab === "2" ? "colTabBtnSel" : "colTabBtn"}
          >
            {t('plateInventory.history')}
        </button>
      </div>
      {tab === "1" && (
         <Plates
          plateDetails={plateDetails}
          plateBatches={plateBatches}
          handleSort={handleSort}
         />
      )}

      {tab === "2" && (
        <Discards
          columns={discardHistory}
          plateBatches={plateBatches}
        />
      )}


    </div>
  );
};
export default Discard;
