export const getColumns =  (status: string): TableColumn[] => {
  const columns: TableColumn[] = [
    {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Serial Number',
      accessor: 'serial_number',
    },
    {
      Header: 'tableCommon.batch_code',
      accessor: 'batch_number',
    },
    {
      Header: 'Activity Type',
      accessor: 'plate_type',
    },
    {
      Header: 'Incubation Ends In',
      accessor: 'remainingTime',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Incubated by',
      accessor: 'incubated_by',
    },
  ];

  // Conditionally add the 'Incubation Duration' column
  if (status !== "Pending" && status !== "In Progress") {
    columns.splice(5, 0, {
      Header: 'Incubation Duration',
      accessor: 'incubation_duration',
    });
  }


  return columns;
};

export const columnsHistory: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'slno',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_number',
      },
      {
        Header: 'Serial Number',
        accessor: 'serial_number',
      },
      {
        Header: "Activity Type",
        accessor: "plate_type",
      },
      {
        Header: 'Incubation Duration',
        accessor: 'incubation_duration',
      },
]

  
interface TableColumn {
    Header: string;
    accessor: string;
}