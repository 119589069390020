export const HolidayNamecolumns: TableColumn[] = [
    {
        Header: "Sl.No",
        accessor: "slno",
      },
      {
        Header: " Shift Name",
        accessor: "shift_name",
      },
      {
        Header: "Start Time",
        accessor: "start_time",
      },
      {
        Header: "End Time",
        accessor: "end_time",
      },
      {
        Header: "Duration (hrs)",
        accessor: "duration",
      },

]

export const columns: TableColumn[] = [
  {
      Header: "Sl.No",
      accessor: "slno",
    },
    {
        Header: " Shift Detail Name",
        accessor: "remarks",
    },
    {
      Header: " Shift Name",
      accessor: "shift_name",
    },
    {
        Header: "Start Time",
        accessor: "start_time",
    },
    {
        Header: "End Time",
        accessor: "end_time",
    },
    {
      Header: "Start Date",
      accessor: "start_date",
    },
    {
        Header: "End Date",
        accessor: "end_date",
    },
    {
        Header: "Assigned Operator",
        accessor: "assigned_operator",
    },
]


interface TableColumn {
    Header: string;
    accessor: string;
  }