import React from 'react'
import Modal from 'react-bootstrap/Modal';
import {TfiClose} from 'react-icons/tfi'




interface AlertModalProps {
    show: boolean;
    onHide: () => void;
    title: string;
    message: string;
    onConfirm: () => void;
  }


const AlertModal: React.FC<AlertModalProps> = ({
    show,
    onHide,
    title,
    message,
    onConfirm,
}) => {

    const handleConfirm = (event : any) => {
        const button = event.target;    // Get the button element
        button.disabled = true;         // Disable the button
        onConfirm();                    // Call the onConfirm function
      };

  return (
    <Modal centered size='sm' show={show}>
        <div>
        <div className='modalHead'>
            <p className='modalHeadText2 mx-3 my-2'>{title}</p>
            <div onClick={onHide} className='p-2 mx-2'>
            <TfiClose fill="#FFFFFF" />
            </div>
        </div>
        <div className='text-center mt-3 mb-5'>
            <p className='normalText2 '>Are you sure you want to <br/> 
                <span><strong>{message}</strong></span>
            </p>
        </div>
        <div className='d-flex justify-content-around pb-3'>
            <button onClick={onHide} className='modalCancelBtn2'>
            Cancel
            </button>
            <button onClick={(e) => handleConfirm(e)} className='squareOrangeBtn3'>
            Confirm
            </button>
        </div>
        </div>
    </Modal>
  )
}

export default AlertModal