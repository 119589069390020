export const activeSamplingColumns: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'slno',
      },
      {
        Header: 'Area Name',
        accessor: 'area',
      },
      {
        Header: 'Location',
        accessor: 'location',
      },
      {
        Header: 'Serial Number',
        accessor: 'serial_number',
      },
      {
        Header: 'Batch Code',
        accessor: 'batch_number',
      },
      {
        Header: 'Lot Code',
        accessor: 'lot_code',
      },
      {
        Header: 'Equipment Code',
        accessor: 'area_code',
      },
      {
        Header: 'Sampling Started By',
        accessor: 'sampling_started_by',
      },
      {
        Header: 'Sampling Started Date',
        accessor: 'start_date_time',
      },
      {
        Header: 'Sampling Duration',
        accessor: 'sampling_duration',
      },
      {
        Header: 'First Incubation Start By',
        accessor: 'incubation_started_by',
      },
      {
        Header: 'First Incubation Start Date',
        accessor: 'incubation_start_date',
      },
      {
        Header: 'First Incubation End By',
        accessor: 'incubation_end_by',
      },
      {
        Header: 'First Incubation End Date',
        accessor: 'incubation_end_date',
      },
      {
        Header: 'First Incubation Duration',
        accessor: 'incubation_duration',
      },
      {
        Header: 'Second Incubation Start By',
        accessor: 'incubation2_started_by',
      },
      {
        Header: 'Second Incubation Start Date',
        accessor: 'incubation2_start_date',
      },
      {
        Header: 'Second Incubation End By',
        accessor: 'incubation2_end_by',
      },
      {
        Header: 'Second Incubation End Date',
        accessor: 'incubation2_end_date',
      },
      {
        Header: 'Second Incubation Duration',
        accessor: 'incubation2_duration',
      },
      {
        Header: 'Observer 1 Name',
        accessor: 'observer1_name',
      },
      {
        Header: 'Count 1',
        accessor: 'count1',
      },
      {
        Header: 'Observer 2 Name',
        accessor: 'observer2_name',
      },
      {
        Header: 'Count 2',
        accessor: 'count2',
      },
      {
        Header: 'Observer 3 Name',
        accessor: 'observer3_name',
      },
      {
        Header: 'Count 3',
        accessor: 'count3',
      },
      {
        Header: 'Approved Id',
        accessor: 'approved_id',
      },
  ];

export const passiveSamplingColumns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Area Name',
      accessor: 'area',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Serial Number',
      accessor: 'serial_number',
    },
    {
      Header: 'Batch Code',
      accessor: 'batch_number',
    },
    {
      Header: 'Lot Code',
      accessor: 'lot_code',
    },
    {
      Header: 'Location Code',
      accessor: 'area_code',
    },
    {
      Header: 'Sampling Started Date',
      accessor: 'start_date_time',
    },
    {
      Header: 'Sampling Duration',
      accessor: 'sampling_duration',
    },
    {
      Header: 'First Incubation Start By',
      accessor: 'incubation_started_by',
    },
    {
      Header: ' First Incubation Start Date',
      accessor: 'incubation_start_date',
    },
    {
      Header: 'First Incubation End By',
      accessor: 'incubation_end_by',
    },
    {
      Header: 'First Incubation End Date',
      accessor: 'incubation_end_date',
    },
    {
      Header: 'First Incubation Duration',
      accessor: 'incubation_duration',
    },
    {
      Header: 'Second Incubation Start By',
      accessor: 'incubation2_started_by',
    },
    {
      Header: 'Second Incubation Start Date',
      accessor: 'incubation2_start_date',
    },
    {
      Header: 'Second Incubation End By',
      accessor: 'incubation2_end_by',
    },
    {
      Header: 'Second Incubation End Date',
      accessor: 'incubation2_end_date',
    },
    {
      Header: 'Second Incubation Duration',
      accessor: 'incubation2_duration',
    },
    {
      Header: 'Observer 1 Name',
      accessor: 'observer1_name',
    },
    {
      Header: 'Count 1',
      accessor: 'count1',
    },
    {
      Header: 'Observer 2 Name',
      accessor: 'observer2_name',
    },
    {
      Header: 'Count 2',
      accessor: 'count2',
    },
    {
      Header: 'Observer 3 Name',
      accessor: 'observer3_name',
    },
    {
      Header: 'Count 3',
      accessor: 'count3',
    },
    {
      Header: 'Approved Id',
      accessor: 'approved_id',
    },
  
];

export const personnelMonitoringColumns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Operator ID',
      accessor: 'operator_id',
    },
    {
      Header: 'Plate Serial Number',
      accessor: 'plate_id',
    },
    {
      Header: 'Batch Code',
      accessor: 'batch_number',
    },
    {
      Header: 'Lot Code',
      accessor: 'lot_code',
    },
    {
      Header: 'Person Name',
      accessor: 'person_name',
    },
    {
      Header: 'Area Name',
      accessor: 'location',
    },
    {
      Header: 'Monitoring Type',
      accessor: 'monitoring_type',
    },
    {
      Header: 'Sampling Start Date',
      accessor: 'sampling_start_date',
    },
    {
      Header: 'First Incubation Start By',
      accessor: 'incubation_started_by',
    },
    {
      Header: ' First Incubation Start Date',
      accessor: 'incubation_start_date',
    },
    {
      Header: 'First Incubation End By',
      accessor: 'incubation_end_by',
    },
    {
      Header: 'First Incubation End Date',
      accessor: 'incubation_end_date',
    },
    {
      Header: 'First Incubation Duration',
      accessor: 'incubation_duration',
    },
    {
      Header: 'Second Incubation Start By',
      accessor: 'incubation2_started_by',
    },
    {
      Header: 'Second Incubation Start Date',
      accessor: 'incubation2_start_date',
    },
    {
      Header: 'Second Incubation End By',
      accessor: 'incubation2_end_by',
    },
    {
      Header: 'Second Incubation End Date',
      accessor: 'incubation2_end_date',
    },
    {
      Header: 'Second Incubation Duration',
      accessor: 'incubation2_duration',
    },
    {
      Header: 'Observer 1 Name',
      accessor: 'observer1_name',
    },
    {
      Header: 'Count 1',
      accessor: 'count1',
    },
    {
      Header: 'Observer 2 Name',
      accessor: 'observer2_name',
    },
    {
      Header: 'Count 2',
      accessor: 'count2',
    },
    {
      Header: 'Observer 3 Name',
      accessor: 'observer3_name',
    },
    {
      Header: 'Count 3',
      accessor: 'count3',
    },
    {
      Header: 'Approved Id',
      accessor: 'approved_id',
    },
  
];

export const surfaceMonitoringColumns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Area Name',
      accessor: 'area',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Serial Number',
      accessor: 'plate_number',
    },
    {
      Header: 'Batch Code',
      accessor: 'batch_number',
    },
    {
      Header: 'tableCommon.lot_code',
      accessor: 'lot_code',
    },
    {
      Header: 'Test Tube Serial',
      accessor: 'test_tube_number',
    },
    {
      Header: 'Sampling Done By',
      accessor: 'first_name',
    },
    {
      Header: 'Date Time',
      accessor: 'start_date_time',
    },
    {
      Header: 'First Incubation Start By',
      accessor: 'incubation_started_by',
    },
    {
      Header: 'First Incubation Start Date',
      accessor: 'incubation_start_date',
    },
    {
      Header: 'First Incubation End By',
      accessor: 'incubation_end_by',
    },
    {
      Header: 'First Incubation End Date',
      accessor: 'incubation_end_date',
    },
    {
      Header: 'First Incubation Duration',
      accessor: 'incubation_duration',
    },
    {
      Header: 'Second Incubation Start By',
      accessor: 'incubation2_started_by',
    },
    {
      Header: 'Second Incubation Start Date',
      accessor: 'incubation2_start_date',
    },
    {
      Header: 'Second Incubation End By',
      accessor: 'incubation2_end_by',
    },
    {
      Header: 'Second Incubation End Date',
      accessor: 'incubation2_end_date',
    },
    {
      Header: 'Second Incubation Duration',
      accessor: 'incubation2_duration',
    },
    {
      Header: 'Observer 1 Name',
      accessor: 'observer1_name',
    },
    {
      Header: 'Count 1',
      accessor: 'count1',
    },
    {
      Header: 'Observer 2 Name',
      accessor: 'observer2_name',
    },
    {
      Header: 'Count 2',
      accessor: 'count2',
    },
    {
      Header: 'Observer 3 Name',
      accessor: 'observer3_name',
    },
    {
      Header: 'Count 3',
      accessor: 'count3',
    },
    {
      Header: 'Approved Id',
      accessor: 'approved_id',
    },   
  
];

export const equipmentConfigurationColumns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Equipment Name',
      accessor: 'name',
    },
    {
      Header: 'Equipment Code',
      accessor: 'equipment_code',
    },
    {
      Header: 'Calibaration Date',
      accessor: 'startDate',
    },
    {
      Header: 'Calibaration Due Date',
      accessor: 'endDate',
    },
    {
      Header: 'Min Temperature',
      accessor: 'min_temp',
    },
    {
      Header: 'Max Temperature',
      accessor: 'max_temp',
    },
  
];

export const plateActivityColumns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Date',
      accessor: 'logDate',
    },
    {
      Header: 'User',
      accessor: 'name',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Reasons',
      accessor: 'reason',
    },
  
];


export const surfaceMonitoringReport: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Area Name',
      accessor: 'area',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Serial Number',
      accessor: 'plate_number',
    },
    {
      Header: 'Batch Code',
      accessor: 'batch_number',
    },
    {
      Header: 'tableCommon.lot_code',
      accessor: 'lot_code',
    },
    {
      Header: 'Sampling Done By',
      accessor: 'first_name',
    },
    {
      Header: 'Date Time',
      accessor: 'start_date_time',
    },
    {
      Header: 'Incubation Start By',
      accessor: 'incubation_started_by',
    },
    {
      Header: 'Incubation Start Date',
      accessor: 'incubation_start_date',
    },
    {
      Header: 'Incubation End By',
      accessor: 'incubation_end_by',
    },
    {
      Header: 'Incubation End Date',
      accessor: 'incubation_end_date',
    },
    {
      Header: 'Incubation Duration',
      accessor: 'incubation_duration',
    },
    {
      Header: 'Second Incubation Start By',
      accessor: 'incubation2_started_by',
    },
    {
      Header: 'Second Incubation Start Date',
      accessor: 'incubation2_start_date',
    },
    {
      Header: 'Second Incubation End By',
      accessor: 'incubation2_end_by',
    },
    {
      Header: 'Second Incubation End Date',
      accessor: 'incubation2_end_date',
    },
    {
      Header: 'Second Incubation Duration',
      accessor: 'incubation2_duration',
    },
    {
      Header: 'Observer 1 Name',
      accessor: 'observer1_name',
    },
    {
      Header: 'Count 1',
      accessor: 'count1',
    },
    {
      Header: 'Observer 2 Name',
      accessor: 'observer2_name',
    },
    {
      Header: 'Count 2',
      accessor: 'count2',
    },
    {
      Header: 'Observer 3 Name',
      accessor: 'observer3_name',
    },
    {
      Header: 'Count 3',
      accessor: 'count3',
    },
    {
      Header: 'Approved Id',
      accessor: 'approved_id',
    },
  
];



  interface TableColumn {
    Header: string;
    accessor: string;
  }