export const columns: TableColumn[] = [
      {
        Header: "Sl.no",
        accessor: "slno",
      },
      {
        Header: "auditTrialReport.tableColumns.user",
        accessor: "action_done_by",
      },
      {
        Header: "auditTrialReport.tableColumns.dateAndTime",
        accessor: "created_at",
      },
      {
        Header: "auditTrialReport.tableColumns.action",
        accessor: "action",
      },
      {
        Header: "auditTrialReport.tableColumns.ipAddress",
        accessor: "ip_address",
      },
      {
        Header: "auditTrialReport.tableColumns.remarks",
        accessor: "title",
      },
      
]


interface TableColumn {
    Header: string;
    accessor: string;
  }