import React, { useEffect, useState } from 'react'
import PageHeader from '../../common/pageHeader'
import HeaderSection from '../../common/headerSection'
import { Modal} from 'react-bootstrap'
import CustomTable from '../../../widgets/table'
import { columns} from './partials/columns'
import { TfiClose } from 'react-icons/tfi'
import { useTranslation } from 'react-i18next';
import AlertModal from '../../../widgets/alertModal'
import CustomToast from '../../../widgets/toaster'
import Pagination from '../../../widgets/pagination';
import { userService } from '../../../service/userService'
import { deviceRegistration } from '../../../service/deviceRegistration'
import NotificationConfigModal from './partials/modal'


const NotificationConfiguration = () => {
 
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalName, setModalName] = useState(t('applicationConfiguration.addDeviceRegistration'));
  const [notificationModalName, setNotificationModalName] = useState(t('applicationConfiguration.addNotification'));
  const [modalEdit, setModalEdit] = useState(false);
  const [deviceRegisterData, setDeviceregisterData] = useState({
    deviceId: "",
    deviceName: "",
    remarks: "",
    id: "",
    reason: ""
  });
  const [deviceRegister, setDeviceRegister] = useState([]);
  const [addDeviceAlert, setAddDeviceAlert] = useState(false);
  const [idAndStatus, setIdAndStatus] = useState({ 
    id: "",
    status: "" 
  });
  const [showUserStatusChangeAlert, setShowUserStatusChangeAlert] = useState(false);
  const [useEffectRequired, setuseEffectRequired] = useState(false)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
   // table pagination for sample type
   const [page, setPage] = useState(1);
   const [pageSize, setPageSize] = useState(5);
   const [totalPages, setTotalPages] = useState(0);
   const [totalElements, setTotalElements] = useState(0);
   // table pagination for sample Sub type

   const [keyWord, setkeyWord] = useState("")

   interface Errors {
    deviceName?: string,
    deviceId?: string,
    remarks?: string,
    reason?: string
  }
  type FormDataType = {
    id: string;
    popUpNotification: boolean;
    emailNotification: boolean;
    leadAlert: string;
    delayAlert: string;
    leadAlarm: string;
    delayAlarm: string;
    popUpSnooze: string;
    notificationEvents: string;
    notificationType: string;
    userSelection: any;
    reason: string;
};
  
  const [errors, setErrors] = useState<Errors>({});
  const [userId, setUserId] = useState("");
  const [authToken , setAuthToken] = useState("");
  const [authUserID , setAuthUserID] = useState("");
  const [permission, setPermission] = useState<any>([]);
  const [formData, setFormData] = useState<FormDataType>({
    id:"",
    popUpNotification: false,
    emailNotification: false,
    leadAlert: "",
    leadAlarm: "",
    delayAlert: "",
    delayAlarm: "",
    popUpSnooze: '',
    notificationEvents: '',
    notificationType: '',
    userSelection: '',
    reason: '',
});





  useEffect(() => {

    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      setAuthToken(storedObject[0].x_auth_token);
      setAuthUserID(storedObject[0].x_userid);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listDeviceList();
    setuseEffectRequired(false)
  }, [useEffectRequired])
  

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }

  //get all_raw_material_type list from api  
  const listDeviceList = async () => {
    try {
      const response = await deviceRegistration.listNotification(page, pageSize, keyWord);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    event_name: element.event_name,
                    id : element.id,
                    status : element.status,
                    popUpSnooze: element.snooze_time,
                    leadTime: element.lead_time,
                    delayTime: element.delay_time,
                    popUpNotification: element.pop_up_notification,
                    emailNotification: element.email_notification,
                    userSelection: element.assigned_users,
                    notificationEvents : element.notification_event_id,
                    notificationType : element.notification_type
                };
              });
              setDeviceRegister(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }else {
        setDeviceRegister([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  //create raw material type 
  const createDeviceRegister = async () => {
    setAddDeviceAlert(false)

    const dataToSend = {
        id: deviceRegisterData.id,
        device_id : deviceRegisterData.deviceId,
        device_name : deviceRegisterData.deviceName,
        remarks : deviceRegisterData.remarks,
        "interface_name" : "Device Registration",
        reason : deviceRegisterData.reason
    }

    try {
      const response = await deviceRegistration.registerDevice(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setuseEffectRequired(true)
    } catch (error) {
      console.error('user response', error);
    }
  }



  //handle edit click 
  const handleEditClick = (id: string) => {
    setNotificationModalName(t('applicationConfiguration.editNotification'))
    setShowAddModal(true)
    const filteredUserDetails: any = deviceRegister.filter((item: any) => item.slno === id);
    const popUpNotification = filteredUserDetails[0]?.popUpNotification === "on";
    const emailNotification = filteredUserDetails[0]?.emailNotification === "on";
    let rowData: any = filteredUserDetails[0]?.userSelection.map((element: any, index: number) => {
      return { 
          id: element.user_id,
          value: element.user_full_name,
          label: element.user_full_name,
      };
    })
    setFormData({
      id:filteredUserDetails[0]?.id,
      popUpNotification: popUpNotification,
      emailNotification: emailNotification,
      leadAlarm: filteredUserDetails[0]?.leadTime,
      delayAlarm: filteredUserDetails[0]?.delayTime,
      leadAlert: filteredUserDetails[0]?.leadTime,
      delayAlert: filteredUserDetails[0]?.delayTime,
      popUpSnooze: filteredUserDetails[0]?.popUpSnooze,
      notificationEvents: filteredUserDetails[0]?.notificationEvents,
      notificationType: filteredUserDetails[0]?.notificationType,
      userSelection: rowData,
      reason : '',
    })
  }


  const handleSubmit = () => {
    setAddDeviceAlert(false)
    createDeviceRegister()
  }

  //show status alert modal for status change 
  const handleStatusAlert = async (id:any , status: string) => {
    setIdAndStatus({ id, status });
    setShowUserStatusChangeAlert(true)
  }


  //api call to make user active or inactive 
  const handleStatusChange = async () => {
    let updatedStatus = "";
      if (idAndStatus?.status === "active") {
        updatedStatus = "inactive";
      } else {
        updatedStatus = "active";
      }
      
      try {
        const response = await deviceRegistration.changeNotificationStatus(idAndStatus.id, updatedStatus, authToken ,authUserID ,"Notification Configuration Status");
        
        if (response.status && response.statusCode === 200) {
          setuseEffectRequired(true)
          setShowUserStatusChangeAlert(false)
          updatedStatus = ""
          setToastMessage(response.message)
          setToastType('success')
        } else {
          setShowUserStatusChangeAlert(false)
          updatedStatus = ""
          setToastMessage(response.message)
          setToastType('error')
        }
        setShowToast(true)
      } catch (error) {
        console.error(error);
      }
  }



  const addEquipmentBtnClick = () => {
    setModalName(t('applicationConfiguration.notificationRegistration'))
    setNotificationModalName(t('applicationConfiguration.addNotification'))
    setModalEdit(false)
    setShowAddModal(true)
  }

  //handle table pagination 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage)
    if(pageSize) {
      setPageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  //handle filter section in sample type
  const handleFilter = (key: string, value: any) => {
    if(key === "Role") {
      // setRole(value)
    } else if (key === "search") {
      setkeyWord(value)
    }
    setuseEffectRequired(true)
  }


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('applicationConfiguration.notificationRegistration')} />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 
   
        <div className='me-3' style={{marginTop : "20px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    tableHead={t('applicationConfiguration.notificationRegistration')}
                    data={deviceRegister} 
                    columns={columns} 
                    isEditable={permission?.includes("edit_device")} 
                    isTempDeletable={false}
                    totalElements={totalElements} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    isSeachable={true}
                    isToggleStatus={permission?.includes("edit_device")}
                    isResetPassword={false}
                    addButton={permission?.includes("add_device")}
                    addButtonText={t('applicationConfiguration.notificationRegistration')}
                    onUseEffectRequired={() => setuseEffectRequired(true)}
                    onFilterClick={handleFilter}
                    onStatusChange={handleStatusAlert}
                    onAddClick={addEquipmentBtnClick}             
                    onEditClick={handleEditClick}
                    changeRoleButton={false}
                />
            </div>
            <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </div>

        <NotificationConfigModal 
          showAddModal={showAddModal} 
          setShowAddModal={setShowAddModal} 
          userId={userId}
          notificationModalName={notificationModalName}
          setuseEffectRequired={setuseEffectRequired}
          setShowToast={setShowToast}
          setToastMessage={setToastMessage}
          setToastType={setToastType}
          setFormData={setFormData}
          formData={formData}
        />
      


     
        {/* Alert modal for Add policy */}
        <AlertModal 
          show={addDeviceAlert}
          onHide={() => setAddDeviceAlert(false)}
          title='Alert'
          message={`${modalName}`}
          onConfirm={handleSubmit}
        />

        {/* Alert modal for sample type status change */}
        <AlertModal
          show={showUserStatusChangeAlert}
          onHide={() => setShowUserStatusChangeAlert(false)}
          title='Alert'
          message={`Change Notification Configuration Status ?`}
          onConfirm={handleStatusChange}
        /> 

    </div>

  )
}

export default NotificationConfiguration
