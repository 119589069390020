import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
import { helperFunctions as _h } from "../../utils/helper";

let authToken = "";
let authUserID = "";

const storedData = localStorage.getItem("userDetails");

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (
      storedObject &&
      Array.isArray(storedObject) &&
      storedObject.length > 0
    ) {
      authToken = storedObject[0].x_auth_token;
      authUserID = storedObject[0].x_userid;
    }
  } catch (error) {
    console.error("Failed to parse user details:", error);
  }
}

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: baseUrl,
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // If the response is successful, just return the response
    return response;
  },
  (error) => {
    // Check for 400 or 401 status codes
    if (
      axios.isAxiosError(error) &&
      (error.response?.status === 408 || error.response?.status === 401)
    ) {
      // Redirect to login page
      handleLogOut();
    }
    // Return any other errors
    return Promise.reject(error);
  }
);

const getUserList = async (
  page: number,
  size: number,
  sort: string,
  order: string,
  keyWord: any,
  role_master_id: number,
  department_id: number
) => {
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/empats/list_users?page=${page}&size=${size}&sort=${sort}&order=${order}&status="active"&keyword=${keyWord}&role_master_id=${role_master_id}&department_id=${department_id}`,
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const changeUserStatus = async (
  id: string,
  status: string,
  interface_name: string
) => {
  try {
    const response = await axiosInstance.post(
      `${baseUrl}/empats/change_status_user`,
      {
        id: id,
        status: status,
        interface_name: interface_name,
      },
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const deleteUserTemp = async (id: string, interface_name: string) => {
  try {
    const response = await axiosInstance.post(
      `${baseUrl}/empats/delete_user`,
      {
        id: id,
        interface_name: interface_name,
      },
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const getUserRoleList = async () => {
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/master/list_all_user_roles?status=active`,
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const getSupervisorUsersList = async (filter: any) => {
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/master/list_all_possible_supervisors?status=active&sort=first_name&order=asc` +
        (_h.isNotEmpty(filter?.id) ? "&id=" + filter?.id : ""),
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const getUserDepartmentList = async () => {
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/master/list_all_departments?status=active`,
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const registerUser = async (dataToSend: any) => {
  try {
    const response = await axiosInstance.post(
      `${baseUrl}/empats/register`,
      dataToSend,
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const getUserByUserId = async (userId: string) => {
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/empats/get_user_by_id?user_id=${userId}`,
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const changeUserRoleDepartment = async (dataToSend: any) => {
  try {
    const response = await axiosInstance.post(
      `${baseUrl}/user/change_user_role_department`,
      dataToSend,
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const geAreaTypeList = async () => {
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/master/list_all_location_types?status=active&category=0,1`,
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const getUserNameList = async (role_master_id: any) => {
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/empats/list_users?sort=id&order=desc&status=active,inactive&keyword&role_master_id=${role_master_id}`,
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const getPermissionByRole = async (role: number) => {
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/roles/get_permissions_by_role?&sort=sort_order&order=asc&status=active&keyword=&role_id=${role}`,
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const getCurrentVersion = async () => {
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/versions/list?page=1&size=10&sort=&order=asc&status=active&keyword=&type_ids=&id`,
      {
        headers: {
          "x-auth-token": authToken,
          "x-userid": authUserID,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    }
  }
};

const userService = {
  getUserList,
  changeUserStatus,
  deleteUserTemp,
  getUserRoleList,
  getUserDepartmentList,
  registerUser,
  getUserByUserId,
  changeUserRoleDepartment,
  geAreaTypeList,
  getUserNameList,
  getPermissionByRole,
  getCurrentVersion,
  getSupervisorUsersList,
};

export { userService };
