import React, { useState, useEffect } from "react";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import CustomToast from "../../../widgets/toaster";
import CustomTable from "../../../widgets/table";
import Pagination from "../../../widgets/pagination";
import { useTranslation } from "react-i18next";
import baseUrl from "../../../service";
import { deviceRegistration } from "../../../service/deviceRegistration";
import { columns } from "./patials/column";
import AlertModal from "../../../widgets/alertModal";
import { TfiClose } from "react-icons/tfi";
import { Modal } from "react-bootstrap";
import { Accept, useDropzone } from "react-dropzone";
import { FaTimes } from "react-icons/fa";
import { MdFileUpload } from "react-icons/md";
import { CSVLink } from "react-csv";
import { AiOutlineFileText } from "react-icons/ai";
import { userService } from "../../../service/userService";

const SopReport = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [addDeviceAlert, setAddDeviceAlert] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [sopDetails, setsopDetails] = useState<Array<any>>([]);
  const [sopName, setSopName] = useState("");
  const [sopDescription, setSopDescription] = useState("");
  const [useEffectRequired, setuseEffectRequired] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const { t } = useTranslation();
  // const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<{ file: File; file_id?: string }[]>([]);
  const [error, setError] = useState("");
  const accept: Accept = { "application/pdf": [] };
  const [fileIds, setFileIds] = useState<any>([]);
  const [permission, setPermission] = useState<any>([]);
  //handle error section
  interface Errors {
    sopName?: string;
    file?: string;
  }
  const [errors, setErrors] = useState<Errors>({});
  const dropzoneStyles: React.CSSProperties = {
    border: "2px dashed #ccc",
    borderRadius: "4px",
    textAlign: "center",
    padding: "20px",
    margin: "10px 25px",
    cursor: "pointer",
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    multiple: true,
    onDrop: (acceptedFiles) => {
      // setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
      // acceptedFiles.forEach((file) => handleFileUpload(file));
      const filesWithIds = acceptedFiles.map((file) => ({ file }));
      setUploadedFiles((prevFiles) => [...prevFiles, ...filesWithIds]);
      filesWithIds.forEach((fileObj) => handleFileUpload(fileObj));
    },
  });
  const removeFile = (file: any) => {
    setUploadedFiles(uploadedFiles.filter((f) => f !== file));
  };

  useEffect(() => {
    listSop();
  }, []);


  const handleFileUpload = async (fileObj: { file: File; file_id?: string }) => {
    try {
      const response = await deviceRegistration.SopFIleUpload(fileObj.file);
      if (response.status && response.statusCode === 200) {
        // setFileIds((prevImages : any) => [...prevImages, response.file_id]);
        setUploadedFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.file === fileObj.file ? { ...f, file_id: response.file_id } : f
          )
        );
        // setToastMessage(response.message)
        // setShowToast(true)
        // setToastType("success")
      }else {
          setToastMessage(response.message)
          setShowToast(true)
          setToastType("error")
          console.error('Error uploading File:', response);
        }
      } catch (error) {
        console.error('Error capturing and uploading File:', error);
      }
  };

  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
        // try {
        //     if ( Array.isArray(response.data) && response.data?.length > 0) {
        //       let rowData: any = response.data.map((element: any, index: number) => {

        //         return { 
        //           // slno: index + 1, 
        //           label: element.title,
        //           value : element.id,
        //           // status : element.status,
        //         };
        //       });
        //       // setUserRoleList(rowData);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setuseEffectRequired(true);
  };

  const listSop = async () => {
    try {
      const response = await deviceRegistration.listSop(page, pageSize);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  doc_id: element.doc_id,
                  caption: element.caption,
                  description: element.description,
                };
              }
            );
            setsopDetails(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setsopDetails([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  const handleDownload = (id: string) => {
    const matchedItem = sopDetails.filter((item: any) => item.slno === id);
    const selectedIds = matchedItem.map((item) => item.doc_id).join(',');
    const link = baseUrl + "/files/download?file_id=" + selectedIds;
    window.open(link, "_blank");
  };

  const handleAddButtonClick = () => {
    setShowAddModal(true);
  };

  const handleSubmit = async () => {
    const dataToSend = {
      "caption" : sopName,
      "description" : sopDescription,
      "doc_id" : uploadedFiles.map(fileObj => fileObj.file_id).filter(id => id).join(','),
      
    }
    try {
      const response = await deviceRegistration.startSopSubmit(dataToSend);
      if(response.status && response.statusCode === 200){      
           setToastType("success")
           setToastMessage(response.message)    
           setShowAddModal(false)  
           setShowAddModalClose();
      } else{
          setToastType("error")
          setToastMessage(response.message)   
      }
      setShowToast(true)
      setAddDeviceAlert(false)
      // setShowAddModal(false)
      listSop();
    
    } catch (error) {
      console.error('user response', error);
    }
  };


  const setShowAddModalClose = () => {
    setShowAddModal(false);
    setSopName("");
    setSopDescription("")
    // setFileIds([])
    setUploadedFiles([])
    setErrors({});
    setError("")
  };

  const handleExceptionAlert = () => {
    const errors: Errors = {};
    if (!sopName) {
      errors.sopName = "Please Enter SOP Name";
    }
    if (uploadedFiles.length === 0) {
      setError("Please Select a File to upload")
      errors.file = "Please Select a File to upload";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setAddDeviceAlert(true);
    setError("")
    setErrors({});
  };

  useEffect(() => {
    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      getPermissionByRole(storedObject[0].role_master_id);
    }
  }, []);


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="SOP" />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />

      <div className="me-3" style={{ marginTop: "20px" }}>
        <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
          <CustomTable
            tableHead={t("sop.sop")}
            data={sopDetails}
            columns={columns}
            isEditable={false}
            isTempDeletable={false}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={false}
            isSeachable={false}
            isResetPassword={false}
            addButton={permission?.includes("upload_sop")}
            addButtonText={t("sop.uploadSop")}
            onUseEffectRequired={() => setuseEffectRequired(true)}
            onAddClick={handleAddButtonClick}
            changeRoleButton={false}
            sopDownloadButton={true}
            onEditClick={handleDownload}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      <AlertModal
        show={addDeviceAlert}
        onHide={() => setAddDeviceAlert(false)}
        title="Alert"
        message={t("sop.addSop")}
        onConfirm={handleSubmit}
      />

      <Modal className="top-right-modal" centered={false} show={showAddModal}>
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">
              {t("sop.sopFileUpload")}
            </p>
            <div onClick={setShowAddModalClose} className="p-2 mx-3 my-2 ">
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>
          <div
            className="form-group ms-2 ps-1"
            style={{ textAlign: "left", padding: "10px" }}
          >
            <label htmlFor="sopName">{t("sop.sopName")}<span style={{ color: "red" }}>*</span></label>
            <input
              type="text"
              className="form-control"
              id="sopName"
              value={sopName}
              onChange={(e) => {
                const value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""); // Allow letters, numbers, and spaces only
                setSopName(value);
              }}
            />
            {errors.sopName && <p className="errorText">{errors.sopName}</p>}
          </div>
          <div
            className="form-group ms-2 ps-1"
            style={{ textAlign: "left", padding: "10px" }}
          >
            <label htmlFor="sopDescription">{t("sop.sopDescription")}</label>
            <textarea
              className="form-control"
              id="sopDescription"
              value={sopDescription}
              onChange={(e) => {
                const value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""); // Allow letters, numbers, and spaces only
                setSopDescription(value);
              }}
            />
          </div>

          <div
            className="form-group ms-2 ps-1"
            style={{ textAlign: "left", padding: "10px" }}
          >
            <label htmlFor="sopDescription">{t("sop.sopFileUpload")}<span style={{ color: "red" }}>*</span></label>
          </div>

          {uploadedFiles.length < 1 && (
          <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            <div>
              {isDragActive ? (
                <p>{t("sop.fileDragingText")}</p>
              ) : (
                <div>
                  <div>
                    <MdFileUpload size={40} fill="#FF7344" />
                  </div>
                  <br />
                  {t("plateInventory.drag_files")}
                </div>
              )}
            </div>
          </div>
          )}

          <div>
              {uploadedFiles.length > 0 && (
                uploadedFiles.map((fileObj, index) => (
                  <div key={index}>
                    <div>
                      <AiOutlineFileText size={50} fill="#FF7344" />
                    </div>
                    <div className="d-flex justify-content-center">
                      <p>{fileObj.file.name}</p>
                      <FaTimes
                        onClick={() => removeFile(fileObj)}
                        className="mt-1 ms-3"
                        fill="red"
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          <p className="errorText text-center">{error}</p>

          <div className="bottomArea">
            <button onClick={setShowAddModalClose} className="modalCancelBtn">
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => handleExceptionAlert()}
              className="squareOrangeBtn3"
            >
              {t("buttons.submit")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SopReport;
