import React from 'react';
import HeaderSection from '../headerSection';
import PageHeader from '../pageHeader';

const WelcomeNote = () => {
  return (
    <>
    <HeaderSection />
      <PageHeader 
        pageHeader="" />
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60vh', // Full height of the viewport
        backgroundColor: 'white', // Light background color
        textAlign: 'center',
      }}
    >
      
      <p
        style={{
          fontSize: '30px',
          color: '#555',
          marginTop: '20px',
          maxWidth: '800px',
          lineHeight: '1.6',
          fontWeight: "600"
        }}
      >
        Welcome to MPATS Application.
      </p>
    </div>
    </>
  );
};

export default WelcomeNote;
