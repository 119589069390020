export const columns: TableColumn[] = [
  {
    Header: "tableCommon.sl_no",
    accessor: "slno",
  },
  {
    Header: "scheduleFrequency.tableColumns.activity_type_name",
    accessor: "activity_type_name",
  },
  {
    Header: "scheduleFrequency.tableColumns.area_grade_name",
    accessor: "area_grade_name",
  },
  // {
  //   Header: "scheduleFrequency.tableColumns.area",
  //   accessor: "area",
  // },
  {
    Header: "scheduleFrequency.tableColumns.location_category_name",
    accessor: "location_category_name",
  },
  // {
  //   Header: "scheduleFrequency.tableColumns.location",
  //   accessor: "location",
  // },
  {
    Header: "scheduleFrequency.tableColumns.op",
    accessor: "op",
  },
  // {
  //   Header: "scheduleFrequency.tableColumns.productionType",
  //   accessor: "productionType",
  // },
  {
    Header: "scheduleFrequency.tableColumns.frequency",
    accessor: "frequency",
  },
  {
    Header: "scheduleFrequency.tableColumns.frequency_period",
    accessor: "frequency_period",
  },
  {
    Header: "scheduleFrequency.tableColumns.created_at",
    accessor: "created_at",
  },
];

interface TableColumn {
  Header: string;
  accessor: string;
}
