
//table columns for lab test 
export const columns: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'slno',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_number',
      },
      {
        Header: 'tableCommon.seriel_number',
        accessor: 'serial_number',
      },
      {
        Header: 'Lab Test Done On',
        accessor: 'labTestDoneOn',
      },
      {
        Header: 'Colony Count',
        accessor: 'colonyCount',
      },
      {
        Header: 'Test Remarks',
        accessor: 'testRemarks',
      },
  ];
  

  export const PendingColumns: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'sl_no',
      },
      {
        Header: 'Plate Serial No.',
        accessor: 'serial_number',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_number',
      },
      {
        Header: 'Plate Type',
        accessor: 'activity_type',
      },
      {
        Header: 'Lab Status',
        accessor: 'lab_status',
      },
  ];


  interface TableColumn {
    Header: string;
    accessor: string;
  }
  