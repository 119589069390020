import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap'
import { TfiClose } from 'react-icons/tfi';
import { policyService } from '../../../../service/policyServices';
import AlertModal from '../../../../widgets/alertModal';
import { useTranslation } from 'react-i18next';


interface AddPolicyModalProps {
    showAddModal: boolean;
    setShowAddUserAlert: () => void;
    setShowAddModal: () => void;
    setSelectedPolicyId: (id: string) => void;
    departmentList: any[];
    areas: any[];
    policyId?: string;
    modalName: string;
    setAddPolicyData: React.Dispatch<React.SetStateAction<{ 
        id:string,
        policyName: string,
        areaType: string,
        policyDuration: string,
        isEquipmentCalReq: string,
        lastCalDate: string,
        nextCalDate: string,
        incubationDurFromExp: string,
        incubationCount: string,
        colCountMinRange: string,
        colCountMaxRange: string,
        isColCountManual: string,
        labTestDurFromInc: string,
        AutoclaveDurFromLab: string,
        isAutoclaveStopMandatory: string,
        fingerDabType: string,
        fingerDabDepartment: string,
        incubationTemparature: string,
        incubationTemparatureMax : string,
        equipmentCalibrationDays: string,
        plateType: string,
        reuseUnUsedPlates: string,
        incubationOrder: string,
        reason: string
      }>>;
    addPolicyData: { 
      policyName: string,
      areaType: string,
      policyDuration: string,
      isEquipmentCalReq: string,
      lastCalDate: string,
      nextCalDate: string,
      incubationDurFromExp: string,
      incubationCount: string,
      colCountMinRange: string,
      colCountMaxRange: string,
      isColCountManual: string,
      labTestDurFromInc: string,
      AutoclaveDurFromLab: string,
      isAutoclaveStopMandatory: string,
      fingerDabType: string,
      fingerDabDepartment: string,
      incubationTemparature: string,
      incubationTemparatureMax:string,
      equipmentCalibrationDays: string,
      plateType: string,
      reuseUnUsedPlates: string,
      incubationOrder: string,
      reason: string,
      id: string
    };


}

const AddPolicyModal: React.FC<AddPolicyModalProps> = ({
    showAddModal,
    setShowAddUserAlert,
    setShowAddModal,
    setSelectedPolicyId,
    setAddPolicyData,
    areas,
    departmentList,
    policyId,
    addPolicyData,
    modalName,
}) => {


    interface Errors {
        policyName?: string,
        areaType?: string,
        policyDuration?: string,
        isEquipmentCalReq?: string,
        lastCalDate?: string,
        nextCalDate?: string,
        incubationDurFromExp?: string,
        incubationCount?: string,
        colCountMinRange?: string,
        colCountMaxRange?: string,
        isColCountManual?: string,
        labTestDurFromInc?: string,
        AutoclaveDurFromLab?: string,
        isAutoclaveStopMandatory?: string,
        fingerDabType?: string,
        fingerDabDepartment?: string,
        incubationTemparature?: string,
        incubationTemparatureMax?:string;
        equipmentCalibrationDays?: string,
        reason?: string
      }
      const [errors, setErrors] = useState<Errors>({});
      const { t } = useTranslation();
      useEffect(() => {
        if(policyId) {
          getPolicyById(policyId)
        }
      }, [policyId])


      const handleAddPolicyChange = (e : any) => {
        const { name, value } = e.target;
        console.log(name, value, "name-value")
        const errors: Errors = {};


        if (name === 'lastCalDate' || name === 'nextCalDate') {
          const currentDate = new Date().toISOString().split('T')[0];
      
          if (name === 'lastCalDate' && value > currentDate) {
            errors.lastCalDate = `select a previous date`;
          }

          if (name === 'nextCalDate' && value < currentDate) {
            errors.nextCalDate = `select a future date`;
          }
        }

        let regex;
        if (name === "policyName") {
          regex = /^[a-zA-Z][a-zA-Z0-9]*$/;
        } else if (name === "incubationTemparature") {
          regex = /^\d+$/;
        } else if (name === "incubationTemparatureMax") {
          regex = /^\d+$/;
        } 
    
        if (value === "") {
          // console.log("haa122")
        } else if (regex && !regex.test(value)) {
          return;
        }

        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
        } else {
          setErrors({});
        }

        setAddPolicyData((prevFormData : any) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

      //handle policy management alert
      const handlePolicyAddAlert = () => {
        console.log(addPolicyData, "x12")
        const errors: Errors = {};
        let emailRegex = /^\d{0,5}$/;
        const regex = /^[a-zA-Z0-9\s]*$/;
          if (!addPolicyData.policyName) {
            errors.policyName = "Please Enter Policy Name";
          }
          if (!addPolicyData.areaType) {
            errors.areaType = "Please Select Area Type";
          }
          if (addPolicyData.areaType != '8' && !addPolicyData.policyDuration) {
            errors.policyDuration = "Please Enter a Valid Duration";
          }
          if (addPolicyData.id && !addPolicyData.reason) {
            errors.reason = "Please Enter Reason";
          }
          if (addPolicyData.labTestDurFromInc && !emailRegex.test(addPolicyData.labTestDurFromInc)){
            errors.labTestDurFromInc = "Please Enter a Valid Duration"
          }
          if (addPolicyData.areaType === "7") {
            if(!addPolicyData.incubationDurFromExp){
              errors.incubationDurFromExp = "Please Enter Incubation Duration";
            }
            if(!addPolicyData.incubationTemparature){
              errors.incubationTemparature = "Please Enter Temperature Minimum";
            }
            if(!addPolicyData.incubationTemparatureMax){
              errors.incubationTemparatureMax = "Please Enter Temperature Maximum";
            }
          }
          // if (addPolicyData.areaType === "8") {
          //   if(!addPolicyData.colCountMaxRange){
          //     errors.colCountMaxRange = "Please enter Max Colony Count";
          //   }
          // }
          if (addPolicyData.areaType === "10") {
            if(!addPolicyData.fingerDabType){
              errors.fingerDabType = "Please Select Personnel Monitoring type";
            }
            if(!addPolicyData.fingerDabDepartment){
              errors.fingerDabDepartment = "Please Select Personnel Monitoring department";
            }
          }
          if (addPolicyData.isEquipmentCalReq == "1") {
            if(!addPolicyData.lastCalDate){
              errors.lastCalDate = "Enter Last Calibrated Date";
            }
            if(!addPolicyData.equipmentCalibrationDays){
              errors.equipmentCalibrationDays = "Enter Duration Day Count";
            }
          }
          
          
          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            console.log(errors, "x12")
            return;
          }
          setShowAddUserAlert()
          setErrors({});
      }

      //on close button click 
      const addScheduleClose = () => {
        setShowAddModal()
        setErrors({})
        setSelectedPolicyId("")
        setAddPolicyData({
          id: "",
          policyName: "",
          areaType: "",
          policyDuration: "0",
          isEquipmentCalReq: "0",
          lastCalDate: "",
          nextCalDate: "",
          incubationDurFromExp: "",
          incubationCount: "",
          colCountMinRange: "0",
          colCountMaxRange: "",
          isColCountManual: "0",
          labTestDurFromInc: "0",
          AutoclaveDurFromLab: "0",
          isAutoclaveStopMandatory: "0",
          fingerDabType: "",
          fingerDabDepartment: "",
          incubationTemparature: "0",
          equipmentCalibrationDays: "",
          plateType:"",
          reuseUnUsedPlates:"",
          incubationOrder:"",
          reason: "",
          incubationTemparatureMax:"0"
        });
      }

      //api call to get the policy id 
      const getPolicyById = async (userId:string) => {
        try {
          const response = await policyService.getPolicyId(userId);
          if(response.status && response.statusCode === 200){
              setAddPolicyData({
                policyName: response.data.activity_name,
                areaType: response.data.policy_area_type,
                policyDuration: response.data.duration,
                isEquipmentCalReq: response.data.equipment_calibration,
                lastCalDate: response.data.last_calibration_date,
                nextCalDate: response.data.equipment_calibration_days,
                incubationDurFromExp: response.data.inc_duration_from_ep,
                incubationCount: response.data.inc_activity_count,
                colCountMinRange: response.data.lab_min_range,
                colCountMaxRange: response.data.lab_max_range,
                isColCountManual: response.data.lab_colony_count_entry_type,
                labTestDurFromInc: response.data.lab_duration_from_inc,
                AutoclaveDurFromLab: response.data.aut_duration_from_lab,
                isAutoclaveStopMandatory: response.data.aut_end_flag,
                fingerDabType: response.data.fin_test_type,
                fingerDabDepartment: response.data.fin_dipartment_id,
                id: response.data.id,
                incubationTemparature: response.data.inc_temparature,
                equipmentCalibrationDays: response.data.equipment_calibration_days,
                plateType:response.data.plate_types,
                reuseUnUsedPlates:response.data.plate_grades,
                incubationOrder:response.data.incubationOrder,
                reason: response.data.reason,
                incubationTemparatureMax: response.data.inc_temparature_max
              });
          }
        } catch (error) {
          console.error('user response', error);
        }
      }



  return (
    <Modal 
              className="top-right-modal-md"
              centered={false}
              show={showAddModal}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                <div onClick={() => addScheduleClose()} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>
                  <div className="selectWrap">
                    <div className="form-group my-1">
                      <label htmlFor="">{t('policyManagement.name')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                        id="policyName"
                        value={addPolicyData.policyName}
                        name="policyName"
                        type='text'
                        className="placeholder-style"
                        placeholder='Policy Name'
                        onChange={handleAddPolicyChange}
                        pattern="^[a-zA-Z][a-zA-Z0-9]*$"
                      />
                      {errors.policyName && <p className="errorText">{errors.policyName}</p>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="">{t('policyManagement.areaType')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <select
                        id="areaType"
                        value={addPolicyData.areaType}
                        name="areaType"
                        onChange={handleAddPolicyChange} 
                        className="placeholder-style"
                      >
                        <option value="">Select</option>
                        {areas.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.title}
                          </option>
                        ))}
                      </select> 
                      {errors.areaType && <p className="errorText">{errors.areaType}</p>}
                    </div>

                    {addPolicyData.areaType != '8' && (
                    <div className="form-group my-1">
                      <label>{t('policyManagement.duration')}<span style={{ color: 'red' }}> *</span></label>
                      <br />
                      <input 
                        id="policyDuration"
                        value={addPolicyData.policyDuration}
                        name="policyDuration"
                        type='number'
                        className="placeholder-style"
                        placeholder='Duration'
                        onChange={handleAddPolicyChange} 
                      />
                      {errors.policyDuration && <p className="errorText">{errors.policyDuration}</p>}     
                    </div>
                    )}

                    {/* {addPolicyData.areaType === '8' && (
                    <div className="form-group my-1">
                      <label>{t('policyManagement.count_min')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                        id="colCountMinRange"
                        value={addPolicyData.colCountMinRange}
                        name="colCountMinRange"
                        type='text'
                        onChange={handleAddPolicyChange} 
                      />
                      {errors.colCountMinRange && <p className="errorText">{errors.colCountMinRange}</p>}     
                    </div>
                    )} */}

                    {addPolicyData.areaType === '8' && (
                    <div className="form-group my-1" style={{visibility : "hidden"}}>
                      <label>{t('policyManagement.count_max')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                        id="colCountMaxRange"
                        value={addPolicyData.colCountMaxRange}
                        name="colCountMaxRange"
                        type='text'
                        className="placeholder-style"
                        placeholder='Colony Count Max Range'
                        onChange={handleAddPolicyChange} 
                      />
                      {/* {errors.colCountMaxRange && <p className="errorText">{errors.colCountMaxRange}</p>}      */}
                    </div>
                    )}
                  </div>

                  <div className="selectWrap-new" style={{visibility:"hidden"}}>                
                    <div className="form-group my-1 mx-3 px-3">
                      <label>{t('policyManagement.caliberation')}</label>
                      <div className="radio-container">
                      <input
                        type="radio"
                        id="isEquipmentCalReqYes" // Unique id for the "Yes" option
                        name="isEquipmentCalReq"
                        value="1" // Assign value 1 for "Yes"
                        checked={addPolicyData.isEquipmentCalReq === "1"}
                        onChange={handleAddPolicyChange}
                        />
                      <label htmlFor="isEquipmentCalReqYes">Yes</label>
                      <input
                        type="radio"
                        id="isEquipmentCalReqNo" // Unique id for the "No" option
                        name="isEquipmentCalReq"
                        value="0" // Assign value 0 for "No"
                        checked={addPolicyData.isEquipmentCalReq === "0"}
                        onChange={handleAddPolicyChange}
                        />
                      <label htmlFor="isEquipmentCalReqNo">No</label>
                      </div>
                    </div>

                    {addPolicyData.isEquipmentCalReq === '1' && (
                          <div className="form-group my-1 mx-4 px-4">
                            <label>{t('policyManagement.last_caliberated')} <span style={{ color: 'red' }}>*</span></label>
                            <br/>
                            <input
                              id="lastCalDate"
                              value={addPolicyData.lastCalDate}
                              name="lastCalDate"
                              type="date"
                              onChange={handleAddPolicyChange}
                              className="placeholder-style"
                            />
                            {errors.lastCalDate && <p className="errorText">{errors.lastCalDate}</p>}
                          </div>
                       )}   
                      {addPolicyData.isEquipmentCalReq === '1' && (
                          <div className="form-group my-1 mx-3 px-3">
                            <label>{t('policyManagement.next_caliberated')} <span style={{ color: 'red' }}>*</span></label>
                            <br />
                            <input
                              id="equipmentCalibrationDays"
                              value={addPolicyData.equipmentCalibrationDays}
                              name="equipmentCalibrationDays"
                              type="text"
                              onChange={handleAddPolicyChange}
                              className="placeholder-style"
                              placeholder='Caliberation Due in Days'
                            />
                            {errors.equipmentCalibrationDays && <p className="errorText">{errors.equipmentCalibrationDays}</p>}
                          </div>
                      )}
                  </div>

                  <div className="selectWrap">
                    {addPolicyData.areaType === '12' && (
                      <div className="form-group my-1">
                        <label htmlFor="" style={{ whiteSpace: 'inherit', width: '80%' }}>{t('policyManagement.incubation_duration')} <span style={{ color: 'red' }}>*</span></label>
                        <br />
                        <input 
                          id="incubationDurFromExp"
                          value={addPolicyData.incubationDurFromExp}
                          name="incubationDurFromExp"
                          type='number'
                          onChange={handleAddPolicyChange}
                          className="placeholder-style"
                          placeholder='Interval Duration'
                          maxLength={5}
                        />
                        {errors.incubationDurFromExp && <p className="errorText">{errors.incubationDurFromExp}</p>}
                      </div>
                    )}             
                    {addPolicyData.areaType === '12' && (
                        <div className="form-group my-1">
                          <label>{t('policyManagement.minimum_count')} <span style={{ color: 'red' }}>*</span></label>
                          <br />
                          <input 
                            id="incubationTemparature"
                            value={addPolicyData.incubationTemparature}
                            name="incubationTemparature"
                            type='text'
                            onChange={handleAddPolicyChange} 
                            className="placeholder-style"
                            placeholder='Minimum Temperature'
                            maxLength={5}
                          />
                          {errors.incubationTemparature && <p className="errorText">{errors.incubationTemparature}</p>}     
                        </div>   
                      )}

                      {addPolicyData.areaType === '12' && (
                        <div className="form-group my-1 mx-3 px-4">
                          <label>{t('policyManagement.maximum_count')} <span style={{ color: 'red' }}>*</span></label>
                          <br />
                          <input 
                            id="incubationTemparatureMax"
                            value={addPolicyData.incubationTemparatureMax}
                            name="incubationTemparatureMax"
                            type='text'
                            onChange={handleAddPolicyChange} 
                            className="placeholder-style"
                            placeholder='Maximum Temperature'
                            maxLength={5}
                          />
                          {errors.incubationTemparatureMax && <p className="errorText">{errors.incubationTemparatureMax}</p>}     
                        </div>   
                      )}
                     {/* <div className="form-group my-1 mx-3 px-3"></div> */}
                    
                  </div>
                  
                  <div className="selectWrap-new">
                    {addPolicyData.areaType === '8' && (
                        <div className="form-group my-1 mx-2 px-2">
                          <label style={{ whiteSpace: 'inherit', width: '95%' }}>{t('policyManagement.labtest_duration')}</label>
                          <br />
                          <input 
                            id="labTestDurFromInc"
                            value={addPolicyData.labTestDurFromInc}
                            name="labTestDurFromInc"
                            type='text'
                            className="placeholder-style"
                            onChange={handleAddPolicyChange} 
                            placeholder='Labtest Duration'
                          />
                          {errors.labTestDurFromInc && <p className="errorText">{errors.labTestDurFromInc}</p>}     
                        </div>
                    )}
                
                    {addPolicyData.areaType === '8' && (
                      <div className="form-group my-1 mx-2 px-3">
                            <label >{t('policyManagement.count_entry')}</label>
                            <div className="radio-container">
                              <input
                                type="radio"
                                id="isColCountManual"
                                name="isColCountManual"
                                value="1"
                                checked={addPolicyData.isColCountManual === "1"}
                                onChange={handleAddPolicyChange}
                              />
                              <label htmlFor="isColCountManual">Yes</label>
                              <input
                                type="radio"
                                id="isColCountManual"
                                name="isColCountManual"
                                value="0"
                                checked={addPolicyData.isColCountManual === "0"}
                                onChange={handleAddPolicyChange}
                              />
                              <label htmlFor="isColCountManual">No</label>
                            </div>
                        </div>
                      )}   

                      {addPolicyData.areaType === '9' && (
                        <div className="form-group my-1 mx-2 px-2">
                          <label style={{ whiteSpace: 'inherit', width: '95%' }}>{t('policyManagement.autoclave_duration')}</label>
                          <br />
                          <input 
                            id="AutoclaveDurFromLab"
                            value={addPolicyData.AutoclaveDurFromLab}
                            name="AutoclaveDurFromLab"
                            type='text'
                            className="placeholder-style"
                            onChange={handleAddPolicyChange} 
                            placeholder='Autoclave Duration'
                          />
                          {errors.AutoclaveDurFromLab && <p className="errorText">{errors.AutoclaveDurFromLab}</p>}     
                        </div>
                      )}
                  
                      {addPolicyData.areaType === '9' && (
                        <div className="form-group my-1 mx-2 px-3">
                              <label>{t('policyManagement.autoclave_flag')}</label>
                              <div className="radio-container">
                                <input
                                  type="radio"
                                  id="isAutoclaveStopMandatory"
                                  name="isAutoclaveStopMandatory"
                                  value="1"
                                  checked={addPolicyData.isAutoclaveStopMandatory === '1'}
                                  onChange={handleAddPolicyChange}
                                />
                                <label htmlFor="isAutoclaveStopMandatory">Yes</label>
                                <input
                                  type="radio"
                                  id="isAutoclaveStopMandatory"
                                  name="isAutoclaveStopMandatory"
                                  value="0"
                                  checked={addPolicyData.isAutoclaveStopMandatory === '0'}
                                  onChange={handleAddPolicyChange}
                                />
                                <label htmlFor="isAutoclaveStopMandatory">No</label>
                              </div>
                          </div>
                        )}   

                        {/* {addPolicyData.areaType === '10' && (
                          <div className="form-group my-1 mx-2 px-3">
                            <label htmlFor="">{t('policyManagement.fingerdabType')} <span style={{ color: 'red' }}>*</span></label>
                            <br />
                            <select
                              id="fingerDabType"
                              value={addPolicyData.fingerDabType}
                              name="fingerDabType"
                              onChange={handleAddPolicyChange} 
                            >
                              <option value="">--select--</option>
                              <option value="1">{t('policyManagement.selfAssessment')}</option>
                              <option value="2">{t('policyManagement.otherAssessment')}</option>
                            </select> 
                            {errors.fingerDabType && <p className="errorText">{errors.fingerDabType}</p>}
                          </div>       
                        )} 

                        {addPolicyData.areaType === '10' && (
                          <div className="form-group my-1 mx-2 px-3">
                            <label htmlFor="">{t('policyManagement.fdabDepartment')} <span style={{ color: 'red' }}>*</span></label>
                            <br />
                            <select
                              id="fingerDabDepartment"
                              value={addPolicyData.fingerDabDepartment}
                              name="fingerDabDepartment"
                              onChange={handleAddPolicyChange} 
                            >
                              <option value="">--select--</option>
                              {departmentList.map((obj, index) => (
                                <option key={index} value={obj?.id}>
                                  {obj?.department_name}
                                </option>
                              ))}
                            </select> 
                            {errors.fingerDabDepartment && <p className="errorText">{errors.fingerDabDepartment}</p>}
                          </div>       
                        )}  */}
                  </div>

                  <div className="selectWrap-new">
                    {addPolicyData.id && (
                    <div className="form-group my-1 mx-3 px-2">
                        <label htmlFor="" className='ReasonLabel'>{t('policyManagement.reason')} <span style={{ color: 'red' }}>*</span></label>
                        <br />
                        <input 
                            id="reason"
                            value={addPolicyData.reason}
                            name="reason"
                            type='text'
                            placeholder={t('policyManagement.reason')}
                            className="reasonClass"
                            onChange={handleAddPolicyChange} 
                        />
                        {errors.reason && <p className="errorText">{errors.reason}</p>}
                    </div>
                    )}
                  </div>
                

          </div>
              <div className="bottomArea">
            <button onClick={() => addScheduleClose()} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>
            <button onClick={() => handlePolicyAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
            {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
          </div>
            </div>
</Modal>
  )
}

export default AddPolicyModal