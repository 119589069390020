import React, { ChangeEvent, useEffect, useState } from "react";
import { workflow } from "../../../service/workflow";
import { GrEdit } from "react-icons/gr";
import { FaLessThanEqual } from "react-icons/fa";
import CustomToast from "../../../widgets/toaster";

interface InnerWorkFlowProps {
  setTab: (type: string) => void;
  activityId: any;
  selectedIds: string;
  flowName: string;
  editWorkFlow: {
      id: number;
      processDetails: any[];
      status: string;
      name: string;
      master_activity_id: any
  };
  setEditWorkFlow : React.Dispatch<React.SetStateAction<{
    id: number;
    processDetails: any[];
    status: string;
    name: string;
    master_activity_id: any
  }>>
}

const WorkflowInnerPage: React.FC<InnerWorkFlowProps> = ({
  setTab , 
  activityId,
  selectedIds,
  editWorkFlow,
  flowName,
  setEditWorkFlow

}) => {

  const [process, setProcess] = useState<Array<any>>([]);
  const [processId, setProcessId] = useState<any>("");
  // const [editWorkFlow, setEditWorkFlow] = useState<any>({});
  const [step, setStep] = useState(2);
  const [processField, setProcessField] = useState<Array<any>>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [rows, setRows] = useState([{ id: 1, content: "" }]);
  const [sequence , setSequence] = useState(1);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");


  const handleProcessChange = (event: any) => {
    setProcessId(event.target.value)
    const selectedActivity = process.find(act => act.id === event.target.value);
    setProcessField(selectedActivity?.attributes);
    // Clear form data when activity changes
    // setFormData({});
  }

  useEffect(() => {
    ProcessList(editWorkFlow?.master_activity_id)
    setUseEffectRequired(false)
  }, [editWorkFlow, useEffectRequired])

  useEffect(() => {
    getWorkFlowById(selectedIds)
  }, [useEffectRequired])

  useEffect(() => {
    handleEditRow(editWorkFlow?.processDetails[0]?.id)
    setUseEffectRequired(false)
  }, [editWorkFlow?.processDetails, useEffectRequired])


  useEffect(() => {
    const selectedOption = process.find(obj => obj.fixedSequence === "1");
    if (selectedOption) {
      setProcessId(selectedOption.id)
      handleProcessChange({ target: { value: selectedOption.id } });
    }
    setUseEffectRequired(false)
  }, [process, useEffectRequired])


useEffect(() => {

  console.log(processId, "runn")

  const selectedProcess = process.find(obj => obj.id === processId);
  let hasFixedSequence = selectedProcess?.fixedSequence === "1";



  if (process.some((obj : any) => obj.id === processId) && hasFixedSequence) {
    setIsDisabled(true);
  } else {
    setIsDisabled(false);
  }

    setUseEffectRequired(false)

}, [processId, process, editWorkFlow, workflow, useEffectRequired]);






  


  const backToHome = () => {
    setProcessField([]);
    setProcess([]);
    setFormValues({});
    setProcessId("");
    setSequence(1);
    setEditWorkFlow({
      id: 0,
      processDetails: [],
      status: "",
      name: "",
      master_activity_id: 0,
    })
    setTab("1");
  }

  const ProcessList = async (id : string) => {
    try {
      const response = await workflow.ProcessList(id);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any) => {
              return {
                id: element.id,
                name: element.name,
                attributes : element.attributes,
                fixedSequence : element.fixed_sequence,
                label_name : element.label_name
              };
            });
            setProcess(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }else{
        setProcess([])
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  const getWorkFlowById = async (id : string) => {
    try {
      const response = await workflow.EditById(id);
      if (response.status && response.statusCode === 200) {
        try {
          const rowData = {
                id: response.data.id,
                processDetails : response.data.process_details,
                status : response.data.status,
                name: response.data.name,
                master_activity_id: response.data.master_activity_id
          }

          setEditWorkFlow(rowData);
          handleEditRow(rowData?.processDetails[0]?.id)
          ProcessList(response.data.master_activity_id);
        } catch (error) {
          console.log(error);
        }
      }else{
        setEditWorkFlow({
          id: 0,
          processDetails: [],
          status: "",
          name: "",
          master_activity_id: 0,
        })
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  const handleInputChange = (event : any) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleNextClick = async () => {
    const attributeValues = Object.keys(formValues).map((key) => ({
      master_process_atributes_mapping_id: key,
      value: formValues[key],
    }));

    const dataToSend = {
      "id": selectedRowId ? selectedRowId : 0,
      "sequence": sequence ? sequence : 1,
      "activity_workflow_definition_id": selectedIds,
      "master_process_id": processId,
      "attribute_values": attributeValues
    }


    try {
      const response = await workflow.saveWorkFlowDetails(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastMessage(response.message)
        setToastType("success")
        setUseEffectRequired(true)
      } else {
        setToastMessage(response.message)
        setToastType("error")
      }
      setShowToast(true)
    } catch (error) {
      console.error("user response", error);
    }
  }


  const handleAddRow = () => {
    setFormValues({});
    setSelectedRowId(0);
    setIsDisabled(false);
  };

  const handleEditRow = (id: any) => {
    setSelectedRowId(id)

    const itemToEdit: any = editWorkFlow?.processDetails?.find((item : any) => item.id === id);

    const selectedActivity = process.find(act => act.id === itemToEdit?.master_process_id);
    setProcessField(selectedActivity?.attributes);
    setSequence(itemToEdit?.sequence)
    setProcessId(itemToEdit?.master_process_id)
    if (itemToEdit !== 0) {
      const newFormValues: { [key: string]: string } = {};
  
      itemToEdit?.attribute_values.forEach((obj: any) => {
        newFormValues[obj.master_process_atributes_mapping_id] = obj.value;
      });

      setFormValues(newFormValues);

    } else {
      setFormValues({});
    }

  };

  return (
    <>
      <div className="wizard-container">
        <div
          className={`wizard-step ${step === 2 ? "highlight-green" : ""}`}
          onClick={() => setStep(2)}
        >
          Process
        </div>
        <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 
        <div className="home-screen-btn">
        <button className="home-button" onClick={backToHome}>
          Back to Home Screen
        </button>

        {/* <button className="home-button" onClick={() => console.log("ok")}>
          false--
        </button> */}
        </div>
      </div>


      {step === 2 && ( 
              <div className="workflow-container">
              <div className="left-tab">
                <h2>{flowName}</h2>
                {editWorkFlow?.processDetails?.map((row : any) => (
                  <div key={row.id} className="row-item">
                    {row.process_name}
                    <GrEdit 
                      className="edit-icon"
                      onClick={() => handleEditRow(row.id)}
                    />
                  </div>
                ))}
                <div className="add-row-button-container">
                  <button onClick={handleAddRow} className="add-row-button">
                    + Add
                  </button>
                </div>
              </div>
          
              <div className="right-tab">
                <div className="workflow-process">
                  <div className="dropdown-container">
                    <label htmlFor="selectProcess" className="process-label">
                      Select Process <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="selectProcess"
                      value={processId}
                      disabled={isDisabled}
                      className="process-dropdown"
                      onChange={handleProcessChange}
                    >
                      <option value="">Select</option>
                      {process.map((obj, index) => (
                        <option
                          key={index}
                          selected={isDisabled}
                          value={obj?.id}
                        >
                          {obj?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className="dropdown-container">
                    <label htmlFor="selectProcess" className="process-label">
                      Sequence <span style={{ color: "red" }}>*</span>
                    </label>
                    <input type="number" disabled={isDisabled} value={ isDisabled? 1 : sequence} onChange={(e: any) => setSequence(e.target.value)} />
                  </div> */}
          
                  <div className="process-modal">
                    <h2>Process</h2>
                    <div className="process-content">
                      {processField?.map((attr: any) => (
                        <div className="form-group1" key={attr.id}>
                          <label htmlFor={attr.label_name}>{attr.name}</label>
                          <input
                            type="text"
                            id={attr.name}
                            name={attr.master_process_atributes_mapping_id}
                            placeholder={attr.name}
                            value={formValues[attr.master_process_atributes_mapping_id] || ""}
                            onChange={handleInputChange}
                          />
                        </div>
                      ))}
                    </div>
          
                    <div className="button-container">
                      {/* <button onClick={() => setStep(1)} className="prev-button">
                        Previous
                      </button> */}
                      <button onClick={() => handleNextClick()} className="next-button">
                        Save as Draft
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          
      )}
    </>
  );
};

export default WorkflowInnerPage;
