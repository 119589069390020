import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Column } from "react-table";
import { BsQrCodeScan } from "react-icons/bs";
import { TfiClose } from "react-icons/tfi";
import { CiCircleRemove } from "react-icons/ci";
import QrReader from "react-qr-reader";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { userService as plateRelatedService } from '../../../../service/plateServices';
import { visualInspection } from "../../../../service/visualinspection";
import { BatchService } from "../../../../service/plateServices/discard";
import { IoSearchSharp } from "react-icons/io5";
import CustomToast from "../../../../widgets/toaster";




interface AddExceptionModelProps {
  show: boolean;
  isPlateSampling?: boolean;
  isRawMaterialTest?: boolean;
  exceptionReasons: { id: string; reason: string }[];
  samleTestType: { id: string; test_type: string }[];
  rawMaterialType: { id: string; material_type: string }[];
  rawMaterialSub: { id: string; material_type: string }[];
  plateBySerielNo: {
    plateSerielNo: string;
    plateType: string;
    batchId: string;
  }[];
  addExceptionData: {
    remarks: string;
    reason_id: string;
    other_reason: string;
    user_id: number;
    area_id: string;
    subCategory_id :string;
  };
  errors: {
    scan_plate?: string;
    reason_id?: string;
    other_reason?: string;
    remarks?: string;
    subCategory_id?:string;
  };
  showToast: boolean;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
  toastMessage: string;
  toastType: string;

  handleExceptionAlert: () => void;
  handleAddExceptionChange: (e: any) => void;
  addExceptionClose: () => void;
  handleModalOpen: () => void;
  getPlateDetailsBySerielNo: (slNo: string) => void;
  setPlateBySerielNo: (e: any) => void;
}

export const AddExceptionModel: React.FC<AddExceptionModelProps> = ({
  show,
  isPlateSampling,
  isRawMaterialTest,
  plateBySerielNo,
  errors,
  addExceptionData,
  exceptionReasons,
  samleTestType,
  rawMaterialType,
  rawMaterialSub,
  showToast,
  toastMessage,
  toastType,

  addExceptionClose,
  getPlateDetailsBySerielNo,
  handleModalOpen,
  handleAddExceptionChange,
  handleExceptionAlert,
  setPlateBySerielNo,
  setShowToast,
}) => {
  const { t } = useTranslation();
  const scanAreaRef = useRef<HTMLInputElement>(null);


  const [newResult, setResult] = useState("");
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [plateBatches, setPlateBatches] = useState([]);
  const [selectedSerielNo, setSelectedSerielNo] = useState("");
  const [debouncedScanCode, setDebouncedScanCode] = useState('');





  //handle result to get plate details by serial number api
  // const handleScanSerielNo = (result: any) => {
  //   console.log("Original Result:", result);
  //   try {
  //     const parsedResult = JSON.parse(result);
  //     if (typeof parsedResult === "object") {
  //       if (parsedResult.hasOwnProperty("serial_number")) {
  //         getPlateDetailsBySerielNo(parsedResult.serial_number);
  //       }
  //     }
  //   } catch (error) {
  //     getPlateDetailsBySerielNo(result);
  //     console.error("Error parsing JSON:", error);
  //   }
  // };

  const handleScanSerielNo = (result: any) => {
    console.log('Original Result:', result);
    if (result.trim() !== "") {
      // setResult(result);
      try {
        const parsedResult = JSON.parse(result);
        if (typeof parsedResult === 'object') {
          let serialNumber;
  
          if (parsedResult.hasOwnProperty('serial_number')) {
            serialNumber = parsedResult.serial_number;
          } else {
           
            console.log('No serial number property found in the parsed result.');
          }
          if (serialNumber) {
            getPlateDetailsBySerielNo(serialNumber);
            setResult(serialNumber);
          }
        } else {
          console.log('Parsed result is not an object.');    
        }
      } catch (error) {
        // getPlateDetailsBySerielNo(result);
        console.error('Error parsing JSON:', error);
      }
    }
  };

  const handleRemovePlate = (id: any) => {
    setPlateBySerielNo((prevState: any) => prevState.filter((plate: any )=> plate.plateSerielId !== id));
  }

  const handleModalClose = () => {
    addExceptionClose();
    setResult("");
  }

  // batch list for filter dropdown 
    const listPlateBatch = async () => {
        try {
        const response = await plateRelatedService.listPlateBatch();
        if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {
    
                    return { 
                        value: element.batch_number,
                        label: element.batch_number,
                        key: "batchCode",
                    };
                });
                setPlateBatches(rowData);
                }
            } catch (error) {
                console.log(error); 
            }
        }
        } catch (error) {
        console.error('Plate response', error);
        }
    }

    //serial number listing for table drop down 
    const listSerialNumber = async () => {
      try {
      const response = await plateRelatedService.listSerialNumber();
      if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.serial_number,
                      label: element.serial_number,
                      key: "serielNumber",
                  };
              });
              setPlateSerielNums(rowData);
              }
          } catch (error) {
              console.log(error); 
          }
      }
      } catch (error) {
      console.error('Plate response', error);
      }
  }

  const handleSelectChange = (selectedOption : any) => {
    if (selectedOption?.key === "batchCode") {
      listBatchPlateDetails(selectedOption?.value)
    }
    if (selectedOption?.key === "serielNumber") {
      getPlateDetailsBySerielNo(selectedOption?.value)
    }
    // if (selectedOption?.key === "lotCode") {
    //   setSelectedBatch(selectedOption?.batchCode);
    // }
  };

  //list plate batch detail 
  const listBatchPlateDetails = async (batch_number : any) => {
    try {
      const response = await plateRelatedService.listPlateByBatch(batch_number);
      console.log(response, "response---")
      if(response.status && response.statusCode === 200){
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            // setBatchByTotalPages(response?.totalPages)
            // setBatchByTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
              const role = element.role_master_id === "1" ?
                 "Admin" : element.role_master_id === "2" ?
                 "Operator" : element.role_master_id === "3"? 
                 "Lab Operator": null
                 const statusNumber = parseInt(element.plate_status, 10); 
                 const status = plateRelatedService.getPlateStatus(statusNumber);
                return { 
                    id : element.id,
                    slno: index + 1, 
                    batch_number: element.batch_number,
                    serial_number: element.serial_number,
                    manufacturing_date: element.manufacturing_date,
                    expiry_date: element.expiry_date,
                    status: status,
                };
            });
            // setBatchPlateList(rowData);
          }
      } else {
        // setBatchPlateList([]);
      }
    } catch (error) {
      return error;
    }

  }

  useEffect (() => {
    listPlateBatch()
    listSerialNumber()
  }, [])

  useEffect(() => {
    if (show === true && scanAreaRef.current) {
      scanAreaRef.current.focus();
    }
  }, [show])

  useEffect(() => {
    // Set a timer to update debouncedScanCode after 10 seconds
    const timerId = setTimeout(() => {
      setDebouncedScanCode(newResult);
    }, 3000);

    // Cleanup the timer if the component is unmounted or scanCode changes
    return () => clearTimeout(timerId);
  }, [newResult]);

  useEffect(() => {
    if (debouncedScanCode) {
      handleScanSerielNo(debouncedScanCode);
    }
  }, [debouncedScanCode]);


  return (
    <Modal
      className="top-right-modal"
      centered={false}
      show={show}
      size="lg"
    >
      <div>
        <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
            // toastPosition='topLeft'
        /> 
        <div className="modalHead">
          <p className="modalHeadText ms-3 pt-3 p-2">
            {isPlateSampling
              ? "Plates Sampling"
              : isRawMaterialTest
              ? "Raw Material Test"
              : t("Exception.add_exception")}
          </p>
          <div onClick={() => handleModalClose()} className="p-2 mx-3 my-2 ">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>

        <div className='d-flex'>
          <div className="scanWrap p-3" style={{"width": "48%"}}>
            <div className="scanInputWrap p-2">
              <input
                onChange={(e) => setResult(e.target.value)}
                placeholder="Scan Only"
                value={newResult}
                ref={scanAreaRef}
              />
              {newResult && (
                <CiCircleRemove 
                  onClick={() => setResult("")} 
                  fontSize={30} 
                  color="red"
                  className="m-1"
                  style={{"cursor": "pointer"}}
                />
              )}
              <div onClick={handleModalOpen} className="scanBg ">
                <BsQrCodeScan />
              </div>
            </div>
            {errors.scan_plate && (
              <p className="errorText p-0 m-0">{errors.scan_plate}</p>
            )}
          </div>

          <div className="scanWrap p-3" style={{"width": "52%"}}>
                {/* <label style={{"display": "flex"}}>Search Area / Location / Equipment QR Code</label> */}
                <div className="scanInputWrap p-2">
                <input
                    onChange={(e) => setSelectedSerielNo(e.target.value)}
                    placeholder="Type & Search"
                />
                < IoSearchSharp 
                    fontSize={25} 
                    className='mt-1' 
                    color='grey'
                    style={{"cursor":"pointer"}} 
                    onClick={() => getPlateDetailsBySerielNo(selectedSerielNo)}
                />
                </div>
          </div>

          </div>

          {/* filter dropdown */}
          <div className="d-flex justify-content-end my-4" style={{"visibility":"hidden"}}>
            <div style={{"zIndex":"10"}} className='mx-2' >
                    <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Batch Code</label> 
                    <div style={{"zIndex":"10"}}>
                        <Select
                            options={plateBatches}
                            onChange={handleSelectChange}
                            placeholder="Batch code"
                            isSearchable={true} 
                            isClearable
                            className='areaTypeSelect2 me-3'
                        />
                    </div>
            </div>

            <div style={{"zIndex":"10"}} className='mx-2' >
                    <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Serial Number</label> 
                    <div style={{"zIndex":"10"}}>
                        <Select
                            options={plateSerielNum}
                            onChange={handleSelectChange}
                            placeholder="Serial Number"
                            isSearchable={true} 
                            isClearable
                            className='areaTypeSelect2 me-3'
                        />
                    </div>
            </div>
          </div>


          {/* {plateBySerielNo.length >= 1 && ( */}
            <div className="px-3 mx-2" style={{"overflow" : "scroll", "height": "180px"}}>
              <table className="custom-table">
                <thead >
                  <tr>
                    <th scope="col" className="tableHeadData2">Sl.No ({plateBySerielNo.length})</th>
                    <th scope="col" className="tableHeadData2">Plate Serial No</th>
                    <th scope="col" className="tableHeadData2">Plate Status</th>
                    <th scope="col" className="tableHeadData2">Batch Code</th>
                    <th scope="col" className="tableHeadData2">Remove</th>
                  </tr>
                </thead>
                <tbody >
                  {plateBySerielNo.map((obj : any, index: number) => (
                  <tr>
                    <td className="tableHeadData" >{index + 1}</td>
                    <td className="tableHeadData" >{obj.plateSerielNo}</td>
                    <td className="tableHeadData" >{obj.plateType}</td>
                    <td className="tableHeadData" >{obj.batchId}</td>
                    <td className="tableHeadData" ><CiCircleRemove 
                        onClick={() => handleRemovePlate(obj.plateSerielId)} 
                        fontSize={25} 
                        color="red"
                        style={{"cursor": "pointer"}}
                      /></td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
           {/* )} */}

          {/* <div className="selectWrap">
            <div className="form-group my-1">
              <label htmlFor="">{t("Exception.serial_id")}</label>
              <br />
              <input
                disabled
                value={
                  plateBySerielNo.length > 0
                    ? plateBySerielNo[0].plateSerielNo
                    : ""
                }
              />
            </div>

            <div className="form-group my-1">
              <label>{t("Exception.plate_type")}</label>
              <br />
              <input
                disabled
                value={
                  plateBySerielNo.length > 0 ? plateBySerielNo[0].plateType : ""
                }
              />
            </div>
          </div> */}
          {/* <div className="selectWrap">
            <div className="form-group my-1">
              <label htmlFor="">{t("Exception.batch")}</label>
              <br />
              <input
                disabled
                value={
                  plateBySerielNo.length > 0 ? plateBySerielNo[0].batchId : ""
                }
              />
            </div>

            <div className="form-group my-1">
              {!isPlateSampling && !isRawMaterialTest && (
                <>
                  <label htmlFor="">
                    {t("Exception.reason")}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id=""
                    value={addExceptionData.reason_id}
                    name="reason_id"
                    onChange={handleAddExceptionChange}
                  >
                    <option value="select">select</option>
                    {exceptionReasons.map((reason, index) => (
                      <option key={index} value={reason?.id}>
                        {reason?.reason}
                      </option>
                    ))}
                    <option value="0">others</option>
                  </select>
                  {errors.reason_id && (
                    <p className="errorText">{errors.reason_id}</p>
                  )}
                </>
              )}

              {isPlateSampling && (
                <>
                  <label htmlFor="">
                    Plate Sampling Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id=""
                    value={addExceptionData.reason_id}
                    name="reason_id"
                    onChange={handleAddExceptionChange}
                  >
                    <option value="select">select</option>
                    {samleTestType.map((type, index) => (
                      <option key={index} value={type?.id}>
                        {type?.test_type}
                      </option>
                    ))}
                  </select>
                  {errors.reason_id && (
                    <p className="errorText">{errors.reason_id}</p>
                  )}
                </>
              )}

              {isRawMaterialTest && (
                <>
                  <label htmlFor="">
                    Sample Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id=""
                    value={addExceptionData.reason_id}
                    name="reason_id"
                    onChange={handleAddExceptionChange}
                  >
                    <option value="select">select</option>
                    {rawMaterialType.map((type, index) => (
                      <option key={index} value={type?.id}>
                        {type?.material_type}
                      </option>
                    ))}
                  </select>
                  {errors.reason_id && (
                    <p className="errorText">{errors.reason_id}</p>
                  )}
                </>
              )}
            </div>
          </div> */}
          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-1">
                {!isPlateSampling && !isRawMaterialTest && (
                  <>
                    <label htmlFor="">
                      {t("Exception.reason")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <select
                      id=""
                      value={addExceptionData.reason_id}
                      name="reason_id"
                      onChange={handleAddExceptionChange}
                    >
                      <option value="select">select</option>
                      {exceptionReasons.map((reason, index) => (
                        <option key={index} value={reason?.id}>
                          {reason?.reason}
                        </option>
                      ))}
                      <option value="0">others</option>
                    </select>
                    {errors.reason_id && (
                      <p className="errorText">{errors.reason_id}</p>
                    )}
                  </>
                )}

                {isPlateSampling && (
                  <>
                    <label htmlFor="">
                      Plate Sampling Type<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <select
                      id=""
                      value={addExceptionData.reason_id}
                      name="reason_id"
                      onChange={handleAddExceptionChange}
                    >
                      <option value="select">select</option>
                      {samleTestType.map((type, index) => (
                        <option key={index} value={type?.id}>
                          {type?.test_type}
                        </option>
                      ))}
                    </select>
                    {errors.reason_id && (
                      <p className="errorText">{errors.reason_id}</p>
                    )}
                  </>
                )}

                {isRawMaterialTest && (
                  <>
                    <label htmlFor="">
                      Sample Type<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <select
                      id=""
                      value={addExceptionData.reason_id}
                      name="reason_id"
                      onChange={handleAddExceptionChange}
                    >
                      <option value="select">select</option>
                      {rawMaterialType.map((type, index) => (
                        <option key={index} value={type?.id}>
                          {type?.material_type}
                        </option>
                      ))}
                    </select>
                    {errors.reason_id && (
                      <p className="errorText">{errors.reason_id}</p>
                    )}
                  </>
                )}
              </div>
            <div className="form-group my-1 mx-3">
              {isRawMaterialTest && (
                <>
                  <label htmlFor="">
                   Sub Category<span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id=""
                    value={addExceptionData.subCategory_id}
                    name="subCategory_id"
                    onChange={handleAddExceptionChange}
                  >
                    <option value="select">select</option>
                    {rawMaterialSub.map((type, index) => (
                      <option key={index} value={type?.id}>
                        {type?.material_type}
                      </option>
                    ))}
                  </select>
                  {errors.subCategory_id && (
                    <p className="errorText">{errors.subCategory_id}</p>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-start">
              {addExceptionData.reason_id === "0" && (
                <div className="selectWrap justify-content-start ps-3">
                  <div className="form-group ms-2 ps-1 ">
                    <label htmlFor="">
                      {t("Exception.other")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <textarea
                      placeholder="Other"
                      className="otherReasonTextArea p-2"
                      value={addExceptionData.other_reason}
                      name="other_reason"
                      onChange={handleAddExceptionChange}
                      // Add any necessary props for the text area
                    />
                    {errors.other_reason && (
                      <p className="errorText m-0 p-0">{errors.other_reason}</p>
                    )}
                  </div>
                </div>
              )}

              {!isPlateSampling && !isRawMaterialTest && (
                <div className="selectWrap justify-content-start ms-1 ps-3">
                  <div className="form-group ms-2 ps-1 ">
                    <label htmlFor="">
                      {t("Exception.remarks")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <textarea
                      placeholder="Remarks"
                      className="otherReasonTextArea p-2"
                      value={addExceptionData.remarks}
                      name="remarks"
                      onChange={handleAddExceptionChange}
                      // Add any necessary props for the text area
                    />
                    {errors.remarks && (
                      <p className="errorText m-0 p-0">{errors.remarks}</p>
                    )}
                  </div>
                </div>
              )}
          </div>


          {/* {addExceptionData.reason_id === "0" && (
            <div className="selectWrap justify-content-start ps-3">
              <div className="form-group ms-2 ps-1 ">
                <label htmlFor="">
                  {t("Exception.other")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <textarea
                  placeholder="Other"
                  className="otherReasonTextArea p-2"
                  value={addExceptionData.other_reason}
                  name="other_reason"
                  onChange={handleAddExceptionChange}
                  // Add any necessary props for the text area
                />
                {errors.other_reason && (
                  <p className="errorText m-0 p-0">{errors.other_reason}</p>
                )}
              </div>
            </div>
          )} */}
        </div>
        {/* {!isPlateSampling && !isRawMaterialTest && (
          <div className="selectWrap justify-content-start ps-3">
            <div className="form-group ms-2 ps-1 ">
              <label htmlFor="">
                {t("Exception.remarks")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <textarea
                placeholder="Remarks"
                className="otherReasonTextArea p-2"
                value={addExceptionData.remarks}
                name="remarks"
                onChange={handleAddExceptionChange}
                // Add any necessary props for the text area
              />
              {errors.remarks && (
                <p className="errorText m-0 p-0">{errors.remarks}</p>
              )}
            </div>
          </div>
        )} */}
        <div className="bottomArea">
          <button
            onClick={() => handleModalClose()}
            className="modalCancelBtn"
          >
            {t("buttons.cancel")}
          </button>
          <button
            onClick={() => handleExceptionAlert()}
            className="squareOrangeBtn3"
          >
            {t("buttons.submit")}
          </button>
          {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
        </div>
    </Modal>
  );
};

interface ScanAreaModelProps {
  show: boolean;
  onHide: () => void;
  webCamError: (error: any) => void;
  webCamScan: (result: string | null) => void;
}

export const ScanAreaModel: React.FC<ScanAreaModelProps> = ({
  show,
  onHide,
  webCamError,
  webCamScan,
}) => {
  return (
    <Modal show={show} >
      <div className="scanModal">
        <div className="modalHead">
          <p className="modalHeadText2 mx-3 my-2">Scan</p>
          <div onClick={onHide} className="p-2 mx-2 ">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>

        <QrReader
          className="scan-web"
          delay={300}
          onError={webCamError}
          onScan={webCamScan}
          facingMode={"user"}
        />
      </div>
    </Modal>
  );
};
