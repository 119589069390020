import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
import { helperFunctions as _h } from "../../utils/helper";
let authToken = "";
let authUserID = "";

const storedData = localStorage.getItem("userDetails");

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (
      storedObject &&
      Array.isArray(storedObject) &&
      storedObject.length > 0
    ) {
      authToken = storedObject[0].x_auth_token;
      authUserID = storedObject[0].x_userid;
    }
  } catch (error) {
    console.error("Failed to parse user details:", error);
  }
}

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: baseUrl,
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // If the response is successful, just return the response
    return response;
  },
  (error) => {
    // Check for 400 or 401 status codes
    if (
      axios.isAxiosError(error) &&
      (error.response?.status === 408 || error.response?.status === 401)
    ) {
      // Redirect to login page
      handleLogOut();
    }
    // Return any other errors
    return Promise.reject(error);
  }
);


const listMasterShiftName = async (page: number, pageSize: number, keyWord: any, sort:string , order: string) => {
    try {
      const response = await axiosInstance.get(
        `${baseUrl}/shift/list_master_shift?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&status=active,inactive&keyword=${keyWord}`,
        {
          headers: {
            "x-auth-token": authToken,
            "x-userid": authUserID,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data;
      }
    }
  };

  const changeMasterShiftStatus = async (id: string, status: string , interface_name : string) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/shift/change_master_shift_status`, {
        id: id,
        status: status,
        interface_name: interface_name
      },{
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


  const addMasterShiftData = async (dataToSend: any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/shift/save_master_shift`, dataToSend,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


  const listCustomShift = async (page : any, pageSize: any , keyword: string, sort: string, order: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/shift/list_custom_shift?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&keyword=${keyword}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const createShifttoUser = async (dataToSend: any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/shift/save_custom_shift`, dataToSend,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


  const getShiftDropDown = async () => {
    try {
      const response = await axiosInstance.get(
        `${baseUrl}/shift/list_master_shift?sort=id&order=desc&status=active`,
        {
          headers: {
            "x-auth-token": authToken,
            "x-userid": authUserID,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data;
      }
    }
  };


  const getUserNameList = async (role_master_id: any) => {
    try {
      const response = await axiosInstance.get(
        `${baseUrl}/empats/list_users?sort=id&order=desc&status=active,inactive&keyword&role_master_id=${role_master_id}`,
        {
          headers: {
            "x-auth-token": authToken,
            "x-userid": authUserID,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data;
      }
    }
  };


  const AssignUserShift = async (dataToSend: any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/shift/save_user_shift`, dataToSend,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

const shiftManagement = {
    listMasterShiftName,
    changeMasterShiftStatus,
    addMasterShiftData,
    listCustomShift,
    createShifttoUser,
    getShiftDropDown,
    getUserNameList,
    AssignUserShift
  };
  
  export { shiftManagement };