import React, { useState, useEffect } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link } from "react-router-dom";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { TfiClose } from "react-icons/tfi";
import { Modal } from "react-bootstrap";
import { dashboardServices } from "../../../../service/dashboard";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import translationData from '../../../../locales/dashboardTranslation.json'
import DownloadPdf from "../../../../widgets/downloadPdf";



const InnerPageLabtest = () => {
    const { labtestId } = useParams();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [scheduleDetails, setScheduleDetails] = useState([]);
    const displayName = getDisplayName(Number(labtestId));
    const [useEffectRequired, setUseEffectRequired] = useState(false)
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");

    function getDisplayName(id: any) {
        const LabtestDataHead = translationData.LabtestDataHead;
        const labtest = LabtestDataHead.find(item => item.id === id);
        return labtest ? labtest.displayName : "";
    }

    //column header for labtest view
    const columns : any  = [
    {
      Header: "tableCommon.sl_no",
      accessor: "slno",
    },
    {
      Header: "dashboard.labtestViewTableColumns.plateSerialNumber",
      accessor: "plate_serial_number",
    },
    {
      Header: "dashboard.labtestViewTableColumns.batchNumber",
      accessor: "batch_number",
    },
    {
      Header: "dashboard.labtestViewTableColumns.labtest1DoneBy",
      accessor: "labtest_first_doneby",
    },
    {
      Header: "dashboard.labtestViewTableColumns.labtest2DoneBy",
      accessor: "labtest_second_doneby",
    },
    {
      Header: "dashboard.labtestViewTableColumns.labtest3DoneBy",
      accessor: "labtest_third_doneby",
    },
    {
      Header: "dashboard.labtestViewTableColumns.colonyCountOrLimit",
      accessor: "colony_count",
    },
    {
        Header: "dashboard.labtestViewTableColumns.deviationsIfAny",
        accessor: "deviations_any",
    },
    {
        Header: "dashboard.labtestViewTableColumns.status",
        accessor: "status",
    },
  ]
  
  //handle pagination 
    const handleSchedulePageChange = (newPage :any, newPageSize?: number) => {
      setPage(newPage);
      if (newPageSize) {
        setPageSize(newPageSize);  
      }
      setUseEffectRequired(true)

    };

    //handle inner page api integration 
    const fetchData = async () => {
      try {
        let response;
        switch (labtestId) {
          case "1":
            response = await dashboardServices.ListFirstLabtest(page, pageSize, sort, order);
            break;
          case "2":
            response = await dashboardServices.ListSecondLabtest(page, pageSize, sort, order);
            break;
          case "3":
            response = await dashboardServices.ListThirdLabtest(page, pageSize, sort, order);
            break;
          case "4":
            response = await dashboardServices.ListReviewLabtest(page, pageSize, sort, order);
            break;
          case "5":
            response = await dashboardServices.ListPlateForFlora(page, pageSize, sort, order);
            break;
          case "6":
            response = await dashboardServices.ListLabtestWithinRange(page, pageSize, sort, order);
            break;
          case "7":
            response = await dashboardServices.ListLabtestOutsideRange(page, pageSize, sort, order);
            break;
          default:
            break;
        }
  
        if (response.status && response.statusCode === 200) {
          try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages);
              setTotalElements(response?.totalElements);
              let rowData = response.data.map((element:any, index: any) => ({
                slno: index + 1,
                plate_serial_number: element.plate_serial_number,
                batch_number: element.plate_batch_number,
                labtest_first_doneby: element.first_lab_done_by,
                labtest_second_doneby: element.second_lab_done_by,
                labtest_third_doneby : element.third_lab_done_by,
                colony_count: element.colony_count_limit,
                deviations_any: element.deviations_any,
                status: element.status,
              }));
              setScheduleDetails(rowData);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          setScheduleDetails([]);
        }
      } catch (error) {
        console.error('API call error', error);
      }
    };

      
    // function to sort table
    const handleSort = (key: string, value: boolean) => {
      setSort(key)
      if (value) {
        setOrder("asc")
      } else {
        setOrder("desc")
      }
      setUseEffectRequired(true)
    }

    useEffect(() => {
        fetchData();
        setUseEffectRequired(false)

      }, [labtestId, useEffectRequired]);

      return (
        <div>
          <HeaderSection />
          <PageHeader pageHeader={t('dashboard.title')} />
          <div id="dashWrap">
          <nav aria-label="breadcrumb" className="breadcrumb-container row">
            <div className='col-10 d-flex'>
              <ol className="breadcrumb">
                <li className="breadcrumb-item dashboard-hover">
                  <Link to="/*">{t('dashboard.title')}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                {displayName}
                </li>
              </ol>
            </div>
            <div className='col-2 d-flex'>
              <DownloadPdf divId="dashWrap" reportHead="LabTest Report" />
            </div>
          </nav>
    
            <div className='col-md-12 mx-3 pe-5' style={{"height":"45vh"}}>
                    <CustomTable
                        tableHead={displayName} 
                        data={scheduleDetails} 
                        columns={columns} 
                        isEditable={false} 
                        isActionVisible={false} 
                        isViewVisible={false} 
                        totalElements={totalElements}
                        isSeachable={false}
                        isToggleStatus={false}      
                        onSortClick={handleSort}
                        onUseEffectRequired={() => setUseEffectRequired(true)}
                    />
            </div>
            <div>
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePageChange={handleSchedulePageChange}
                  />
            </div>
          </div>
        </div>
      );
  };
  
  export default InnerPageLabtest;
  