export const columns: TableColumn[] = [
    {
        Header: 'tableCommon.sl_no',
        accessor: 'slno',
      },
      {
        Header: 'Workflow Name',
        accessor: 'name',
      },
      {
        Header: 'Activity Name',
        accessor: 'activity_name',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }