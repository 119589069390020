export const columns: TableColumn[] = [
    {
        Header: 'tableCommon.sl_no',
        accessor: 'slno',
      },
      {
        Header: 'Personnel Monitoring Name',
        accessor: 'personnelLocName',
      },
      {
        Header: 'Location Code',
        accessor: 'locationCode',
      },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }