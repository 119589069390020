import React from 'react';
import { FaExclamationTriangle, FaBell, FaTimes, FaMicroscope  } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { CSSProperties } from 'react';

interface PushNotificationProps {
  show: boolean;
  alarmcount: String;
  alertcount: string;
  onClose: () => void;
  onSnooze: () => void;
}



const PushNotification: React.FC<PushNotificationProps> = ({ show, onClose, onSnooze, alarmcount, alertcount }) => {
  if (!show) return null;
  return (
    <div style={styles.notificationContainer as CSSProperties}>
      <div style={styles.notificationContent as CSSProperties}>    
      <div style={styles.titleBar}>
          <span style={styles.titleText}> <FaMicroscope style={{ marginRight: '5px' }} /> MPATS Notification</span>
          <FaTimes style={styles.closeIcon} onClick={onSnooze} />
        </div>
        <div style={styles.notificationText}>  
          You have  {alarmcount} Alarm(s) <FaBell  className="ringing-icon" style={{ color: 'orange', marginRight: '5px', marginLeft: '5px' }} size={20} />
           and {alertcount} Alert <FaExclamationTriangle style={{ color: 'red', marginRight: '5px', marginLeft: '5px' }} size={20}/>   
        </div>
        <div style={styles.buttonsContainer}>
          <Button variant="secondary" size="sm" onClick={onSnooze} style={styles.button}>
            Snooze
          </Button>
          <Button variant="primary" size="sm" onClick={onClose} style={{ ...styles.button, ...styles.viewDetailsButton }}>
            View Details
          </Button>
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  notificationContainer: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '300px',
    zIndex: 1000,
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: '1px solid #6c757d',
    padding: '10px',
  },
  notificationContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    borderBottom: '1px solid #ddd',
    paddingBottom: '5px',
  },
  titleText: {
    fontSize: '14px',
    // fontWeight: 'bold',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  notificationText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    flex: 1,
    margin: '0 5px',
  },
  viewDetailsButton: {
    backgroundColor: '#FF7344',
    borderColor: '#FF7344',
  },
};

export default PushNotification;
