import React, { useEffect, useState } from 'react'
import PageHeader from '../../../common/pageHeader'
import HeaderSection from '../../../common/headerSection'
import { Modal} from 'react-bootstrap'
import CustomTable from '../../../../widgets/table'
import { columns} from './partials/columns'
import { TfiClose } from 'react-icons/tfi'
import { useTranslation } from 'react-i18next';
import AlertModal from '../../../../widgets/alertModal'
import CustomToast from '../../../../widgets/toaster'
import Pagination from '../../../../widgets/pagination';
import { userService } from '../../../../service/userService'
import { deviceRegistration } from '../../../../service/deviceRegistration'



const DeviceRegistration = () => {

  const { t } = useTranslation();


  const [showAddModal, setShowAddModal] = useState(false);
  const [modalName, setModalName] = useState(t('applicationConfiguration.addDeviceRegistration'));
  const [modalEdit, setModalEdit] = useState(false);
  const [deviceRegisterData, setDeviceregisterData] = useState({
    deviceId: "",
    deviceName: "",
    remarks: "",
    id: "",
    reason: ""
  });
  const [deviceRegister, setDeviceRegister] = useState([]);
  const [addDeviceAlert, setAddDeviceAlert] = useState(false);
  const [idAndStatus, setIdAndStatus] = useState({ 
    id: "",
    status: "" 
  });
  const [showUserStatusChangeAlert, setShowUserStatusChangeAlert] = useState(false);
  const [useEffectRequired, setuseEffectRequired] = useState(false)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
   // table pagination for sample type
   const [page, setPage] = useState(1);
   const [pageSize, setPageSize] = useState(5);
   const [totalPages, setTotalPages] = useState(0);
   const [totalElements, setTotalElements] = useState(0);
   // table pagination for sample Sub type

   const [keyWord, setkeyWord] = useState("")

   interface Errors {
    deviceName?: string,
    deviceId?: string,
    remarks?: string,
    reason?: string
  }
  
  const [errors, setErrors] = useState<Errors>({});
  const [userId, setUserId] = useState("");
  const [authToken , setAuthToken] = useState("");
  const [authUserID , setAuthUserID] = useState("");
  const [permission, setPermission] = useState<any>([]);





  useEffect(() => {

    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      setAuthToken(storedObject[0].x_auth_token);
      setAuthUserID(storedObject[0].x_userid);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listDeviceList();
    setuseEffectRequired(false)
  }, [useEffectRequired])
  

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }

  //get all_raw_material_type list from api  
  const listDeviceList = async () => {
    try {
      const response = await deviceRegistration.listDeviceList(page, pageSize);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    device_code: element.device_code,
                    remarks: element.remarks,
                    device_name: element.device_name,
                    id : element.id,
                    status : element.status,
                };
              });
              setDeviceRegister(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }else {
        setDeviceRegister([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }


  const handleInputChange = (e : any) => {
    const { name, value } = e.target;
    let regex;
    if (name === "deviceName") {
      regex = /^[a-zA-Z][a-zA-Z0-9 .-]*$/;
    } else if (name === "deviceId") {
      regex = /^[a-zA-Z][a-zA-Z0-9 .-]*$/;
    }

    if (value === "") {
    } else if (regex && !regex.test(value)) {
      return
    }

    setDeviceregisterData((prevFormData : any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  //create raw material type 
  const createDeviceRegister = async () => {
    setAddDeviceAlert(false)

    const dataToSend = {
        id: deviceRegisterData.id,
        device_id : deviceRegisterData.deviceId,
        device_name : deviceRegisterData.deviceName,
        remarks : deviceRegisterData.remarks,
        "interface_name" : "Device Registration",
        reason : deviceRegisterData.reason
    }

    try {
      const response = await deviceRegistration.registerDevice(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
        addModalCloseEvent()
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      // addModalCloseEvent()
      setuseEffectRequired(true)
    } catch (error) {
      console.error('user response', error);
    }
  }



  //handle edit click 
  const handleEditClick = (id: string) => {
    setModalName(t('applicationConfiguration.editDeviceRegistration'))
    setModalEdit(true)
    const filteredUserDetails: any = deviceRegister.filter((item: any) => item.slno === id);
    setDeviceregisterData({
      deviceId: filteredUserDetails[0]?.device_code,
      deviceName: filteredUserDetails[0]?.device_name,
      remarks: filteredUserDetails[0]?.remarks,
      id: filteredUserDetails[0]?.id,
      reason: filteredUserDetails[0]?.reason
    })
    // const selectedIds = filteredUserDetails.map((item: any) => item.id).join(',');
    // setSelectedRawMaterialId(selectedIds)
    setShowAddModal(true)
  }


  //handle policy management alert
  const handleDeviceAddAlert = () => {
    const errors: Errors = {};
      if (!deviceRegisterData.deviceName) {
        errors.deviceName = "Please Enter Device Name";
      }
      if (!deviceRegisterData.deviceId) {
        errors.deviceId = "Please Enter Device ID";
      }
      // if (!deviceRegisterData.remarks) {
      //   errors.remarks = "Please Enter User Name";
      // }
      if (deviceRegisterData.id && !deviceRegisterData.reason) {
        errors.reason = "Please enter Reason";
      }
      
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        console.log(errors)
        return;
      }
      setAddDeviceAlert(true)
      setErrors({});
  }


  const handleSubmit = () => {
    setAddDeviceAlert(false)
    createDeviceRegister()
  }

  //show status alert modal for status change 
  const handleStatusAlert = async (id:any , status: string) => {
    setIdAndStatus({ id, status });
    setShowUserStatusChangeAlert(true)
  }


  //api call to make user active or inactive 
  const handleStatusChange = async () => {
    let updatedStatus = "";
      if (idAndStatus?.status === "active") {
        updatedStatus = "inactive";
      } else {
        updatedStatus = "active";
      }
      
      try {
        const response = await deviceRegistration.changeDeviceStatus(idAndStatus.id, updatedStatus, authToken ,authUserID ,"Device Registration Status");
        
        if (response.status && response.statusCode === 200) {
          setuseEffectRequired(true)
          setShowUserStatusChangeAlert(false)
          updatedStatus = ""
          setToastMessage(response.message)
          setToastType('success')
        } else {
          setShowUserStatusChangeAlert(false)
          updatedStatus = ""
          setToastMessage(response.message)
          setToastType('error')
        }
        setShowToast(true)
      } catch (error) {
        console.error(error);
      }
  }



  const addEquipmentBtnClick = () => {
    setModalName(t('applicationConfiguration.addDeviceRegistration'))
    setModalEdit(false)
    setShowAddModal(true)
  }

  const addModalCloseEvent = () => {
    setShowAddModal(false)
    setDeviceregisterData({
      deviceId: "",
      deviceName: "",
      remarks: "",
      id: "",
      reason: ""
    })
    setErrors({})
  }

  //handle table pagination 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage)
    if(pageSize) {
      setPageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  //handle filter section in sample type
  const handleFilter = (key: string, value: any) => {
    if(key === "Role") {
      // setRole(value)
    } else if (key === "search") {
      setkeyWord(value)
    }
    setuseEffectRequired(true)
  }


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('applicationConfiguration.deviceRegistration')} />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 
   
        <div className='me-3' style={{marginTop : "20px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    tableHead={t('applicationConfiguration.deviceRegistration')}
                    data={deviceRegister} 
                    columns={columns} 
                    isEditable={permission?.includes("edit_device")} 
                    isTempDeletable={false}
                    totalElements={totalElements} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    isSeachable={true}
                    isToggleStatus={permission?.includes("edit_device")}
                    isResetPassword={false}
                    addButton={permission?.includes("add_device")}
                    addButtonText={t('applicationConfiguration.addDeviceRegistration')}
                    onUseEffectRequired={() => setuseEffectRequired(true)}
                    onFilterClick={handleFilter}
                    onStatusChange={handleStatusAlert}
                    onAddClick={addEquipmentBtnClick}             
                    onEditClick={handleEditClick}
                    changeRoleButton={false}
                />
            </div>
            <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </div>
      


        <Modal 
              className="top-right-modal-md"
              centered={false}
              show={showAddModal}
          >
            <div>
              <div className="modalHead">
                  <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                  <div onClick={() => addModalCloseEvent()} className="p-2 mx-3 my-2 ">
                    <TfiClose fill="#FFFFFF" />
                  </div>
              </div>

              <div>
              <div className="selectWrap">
                    <div className="form-group my-1">
                      <label htmlFor="">{t('common.deviceId')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                        id="deviceId"
                        value={deviceRegisterData.deviceId}
                        name="deviceId"
                        type='text'
                        placeholder={t('common.deviceId')}
                        className="placeholder-style"
                        onChange={handleInputChange}
                        maxLength={100}
                      />
                      {errors.deviceId && <p className="errorText">{errors.deviceId}</p>}
                    </div>

                    <div className="form-group my-1">
                      <label htmlFor="" >{t('common.deviceName')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                        id="deviceName"
                        value={deviceRegisterData.deviceName}
                        name="deviceName"
                        type='text'
                        placeholder={t('common.deviceName')}
                        className="placeholder-style"
                        onChange={handleInputChange}
                        maxLength={100}
                      />
                      {errors.deviceName && <p className="errorText">{errors.deviceName}</p>}
                    </div>

                    <div className="form-group my-1">
                      <label htmlFor="">{t('common.remarks')}</label>
                      <br />
                      <input
                        id="remarks"
                        value={deviceRegisterData.remarks}
                        name="remarks"
                        placeholder={t('common.remarks')}
                        className="placeholder-style"
                        onChange={handleInputChange} 
                        maxLength={100}
                      /> 
                      {/* {errors.remarks && <p className="errorText">{errors.remarks}</p>} */}
                    </div>
              </div>

                <div className="selectWrap-new">
                  {deviceRegisterData.id && (
                  <div className="form-group my-1 ms-3 me-1 ps-2 pe-5">
                      <label htmlFor="" className='ReasonLabel'>{t('configuration.reason')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                          id="reason"
                          value={deviceRegisterData.reason}
                          name="reason"
                          type='text'
                          placeholder={t('configuration.reason')}
                         className="reasonClass"
                          onChange={handleInputChange} 
                      />
                      {errors.reason && <p className="errorText">{errors.reason}</p>}
                  </div>
                  )}
                </div>

              </div>
                <div className="bottomArea">
                  <button onClick={() => addModalCloseEvent()} className="modalCancelBtn">{t('buttons.cancel')}</button>
                  <button onClick={() => handleDeviceAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
                </div>
            </div>
          
        </Modal>

        {/* Alert modal for Add policy */}
        <AlertModal 
          show={addDeviceAlert}
          onHide={() => setAddDeviceAlert(false)}
          title='Alert'
          message={`${modalName}`}
          onConfirm={handleSubmit}
        />

        {/* Alert modal for sample type status change */}
        <AlertModal
          show={showUserStatusChangeAlert}
          onHide={() => setShowUserStatusChangeAlert(false)}
          title='Alert'
          message={`change Device Registration status ?`}
          onConfirm={handleStatusChange}
        /> 

    </div>
  )
}

export default DeviceRegistration