import React, { useEffect, useState } from 'react';
import { Modal, Button, FloatingLabel } from 'react-bootstrap';
import { FaExclamationTriangle, FaBell, FaTimes  } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import { authenticationService } from '../../../../service/authentication';
import { useTranslation } from 'react-i18next';

interface Notification {
  id: number;
  caption: string;
  notification_type: string;
  updatedAt: string;
  content: string;
  typeButton : string;
  type: any;
}

interface NotificationModalProps {
  show: boolean;
  notifications: Notification[];
  onClose: () => void;
  onSnooze: (id: number) => void;
  typeButton: string;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
  show,
  notifications,
  onClose,
  onSnooze,
  typeButton
}) => {

  const { t } = useTranslation();


  const [showSecondaryModal, setShowSecondaryModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<any>(null);
  const [showNotifyCloseModal, setShowNotifyCloseModal] = useState(false);
  const [notificationId, setNotificationId] = useState("")
  const [notificationRemark, setNotificationRemark] = useState("")
  const [userId, setUserId] = useState("");




  const handleCloseSecondaryModal = () => {
    setShowSecondaryModal(false);
    setSelectedNotification(null);
  };

  const onView = (id: number) => {
    setShowSecondaryModal(true);
    console.log('View notification', id);
    const notification = notifications.find(n => n.id === id);
    setSelectedNotification(notification);
  };

  const onDismiss = (id: any) => {
    setShowNotifyCloseModal(true)
    setNotificationId(id)
  }

  const updateNotification = async (completedStatus: string, notificationId: string) => {
    const dataToSend = {
      id : notificationId,
      read_status:"1",
      complete_status: completedStatus,
      user_id: userId
    }

    try {
      const response = await authenticationService.updateNotification(dataToSend);
      if(response.status && response.statusCode === 200){
        setShowSecondaryModal(false)
      } else {
        // setNotificationList([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  const handleCloseNotifyDetailsCloseModal = (id : any) => {
    updateNotification("completed", id)
  }

  const handleCloseNotifyCloseModal = () => {
    setShowNotifyCloseModal(false)
    setNotificationRemark("")
  }

  const handleNotifiCloseSubmit = async () => {
    const dataToSend = {
      "notification_id" : notificationId, 
      "user_remark":notificationRemark
    }
    try {
      const response = await authenticationService.updateNotificationRemark(dataToSend);
      if (response.status && response.statusCode === 200) {
        handleCloseNotifyCloseModal()
      } else {
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  interface notificationProps {
    content : any
  }

  const NotificationContent : React.FC<notificationProps> = ({ content }) => {
    if (!content) return null;
  
    // Split the content by <br> tags
    const contentItems = content.split('<br>');
  
    return (
      <div className=''>
        <ul style={{ listStyleType: 'circle', textAlign: 'left' }}>
          {contentItems.map((item : any, index: number) => (
            <li key={index} className='normalText2'>{item}</li>
          ))}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
    }

  }, [])


  return (
    <>
    <Modal show={show} onHide={onClose} className="top-right-modal">
      <Modal.Header closeButton>
        <Modal.Title>Notifications</Modal.Title>
      </Modal.Header>
      <Modal.Body className='alert-content'>
        {notifications?.map((obj: any, index) => (
          <div key={obj?.id} className="notification-item">
            <div className="notification-image">
              {obj.notification_type === 'alarm' ? (
                <FaBell className="ringing-icon" style={{ color: 'orange', marginRight: '5px', marginLeft: '5px'}} size={20} />
              ) : (
                <FaExclamationTriangle style={{ color: 'red', marginRight: '5px', marginLeft: '5px' }} size={20} />
              )}
            </div>
            <div className="notification-details">
              <p className="notification-name">{obj?.caption}</p>
              <p className="notification-date">{obj?.updatedAt}</p>
              <div className="buttons-container">
                <button onClick={() => onView(obj?.id)} className="view-button">View</button>
                {typeButton === 'PopupClicked' && (
                <Button variant="secondary" size="sm" onClick={() => onSnooze(obj?.id)} className="button">
                  Snooze
                </Button>
                )}
                <Button variant="danger" size="sm" onClick={() => onDismiss(obj?.id)} className="button">
                  Close
                </Button>
              </div>
            </div>
          </div>
        ))}
      </Modal.Body>
      {/* <div className="bottomArea">
      <button onClick={onClose} className="modalCancelBtn">Cancel</button>
      </div> */}
    </Modal>

    {selectedNotification && (
      <Modal show={showSecondaryModal} className="top-center-modal">
        <Modal.Header closeButton onClick={() => handleCloseNotifyDetailsCloseModal(selectedNotification?.id)}>
          <Modal.Title>Notification Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>{selectedNotification?.content}</p> */}
          <div className='my-2' >
                <NotificationContent content={selectedNotification?.content} />
          </div>
        </Modal.Body>
        <div className="bottomArea">
          <button onClick={() => handleCloseNotifyDetailsCloseModal(selectedNotification?.id)} className="modalCancelBtn">Cancel</button>
        </div>
      </Modal>
    )}

      <Modal show={showNotifyCloseModal} centered >
        <Modal.Header closeButton onClick={() => handleCloseNotifyCloseModal()}>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel
            controlId="floatingTextarea"
            label="Please add a remark"
            className="mb-3"
          >
            <Form.Control 
              as="textarea" 
              placeholder="Leave a comment here" 
              style={{ maxHeight: '150px' }}
              value={notificationRemark}
              onChange={(e) => setNotificationRemark(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
          <div className="d-flex justify-content-between m-2">
            <button
              onClick={() => handleCloseNotifyCloseModal()}
              className="modalCancelBtn"
            >
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => handleNotifiCloseSubmit()}
              className="squareOrangeBtn3"
              disabled={!notificationRemark}
            >
              {t("buttons.submit")}
            </button>
          </div>
      </Modal>
   
    </>


  );
};

export default NotificationModal;
